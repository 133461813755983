.add-api-key-modal-wrapper {
  padding-right: 2.45rem;
  padding-left: 2.45rem;
}

.add-api-key-field {
  margin-top: 1em;
  margin-bottom: 1em;
}

.add-api-key-field h6 {
  font-size: 12px;
  color: #4698cb;
  line-height: 1;
  margin-bottom: 3px;
}

.add-api-key-field input {
  width: 20rem;
}

.add-api-key-ip-whitelist {
  display: flex;
  flex-direction: row;
}

.add-api-key-ip-whitelist .error-message {
  color: #E0004D;
  display: block;
}

.add-api-key-ip-whitelist textarea {
  width: 20rem !important;
  height: 8rem !important;
}

.add-api-key-ip-whitelist .ip-whitelist-instructions {
  margin-left: 1em;
}

.ip-whitelist-instructions ul {
  margin-left: 3ex;
}

.ip-whitelist-instructions li {
  text-indent: -1em;
}

.ip-whitelist-instructions li>span {
  color: #4698cb;
}
