.powerstation-nameplate {
  display: flex;
  border-bottom: 1px solid #DBE2E9;
  padding: 1em 0;
}

.powerstation-nameplate > div {
  margin-right: 2em;
}

.powerstation-nameplate label {
  margin-bottom: 0.4em;
  display: inline-block;
  font-weight: 500;
}

.powerstation-nameplate div.value {
  font-size: 1.3em;
  font-weight: 300;
}

.powerstation-nameplate.no-border {
  border-bottom: none;
}

.powerstation-nameplate .value.size {
  min-width: 5.5em;
}

.powerstation-nameplate .value.capacity,
.powerstation-nameplate .value.power {
  min-width: 6em;
}

.powerstation-nameplate .ps-np-loading {
  color: #8a8d91;
  margin-left: 0.5em;
}

.powerstation-nameplate .ps-np-tooltip-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: -2em;
}
