.pagination-control-container {
  display: flex;
  font-size: 13px;
  padding: 5px 0 5px 5px;
}

.pagination-container {
  align-items: center;
  display: flex;
}

.pagination-controls {
  display: flex;
}

.pagination-controls div {
  border-radius: 5px;
  color: rgb(70, 152, 203);
  cursor: default;
  margin: 0 2px;
  min-width: 20px;
  padding: 2px;
  text-align: center;
}

.pagination-controls .current-page, .pagination-controls .clickable:hover {
  background-color: rgba(11, 123, 214, 0.925);
  color: rgb(252, 252, 252);
}

.pagination-controls .clickable {
  border: 1px solid #DBE2E9;
  cursor: pointer;
}

.pagination-container .results-per-page {
  margin-left: 30px;
}

.pagination-container .pagination-display-text {
  margin-left: 10px;
}
