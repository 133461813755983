.dispatch-controls .rc-slider-disabled {
  background-color: unset;
}

.dispatch-controls .rc-slider-disabled .rc-slider-handle {
  box-shadow: none;
}

.dispatch-controls .rc-slider-rail {
  width: 100%;
  height: 8px;
  border-radius: 2px;
}

.dispatch-controls .rc-slider-handle {
  margin-top: -15px;
  margin-left: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.dispatch-controls .headers,
.dispatch-controls .footers {
  display: flex;
  justify-content: space-between;
}

.dispatch-controls .headers {
  margin-bottom: 0.6rem;
}

.dispatch-controls .footers {
  font-size: 0.87em;
  margin-top: 1.1rem;
  color: #4698cb;
}

.dispatch-controls-drop-down {
  position: relative;
  display: inline-block;
  padding-left: 0.4em;
  box-sizing: border-box;
}

.dispatch-controls-drop-down:focus {
  outline: none;
}

.dispatch-controls-drop-down .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.dispatch-controls-drop-down.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: inherit;
}

.dispatch-controls-drop-down .current-value:hover {
  color: #1F2A44;
}

.dispatch-controls-drop-down .current-value .fa {
  margin-left: 0.45em;
}

.dispatch-controls-drop-down .dispatch-controls-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40em;
  padding: 1em;
  box-sizing: border-box;
  left: -25em;
}

.dispatch-controls-drop-down .dispatch-controls-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 26em;
  transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.dispatch-controls-drop-down .dispatch-controls-wrapper {
  width: 100%;
}

.dispatch-controls-drop-down .dispatch-controls-container .power-factor {
  margin-top: 1.5em;
  border-top: 1px solid #DBE2E9;
  padding-top: 1.5em;
}

.power-factor-constraint-message {
  font-style: italic;
  color: #85888c;
}

.dispatch-controls-drop-down .lag-lead-container {
  display: flex;
  margin-top: 0.8em;
  align-items: center;
  justify-content: center;
}

.dispatch-controls-drop-down .lag-lead-container.disabled {
  color: #a3a6aa;
}

.dispatch-controls-drop-down .lag-lead-container label {
  min-width: 8em;
  display: inline-block;
}
