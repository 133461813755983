.rp-widget.select .Select-control {
  border: 1px solid #DBE2E9;
  height: 2.4rem;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
}

.rp-widget.select .Select-control .Select-arrow-zone {
  position: absolute;
  right: 1rem;
  width: 0;
}

.rp-widget.select .Select-control .Select-clear-zone {
  position: absolute;
  right: 1.4rem;
  margin-top: 1px;
}

.rp-widget.select .Select.is-focused,
.rp-widget.select .Select.is-pseudo-focused {
  outline: none;
}

.rp-widget.select .Select.is-open .Select-control {
  border-color: #4698cb;
  outline: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rp-widget.select .Select-menu-outer {
  border-right: 1px solid #4698cb;
  border-left: 1px solid #4698cb;
  border-bottom: 1px solid #4698cb;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}

.rp-widget.select .Select-menu-outer .is-selected {
  color: #4698cb;
  background-color: #F8F9FB;
}

.rp-widget.select .Select-menu-outer .is-focused {
  background-color: #EEF2FA;
}

.rp-widget.select.error .Select-control {
  border-color: #E0004D;
}

.rp-widget.select.error .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.rp-widget.select.ro {
  color: #53565A;
  line-height: 45px;
  padding: 0 0.75rem;
  box-sizing: border-box;
  margin: 0;
}

.rp-widget.select .Select-placeholder,
.rp-widget.select .Select-value-label {
  font-size: 0.9em;
  line-height: 3em;
  font-weight: 400;
  color: #53565A;
}

.rp-widget.select .Select--multi .Select-control {
  padding-right: 2.6em;
  height: 100%;
}

.rp-widget.select .Select--multi .Select-control .Select-value .Select-value-label {
  line-height: 0 !important;
}

.rp-widget.select .is-focused:not(.is-open)>.Select-control {
  box-shadow: none;
}

.rp-widget.select .Select--multi .Select-value {
  background-color: #4698CB22;
  border: 1px solid #4698CB;
}

.rp-widget.select .Select--multi .Select-value-icon {
  padding: 3px 5px 3px 5px;
}
