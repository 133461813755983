.notification-markdown-container {
  margin: 0 2em 1em 2em;
}

.notification-markdown-container h1,
.notification-markdown-container h2,
.notification-markdown-container h3 {
  font-weight: 300;
  line-height: 2.5;
}

.notification-markdown-container h1 {
  font-size: 1.6em !important;
}

.notification-markdown-container h2 {
  font-size: 1.4em !important;
}

.notification-markdown-container h3 {
  font-size: 1.2em !important;
}

.notification-markdown-container em {
  font-style: italic;
}

.notification-markdown-container strong {
  font-weight: 600;
}

.notification-markdown-container code {
  font-family: monospace;
}

.notification-markdown-container ul,
.notification-markdown-container ol {
  padding-left: 2em;
  box-sizing: border-box;
}

.notification-markdown-container ul {
  list-style: disc;
}

.notification-markdown-container ol {
  list-style: decimal;
}

.notification-markdown-container blockquote,
.notification-markdown-container pre {
  white-space: pre-wrap;
  padding: 1em 3em;
  box-sizing: border-box;
}

.notification-markdown-container p {
  margin-top: 1em;
}

.notification-markdown-container a.external-link::after {
  content: ' \f08e';
  font-family: FontAwesome, monospace;
  font-size: 0.8em;
}

.notification-summary {
  font-weight: 500;
  font-size: 1.2em;
  margin: 1em 1.6em 0 1.6em;
}