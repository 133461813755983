.rert-dispatch-overview {
  position: relative;
  display: flex;
}

.rert-dispatch-overview .response-container {
  position: relative;
  flex: 3;
  padding: 2rem 3rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rert-dispatch-overview .graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 25rem;
}

.rert-dispatch-overview .node-count-container {
  width: 100%;
  margin-top: 1.6em;
}

.rert-dispatch-overview .details-container {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.rert-dispatch-overview .details-container .reschedule-rert-dispatch-button {
  margin-top: 1.6rem;
}

.rert-dispatch-overview .details-container .cancel-rert-dispatch-button {
  margin-top: 1.6rem;
}

.rert-dispatch-overview .capacity {
  margin-bottom: 1rem;
}

.rert-dispatch-overview .capacity .value {
  font-size: 34px;
  font-weight: 300;
}

.rert-dispatch-overview .capacity .label {
  font-size: 16px;
  margin-top: -0.55rem;
}

.rert-dispatch-overview .capacity.accepted {
  padding: 2rem 0;
  border-bottom: 1px solid #DBE2E9;
}

.rert-dispatch-overview .capacity.accepted .label {
  color: #4698cb;
}

.rert-dispatch-overview .capacity.battery-power .label {
  color: rgb(38, 208, 124);
}

.rert-dispatch-overview .capacity.meter-power .label {
  color: rgb(170, 170, 170);
}

.rert-dispatch-overview strong.capacity {
  font-size: 34px;
  font-weight: 300;
  display: block;
  margin: -0.5rem 0;
}

.rert-dispatch-overview .reasons.rejected {
  margin-top: 0.5em;
}

.rert-dispatch-overview .reasons.rejected strong {
  font-weight: 500;
}

.rert-dispatch-overview-footer {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 1.6rem;
}

.rert-dispatch-overview-footer span {
  display: block;
  text-align: center;
}

.rert-dispatch-overview-footer .refresh-interval {
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.6;
}

.rert-dispatch-overview .graph-loading-container {
  position: relative;
  margin: 2em;
}
