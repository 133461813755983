.root {
  min-height: 100vh;
  height: initial;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content-wrapper {
  display: flex;
  bottom: 0;
  min-height: calc(100vh - 50px);
  height: 100%;
  /* Allow room for the header */
  flex: 1 0 auto;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.content-container {
  background-color: #F7F9FD;
  box-sizing: border-box;
  min-height: 100vh;
}

.fullscreen-dashboard {
  position: absolute;
  top: 0;
  left: 0;
}

.tooltip-body-container {
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}
