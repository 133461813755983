.fleet-tab-bar {
  display: flex;
  margin-top: -1px;
  margin-bottom: 1.4em;
  font-weight: normal;
  font-size: 0.95em;
}

.fleet-tab-bar .fleet-tab {
  padding: 1.2em 0;
  transition: all 0.3s ease-out;
  border-top: 1px solid transparent;
  display: flex;
  align-items: center;
}

.fleet-tab-bar .fleet-tab:not(:first-child) {
  margin-left: 1.8em;
}

.fleet-tab-bar .fleet-tab:hover {
  cursor: pointer;
  border-top: 1px solid #000;
  z-index: 9999;
}

.fleet-tab-bar .fleet-tab.active {
  border-top: 1px solid #4698cb;
  color: #4698cb;
  z-index: 9999;
}

.fleet-tab-bar .badge {
  background-color: #E0004D;
  padding: 0.1em 0.4em;
  border-radius: 5px;
  color: #fff;
  height: 1.25em;
  line-height: 1.2em;
  font-size: 0.9em;
  margin-left: 0.6em;
  font-weight: bold;
}
