.map-help-message-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 2em;
  pointer-events: none;
}

.map-help-message {
  background-color: #fff;
  line-height: 3em;
  color: #4698cb;
  border-radius: 2em;
  padding: 0 2em;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.5);
}

.map-loading-overlay {
  display: none;
}

.map-loading-overlay.visible {
  display: block;
  position: absolute;
  height: calc(100% - 17px);
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: map-loading-overlay-fadein 0.2s ease-in-out;
}

@keyframes map-loading-overlay-fadein {
  0%   { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.5); }
}
