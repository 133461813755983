.curtailment-response-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.curtailment-response-graph .graph-container {
  width: 100%;
  height: 100%;
  max-height: 25rem;
  margin-bottom: 2em;
}

.curtailment-response-graph .legend-container {
  display: flex;
  background-color: #fff;
  font-size: 0.85em;
  border-top: 1px solid #DBE2E9;
  border-left: 1px solid #E8E8E9;
  border-right: 1px solid #E8E8E9;
  padding: 0.46em 2em;
}

.curtailment-response-graph .legend-container .legend {
  margin: 0 0.6em;
  display: flex;
  align-items: center;
}

.curtailment-response-graph .legend-container .key-style {
  margin-right: 0.4em;
  display: inline-block;
  width: 15px;
}

.curtailment-response-graph .legend-container .key-style.accepted {
  border-top: 3px solid #4698cb;
}

.curtailment-response-graph .legend-container .key-style.battery-response {
  border-top: 3px solid rgb(38, 208, 124);
}

.curtailment-response-graph .legend-container .key-style.meter-response {
  border-top: 3px solid rgb(170, 170, 170);
}

.curtailment-response-graph .legend-container .key-style.solar-prediction {
  border-top: 3px dashed #F68D26;
}

.curtailment-response-graph .legend-container .key-style.meter-prediction {
  border-top: 3px dashed rgba(170, 170, 170, 0.6);
}

.curtailment-response-graph .legend-container .key-style.grid-response {
  border-top: 3px dashed #4698cb;
}

.curtailment-response-graph .legend-container .key-style.solar-response {
  border-top: 3px dotted #26D07C;
}

.curtailment-response-graph .graph-label {
  position: absolute;
  right: 4.5rem;
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.8;
}

.curtailment-response-graph .graph-label.import {
  top: 2.2rem;
}

.curtailment-response-graph .graph-label.export {
  bottom: 2rem;
}
