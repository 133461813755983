.user-notification-container {
  display: flex;
  align-items: center;
}

.user-notification-container .read-spacer,
.user-notification-container .unread-marker {
  height: 14px;
  width: 14px;
  margin: 0 1em 0 0.6em;
}

.user-notification-container .unread-marker {
  border-radius: 7px;
  background-color: #4698cb;
}

.user-notification-container .datetime {
  color: #4698cb;
}

.user-notification-mark-read-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
}