.mfa-settings-form {
  margin-top: 1em;
  line-height: 1.5em;
}

.mfa-settings-form-container .field-box h6 {
  font-size: 12px;
  color: #4698cb;
  line-height: 1;
  margin-bottom: 3px;
}

.mfa-settings-form-container .field-box {
  position: relative;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  display: block;
}

.mfa-settings-form-container .field-box input {
  width: 16em;
}

.mfa-settings-form-container button.mp-button {
  margin-left: 0;
}

.mfa-settings-form-container .google-auth-help {
  padding: 1em 0;
}
