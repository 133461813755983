.curtailment-overview {
  position: relative;
  display: flex;
  height: 100%;
}

.curtailment-overview .response-container {
  position: relative;
  flex: 3;
  padding: 2rem 3rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.curtailment-overview .graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 25rem;
}


.curtailment-overview .node-count-container{
  width: 100%;
  margin-top: 5em;
}

.curtailment-overview .details-container {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.curtailment-overview .deployment-list {
  position: relative;
  
}

.curtailment-overview .request {
  margin-bottom: 1rem;
  border-bottom: 1px solid #DBE2E9;
}

.curtailment-overview .request .value {
  font-size: 34px;
  font-weight: 300;
  padding-top: 1em;
}

.curtailment-overview .request .label {
  font-size: 16px;
  margin-top: -0.55rem;
  margin-bottom: 1.7em;
}


.curtailment-overview .request.power .label {
  color: #4698cb;
}

.curtailment-overview strong.request {
  font-size: 34px;
  font-weight: 300;
  display: block;
  margin: -0.5rem 0;
}

.curtailment-overview .reasons.rejected {
  margin-top: 0.5em;
}

.curtailment-overview .reasons.rejected strong {
  font-weight: 500;
}

.curtailment-overview-footer {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 1.6rem;
}

.curtailment-overview-footer span {
  display: block;
  text-align: center;
}

.curtailment-overview-footer .refresh-interval {
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.6;
}

.curtailment-overview .graph-loading-container {
  position: relative;
  margin: 2em;
}
