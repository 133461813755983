.fleet-date-picker .react-datepicker__triangle {
  left: 25px;
}

.fleet-date-picker-icon {
  cursor: pointer;
  font-size: 1rem;
}

.fleet-date-picker.with-input {
  display: flex;
}

.fleet-date-picker.with-input input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fleet-date-picker.with-input.focus .fleet-date-picker-icon {
  border: 1px solid #4698CB;
  border-left: 1px solid #DBE2E9;
}

.fleet-date-picker.with-input .fleet-date-picker-icon {
  border: 1px solid #DBE2E9;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -1px;
  transition: all 0.3s ease-out;
}

.fleet-date-picker .fleet-date-picker-icon:hover {
  color: #1F2A44;
}

.fleet-date-picker .fleet-date-picker-icon {
  height: 2.25rem;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
  vertical-align: middle;
  border: 1px solid transparent;
}

.fleet-date-picker.inline {
  display: inline-block;
  margin: 0 0.4em;
  box-sizing: border-box;
}

.fleet-date-picker.inline:focus {
  outline: none;
}

.fleet-date-picker.inline {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.fleet-date-picker.inline .fa {
  margin-left: 0.45em;
}

.fleet-date-picker.inline:hover {
  color: #1F2A44;
}

.widget.error  {
  height: 100%;
  border-color: #E0004D;
}

.fleet-date-picker-icon.error {
  border-color: #E0004D !important;
}

.error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.fleet-date-picker.ro {
  color: #53565A;
  line-height: 45px;
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin: 0;
}

.rp-widget.select-dd .current-value .fa {
  margin-left: 0.45em;
}

.rp-widget.select-dd.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: initial;
}

.react-datepicker {
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 1px solid #dee4f0;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
}

.react-datepicker,
.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: #363636;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #4698CB;
  color: #fff !important;
}

.react-datepicker__day:hover {
  background-color: #e6ecf6;
  color: #363636;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  color: #363636 !important;
}

.react-datepicker__triangle {
  border-bottom-color: #f7f9fd !important;
}

.react-datepicker__triangle::before {
  border-bottom-color: #dee4f0 !important;
}

.react-datepicker__header {
  background-color: #f7f9fd;
  border-bottom: 1px solid #dee4f0;
}

.react-datepicker__current-month {
  font-weight: 500;
}

.react-datepicker__day {
  font-size: 0.85rem;
  font-weight: 400;
}

.react-datepicker__day--today {
  font-weight: 600 !important;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc !important;
}

.react-datepicker__day--disabled:hover {
  cursor: default;
  background: none;
}

.react-datepicker__today-button {
  background-color: #F7F9FD;
  border-top: 1px solid #dee4f0;
}

.react-datepicker__tether-element {
  left: -8px !important;
}
