.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found h1 {
  font-size: 12em;
  color: #D9E2EA;
  font-weight: 200;
}

.not-found h2 {
  color: #4698cb;
  font-size: 2em;
  font-weight: 300;
}

.not-found p {
  margin: 2em 0;
  width: 30em;
  text-align: center;
}
