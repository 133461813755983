h6.no-deployments-message {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.site-label-cell {
  max-height: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}