.data-export-summary-item .no-data,
.data-export-summary-item .progress-help {
  font-style: italic;
  opacity: 0.6;
}

.data-export-summary-item .rp-widget.infobox {
  margin-top: 1em;
}

.explanation-modal-link {
  padding-top: 1em;
}
