.mfa-modal-confirm-phone-container {
  padding: 1.5rem 2.45rem 0 2.45rem;
}

.mfa-modal-confirm-phone-container .code-wrapper {
  padding: 0.6em;
}

.mfa-modal-confirm-phone-container .code-wrapper input {
  width: 250px;
}
