.tariff-summary {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tariff-summary .tariff-info-container {
  flex: 1;
  min-width: 160px;
  width: 100%;
  padding-left: 1rem;
}

.tariff-summary .tariff-info-container h4 {
  font-weight: 400;
  padding: 0;
  margin: 0 0 0.8rem 0 !important;
  font-size: 1rem !important;
  color: #4698cb;
}

.tariff-summary-table,
.tariff-summary-rate-table {
  margin-top: 1.42rem;
  width: 100%;
}

.tariff-summary .discount-help-text {
  color: #808387;
  font-style: italic;
}

.tariff-summary .no-tariff {
  font-size: 1.3em;
  font-weight: 300;
  margin-top: 1em;
}
