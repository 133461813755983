.public_fixedDataTable_header .fixedDataTableCellLayout_wrap3 {
  padding-top: 15px;
  padding-left: 5px;
}

h6.no-customers-message {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.discount-container {
  font-style: italic;
}

.discount-container label {
  font-weight: 500;
  margin-right: 0.5em;
}

.customer-info-info {
  color: #E0004D;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.customer-info-info .icon {
  font-size: 1.1rem;
  line-height: 1.7rem;
  width: 25px;
}
