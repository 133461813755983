.graph-widget {
  height: 100%;
  width: 100%;
}

.graph-widget .stats-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1.8em 1.4em;
  height: 4rem;
}

.graph-widget .stats-container .stat {
  width: 50%;
  box-sizing: border-box;
}

.graph-widget .stats-container .stat:first-child {
  margin-right: 1rem;
}

.graph-widget .stats-container .value {
  font-size: 2.25rem;
  font-weight: 200;
  line-height: 2.6rem;
}

.graph-widget .stats-container .label {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.03em;
}

.graph-widget.solar .stat .value {
  color: rgb(255, 157, 66);
}

.graph-widget.meter .stat .value {
  color: rgb(70, 152, 203);
}

.graph-widget.charge .stat .value {
  color: rgb(87, 196, 199)
}

.graph-widget .graph-container {
  width: 100%;
  height: calc(100% - 4rem - 10px); /* height of stats-container minus room for axis labels */
}

.fullscreen-dashboard .graph-widget .stats-container {
  justify-content: space-evenly;
  height: 13rem;
}

.graph-widget .stats-container .stat {
  width: initial;
}

.fullscreen-dashboard .graph-widget .stats-container .value {
  font-size: 7rem;
  font-weight: 300;
  line-height: 8rem;
  width: 100%;
  text-align: center;
}

.fullscreen-dashboard .graph-widget .stats-container .label {
  font-size: 2.6rem;
  font-weight: 400;
  width: 100%;
  text-align: center
}

.fullscreen-dashboard .graph-widget .graph-container {
  width: 100%;
  height: calc(100% - 9rem - 60px); /* height of stats-container minus room for the ticker */
}
