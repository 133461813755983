.api-keys-table {
  border-bottom: 1px solid #DBE2E9;
  width: 100%;
}

.api-keys-table .no-api-keys-message {
  margin-right: 1em;
}

.add-api-key-button {
  margin: 1em;
}

.revoked-api-key {
  color: #9ea1a5;
}

.revoked-api-key :last-child {
  text-align: center;
}

.api-keys-table td {
  line-height: 1.5em;
}

.add-api-key-button.with-table {
  margin: 0;
  margin-bottom: 2rem;
  position: relative;
}

.add-api-key-button.with-table button {
  position: absolute;
  right: 1rem;
}
