.tariff .rates-table {
  width: 100%;
}

.tariff .rates-table input {
  display: inline-block;
}

.tariff .rates-table.ro td {
  padding: 0.2em;
}

.tariff .rates-table td.label {
  vertical-align: top;
}

.tariff .rates-table td.label input {
  max-width: 150px;
}

.tariff .rates-table td.block-onset input {
  max-width: 60px;
  margin: 0 0.4em;
  padding-right: 0.2rem;
}

.tariff .rates-table td.price input {
  max-width: 90px;
}

.tariff .rates-table .block-add {
  /*!*position: absolute;*!*/
  /*right: 0.5em;*/
  /*top: 35%;*/
  float: right;
}

.tariff .rates-table .block-add button,
.tariff .rates-table td.rate-add button {
  font-size: 0.95em;
}

.tariff .rates-table td.rate-add {
  text-align: right;
}

.tariff .rates-table tr.base-rate {
  background-color: #EEF2FA;
}

.tariff .rates-table .base-rate-text {
  display: block;
  font-style: italic;
  font-size: 0.85em;
  padding-left: 0.6rem;
  color: #808387;
}

.tariff .rates-table.ro .base-rate-text {
  margin-top: -1em;
}
