.unassigned-deployments-table-container {
  min-height: 35em;
  margin: 0 2.45rem;
}

.unassigned-deployment-children {
  margin: 0.3rem 2.45rem;
  min-height: 36px;
}

