.new-dispatch-info {
  padding: 0 2.45rem 1rem 2.45rem;
}

.new-dispatch-info > div {
  margin: 0.6em 0;
}

.new-dispatch-info.grid-outage {
  margin-top: 2em;
}

.new-dispatch-info .customer-msg {
  display: flex;
}

.new-dispatch-info .customer-msg-summary {
  max-width: 95%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
  border-bottom: 1px dotted #4698cb;
}

.new-dispatch-info .customer-msg .edit-container {
  overflow: hidden;
  position: relative;
  color: #4698cb;
  cursor: pointer;
  line-height: 0.8em;
  transition: color 0.3s ease;
  display: flex;
  width: 85%
}

.new-dispatch-info .customer-msg .edit-container:hover {
  color: #1F2A44;
}

.new-dispatch-info .customer-msg .title {
  white-space: nowrap;
  margin-right: 0.3em;
}

.new-dispatch-info .customer-msg .edit-icon {
  font-size: 0.7em;
  margin-top: 7px;
  margin-left: 5px;
}

.new-dispatch-info .lockout-message {
  font-style: italic;
  color: #85888c;
  font-size: 0.9em;
  line-height: 1.8em;
}
