.page .page-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBE2E9;
  position: relative;
  min-height: 62px;
}

.page .page-header .break {
  flex-basis: 100%;
  height: 0;
}

.page .page-header h1 {
  font-size: 1.71em;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page .page-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
}

.page .page-content h2 {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 2.5;
}

.page .page-search-container {
  padding: 2rem 0 1rem 0;
  display: flex;
  align-items: center;
}

.page .page-search-container>div:not(:first-child),
.page .page-search-container>span:not(:first-child) {
  margin-left: 1em;
}

.page .breadcrumb {
  font-size: 0.8em;
  margin-top: 5px;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  left: 0;
}

.page .breadcrumb:hover,
.page .breadcrumb:hover a {
  opacity: 1;
  text-decoration: none;
}

.page .breadcrumb a {
  position: relative;
}

.page .breadcrumb .back-icon {
  font-size: 0.8em;
  position: absolute;
  top: 3px;
}

.page .breadcrumb .breadcrumb-label {
  margin-left: 1em;
}

.page .header-actions {
  white-space: nowrap;
}

.page .subheading {
  position: absolute;
  bottom: 0.1em;
  left: 0.1em;
  color: #6c6f73;
}
