.new-dispatch-pricing {
  padding: 1.5rem 2.45rem 0 2.45rem;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.new-dispatch-pricing > div {
  flex: 1;
}

.new-dispatch-pricing h4 {
  color: #4698cb;
  font-size: 0.9em;
}

.new-dispatch-pricing .price {
  font-size: 2.2em;
  font-weight: 300;
}
