.labelled-content .label {
  width: 6rem;
  display: inline-block;
  font-weight: 500;
}

.labelled-content.north .label {
  display: block;
  width: initial;
}

.labelled-content.west .label {
  display: inline-block;
  width: 6rem;
}

.labelled-content {
  display: block;
  margin: 0.7em 0 0.7em 0;
  letter-spacing: -0.015em;
}
