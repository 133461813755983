.password-requirements {
    display: inline-block;
    line-height: 1.75;
    text-align: left;
}

.password-requirements ul {
    margin-left: 3ex;
}

.password-requirements li>span {
    color: #4698cb;
}