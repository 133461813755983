.sidebar {
  width: 300px;
  border-right: solid 1px #DBE2E9;
  min-height: 100%;
  position: fixed;
  margin-top: 50px;
  height: 100vh;
  overflow-y: auto;
}

.sidebar li {
  border-left: 5px solid transparent;
  border-top: 1px solid #DBE2E9;
  font-weight: 400;
  line-height: 2.4em;
}

.sidebar li:hover {
  border-left: 5px solid rgba(70, 152, 203, 0.4);
}

.sidebar li.active {
  height: initial;
}

.sidebar li a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #53565A;
  padding: 1em 0;
}

.sidebar li .submenu a:not(:first-child) {
  padding: 0.5em 0;
}

.sidebar li.active a:first-child {
  font-weight: 500;
}

.sidebar li .submenu a:not(:first-child) {
  padding: 0;
}

.sidebar li .submenu a:first-child {
  padding-bottom: 0;
}

.sidebar li .active-container a:first-child {
  padding-bottom: 0.2em;
}

.sidebar li .active-container a:last-child {
  padding-bottom: 1em;
}

.sidebar li a:not(:first-child) {
  line-height: 2em;
  font-weight: 300;
}

.sidebar li a:hover {
  color: #4698CB;
}

.sidebar .active-container {
  display: flex;
  align-items: flex-start;
}

.sidebar li a.active {
  color: #4698CB;
}

.sidebar li a:hover {
  text-decoration: none;
}

.sidebar li:first-child {
  border-bottom: 1px solid #90C1E0;
}

.sidebar li:last-child {
  border-top: 1px solid #90C1E0;
  border-bottom: 1px solid #DBE2E9;
}

.sidebar li.active {
  border-left: 3px solid #4698CB;
  background-color: #F8F9FB;
}

.sidebar li .icon-container {
  width: 4.5em;
  display: flex;
  justify-content: center;
}

.sidebar li.active .icon-container {
  padding-top: 1em;
}

.sidebar .badge {
  background-color: #E0004D;
  /*background-color: #4698cb;*/
  padding: 0.1em 0.4em;
  border-radius: 5px;
  color: #fff;
  height: 1.25em;
  line-height: 1.2em;
  font-size: 0.9em;
  margin-left: 0.6em;
  font-weight: bold;
}
