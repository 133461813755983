.kit-design-form.kit-summary {
  line-height: 2;
}

.kit-design-form.kit-summary b {
  font-weight: bold;
}

.kit-design-form.kit-summary .illustration-bar img {
  height: 10em;
  margin: auto 0;
}

.kit-design-form.kit-summary .order-table {
  margin-top: 1em;
  line-height: normal;
  table-layout: fixed;
  width: 100%;
}

.kit-design-form.kit-summary .order-table thead th:nth-child(1) {
  width: 26%;
}

.kit-design-form.kit-summary .order-table thead th:nth-child(3) {
  width: 2em;
  text-align: right;
}

.kit-design-form.kit-summary .order-table tbody tr td:nth-child(3) {
  text-align: right;
}

.kit-summary-email-button {
  flex-grow: 1;
}

.kit-summary-email-button .email-success {
  float: right;
  padding-right: 0.9rem;
  color: #ADB4C1;
}

.kit-summary-email-button .email-success i.fa {
  margin-right: 0.6em;
}

.fleet-modal .kit-summary-email-button button.secondary {
  float: right;
}
