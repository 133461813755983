.tariff .periods-table {
  width: 100%;
}

.tariff .periods-table td {
  padding: 0.3em;
}

.tariff .periods-table td.period-add button{
  font-size: 0.95em;
}

.tariff .periods-table td.period-add button {
  float: right;
}

.tariff .periods-table td.period-add .error-message,
.tariff .periods-table td.time-range .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.tariff .periods-table td.time-range input {
  display: inline-block;
}

.tariff .periods-table td.time-range input,
.tariff .periods-table td.time-range .error {
  max-width: 70px;
}

.tariff .periods-table td.time-range .rp-widget {
  display: inline-block;
  vertical-align: top;
}

.tariff .periods-table td.time-range .rp-widget:not(:first-child) {
  margin-left: 0.5em;
}

.tariff .periods-table td.days label {
  display: inline-block;
  text-align: center;
  padding: 0 0.2em;
}

.tariff .periods-table td.days label .text {
  font-size: 0.9em;
}

.tariff .periods-table td.days .checkbox {
  display: block;
  width: 100%;
  text-align: center;
}

.tariff .periods-table td.days .rp-widget.checkbox {
  height: initial;
}

.tariff .periods-table td.days .checkbox .control {
  margin: 0;
}

.tariff .periods-table td.rate input {
  min-width: 60px;
}

.tariff .periods-table .days .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}
