.close-search-control {
  margin-bottom: 11px;
  margin-right: 5px;
}

.customer-list-searchbar {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.customer-list-searchbar .Select-menu-outer {
  z-index: 10;
}
