.fleet-deployment-location {
  display: flex;
  height: 100%;
}

.fleet-deployment-location .location-info {
  padding: 1em 2em;
  flex: 2;
}

.fleet-deployment-location .map-container {
  flex: 5;
  height: 100%;
}

.fleet-deployment-location .location-info .labelled-content:not(:last-child) {
  margin-bottom: 2em;
}

.fleet-deployment-location .location-info .labelled-content .label {
  margin-bottom: 0.4em;
}

.fleet-deployment-location .location-info .labelled-content .value {
  font-size: 1.3em;
  font-weight: 300;
}
