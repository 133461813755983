.new-dispatch-response-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.new-dispatch-response-graph .graph-container {
  width: 100%;
  height: 100%;
}

.new-dispatch-response-graph .legend-container {
  display: flex;
  background-color: #fff;
  font-size: 0.85em;
  border-top: 1px solid #DBE2E9;
  padding: 0.46em 2em;
}

.new-dispatch-response-graph .legend-container .legend {
  margin: 0 0.6em;
  display: flex;
  align-items: center;
}

.new-dispatch-response-graph .legend-container .key-style {
  margin-right: 0.4em;
  display: inline-block;
  width: 15px;
}

.new-dispatch-response-graph .legend-container .key-style.prediction {
  border-top: 3px dotted #26D07C;
}

.new-dispatch-response-graph .legend-container .key-style.adjusted-prediction {
  border-top: 3px dotted #4698cb;
}

.new-dispatch-response-graph .legend-container .key-style.market-prediction {
  border-top: 3px dotted rgba(68, 68, 68, 0.6);
}

.new-dispatch-response-graph .graph-label {
  position: absolute;
  right: 4.5rem;
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.8;
}

.new-dispatch-response-graph .graph-label.import {
  top: 2rem;
}

.new-dispatch-response-graph .graph-label.export {
  bottom: -1.5rem;
}
