.editable-input-container {
  display: flex;
  font-size: medium;
  margin-bottom: 12px; 
  position: relative;
}

.editable-input-placeholder {
  font-style: italic;
  font-weight: 200;
}

.editable-input-icon {
  padding-left: 5px;
}

.editable-input-cancel {
  color: #9ea1a5;
}

.editable-input-read-only {
  line-height: 38px;
  box-sizing: border-box;
  margin: 0;
}

.editable-input input{
  width: 300px;
}

.editable-input-container a {
  transform: translate(0, 5px);
}