.rp-widget.select-dd {
  position: relative;
  display: inline-block;
  padding: 0 0.4em;
  box-sizing: border-box;
}

.rp-widget.select-dd:focus {
  outline: none;
}

.rp-widget.select-dd .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.rp-widget.select-dd .current-value.error {
  border-color: #F68D26;
  color: #F68D26;
  transition: color 0.3s ease-out;
}

.rp-widget.select-dd .current-value:after {
  content: '\f107';
  font-family: "FontAwesome", monospace;
  font-weight: 100;
  margin-left: 0.45em;
}

.rp-widget.select-dd.ro .current-value:after {
  content: '';
  margin-left: 0;
}

.rp-widget.select-dd .current-value:hover {
  color: #1F2A44;
}

.rp-widget.select-dd.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: initial;
}

.rp-widget.select-dd .options-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: pointer;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: initial;
}

.rp-widget.select-dd .options-container.up {
  bottom: 3em;
}

.rp-widget.select-dd .options-scroll-container {
  max-height: 250px;
  overflow-y: auto;
  margin-top: 1em;
}

.rp-widget.select-dd .options-container.up .options-scroll-container {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1em;
  margin-top: unset;
}

.rp-widget.select-dd .options-container .option {
  white-space: nowrap;
  border-bottom: 1px solid #DBE2E9;
  box-sizing: border-box;
  padding: 0.8em 1em;
  font-size: 0.9em;
}

.rp-widget.select-dd .options-container.up .option:first-child {
  border-bottom: unset;
}

.rp-widget.select-dd .options-container:not(.up) .option:last-child {
  border-bottom: none;
}

.rp-widget.select-dd .options-container .option:hover {
  text-decoration: underline;
}

.rp-widget.select-dd .options-container .option.selected {
  color: #4698cb;
}

.rp-widget.select-dd .options-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 1em;
  transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.rp-widget.select-dd .options-container.up .tip {
  bottom: -10px;
  top: unset;
  box-shadow: -4px -6px 12px 0 rgba(0, 0, 0, 0.16);
}

