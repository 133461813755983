.monitoring-graph-controls {
  display: flex;
  flex-wrap: wrap;
}

.monitoring-graph-controls .control {
  width: 50%;
  padding-bottom: 1em;
  display: flex;
}

.monitoring-graph-controls .control > label {
  font-size: 1.05em;
  font-weight: 300;
  min-width: 8.5em;
}

.monitoring-graph-controls .control .checkbox-label {
  display: inline-flex;
  padding: 0;
}

.monitoring-graph-controls .control .rp-widget.checkbox {
  margin-left: 0.4em;
}

.monitoring-graph-controls .control .checkbox-label .label-text {
  margin-left: 0.6em;
  font-weight: 300;
}

.monitoring-graph-controls .toggles-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 3em;
}

.monitoring-graph-controls .toggles-container .rp-widget.checkbox {
  width: initial;
  height: initial;
  position: initial;
}

.monitoring-graph-controls .label-button-checkbox {
  display: block;
  border: 1px solid #DBE2E9;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 0.85em;
  color: #4698CB;
  padding: 0.15em 2em;
  margin-bottom: 0.6em;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  user-select: none;
  max-width: 20em;
}

.monitoring-graph-controls input:checked + .label-button-checkbox {
  color: #fff;
}

.monitoring-graph-controls input:checked + .label-button-checkbox.grid {
  background-color: rgb(70, 152, 203);
  border-color: rgb(70, 152, 203);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.grid:hover {
  background-color: rgba(70, 152, 203, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.grid:hover {
  background-color: rgba(70, 152, 203, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.solar {
  background-color: rgb(246, 141, 38);
  border-color: rgb(246, 141, 38);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.solar:hover {
  background-color: rgba(246, 141, 38, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.solar:hover {
  background-color: rgba(246, 141, 38, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.sga {
  background-color: rgb(31, 42, 68);
  border-color: rgb(31, 42, 68);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.sga:hover {
  background-color: rgba(31, 42, 68, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.sga:hover {
  background-color: rgba(31, 42, 68, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.battery,
.monitoring-graph-controls input:checked + .label-button-checkbox.soc {
  background-color: rgb(224, 0, 77);
  border-color: rgb(224, 0, 77);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.battery:hover {
  background-color: rgba(224, 0, 77, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.battery:hover {
  background-color: rgba(224, 0, 77, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.house {
  background-color: rgb(81, 199, 109);
  border-color: rgb(81, 199, 109);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.house:hover {
  background-color: rgba(81, 199, 109, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.house:hover {
  background-color: rgba(81, 199, 109, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.submeter {
  background-color: rgb(87, 196, 199);
  border-color: rgb(87, 196, 199);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.submeter:hover {
  background-color: rgba(87, 196, 199, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.submeter:hover {
  background-color: rgba(87, 196, 199, 0.3);
}