.stats-panel {
  color: #fff;
  font-weight: 300;
  border-radius: 5px;
  width: 100%;
  padding: 0 1.8em 1.6em 1.8em;
  box-sizing: border-box;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

.stats-panel:not(:first-child) {
  margin-top: 1.2em;
}

.stats-panel.blue {
  background: linear-gradient(to top left, #347299, #3191CC);
}

.stats-panel.blue header {
  border-bottom: 1px solid rgb(37, 118, 181);
}

.stats-panel.red {
  background: linear-gradient(to top left, #AD003D, #E0004D);
}

.stats-panel.red header {
  border-bottom: 1px solid #bc003f;
}

.stats-panel.yellow {
  background: linear-gradient(to top left, #DB7E21, #F58D25);
}

.stats-panel.yellow header {
  border-bottom: 1px solid #e18421;
}

.stats-panel.green {
  background: linear-gradient(to top left, #1C9E5D, #2AD17D);
}

.stats-panel.green header {
  border-bottom: 1px solid #1FAD67;
}

.stats-panel.white {
  background: linear-gradient(to top left, #ececec, #fcfcfc);
  color: #53565A;
}

.stats-panel.white header {
  border-bottom: 1px solid #cccccc;
}

.stats-panel header {
  height: 2.8em;
  margin-bottom: 0.6em;
  display: flex;
  align-items: center;
}

.stats-panel header h3 {
  width: 95%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.stats-panel ul li {
  display: flex;
  margin-bottom: 0.4em;
}

.stats-panel label,
.stats-panel span.value {
  flex: 1;
}

.stats-panel label {
  font-weight: 600;
}

.stats-panel .stats-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}

.stats-panel .stats-grid > div {
  flex: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.stats-panel .stats-grid div.value {
  font-weight: 500;
  font-size: 1.2em;
}

.stats-panel .stats-grid label {
  font-weight: 300;
}

.stats-panel .loading {
  position: relative;
  margin-bottom: 0.6em;
}