.new-dispatch-confirmation .new-dispatch-info > div {
  margin: 0;
}

.new-dispatch-confirmation .new-dispatch-info .ro {
  font-weight: 500;
}


.new-dispatch-confirmation .pricing-summary {
  border-top: 1px solid #DBE2E9;
  border-bottom: 1px solid #DBE2E9;
  margin: 1rem 2.45rem 1rem 2.45rem;
  padding: 1rem 0 1rem 0;
}

.new-dispatch-confirmation .labelled-content .label {
  font-weight: normal;
}

.new-dispatch-confirmation .confirm-message {
  padding: 3rem 2.45rem 3rem 2.45rem;
  font-size: 1.2em;
  text-align: center;
}
