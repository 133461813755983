.header {
  height: 50px;
  background-color: #53565A;
  display: block;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.header .header-content {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #53565A;
}

.header .notification-container {
  width: 300px;
  color: #FFFFFF;
  background-color: #37393D;
  height: 100%;
  font-weight: 300;
  line-height: 50px;
  display: flex;
  position: relative;
  border-right: 1px solid #292B2E;
  cursor: pointer;
}

.header .notification-container:hover {
  background-color: #53565A;
  text-decoration: none;
}

.header .notification-bell-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 4.5em;
  justify-content: center;
}

.header .notification-bell {
  height: 3.13rem;
  width: 1.19rem;
}

.header .notification-unread-alert {
  position: absolute;
  top: 0.85em;
  left: 2.3em
}

.header .user-container {
  display: flex;
  position: relative;
  align-items: center;
  border-left: 1px solid #37393D;
  padding: 0 2.5em;
  cursor: pointer;
}

.header .user-container:focus {
  outline: none;
}

.header .user-container .username {
  color: #DBE2E9;
  font-weight: 300;
  letter-spacing: 0.05em;
}

.header .user-container .username:after {
  content: "\f078";
  font-family: "FontAwesome", monospace;
  margin-left: 1em;
  top: -1px;
  position: relative;
}

.header .user-container:hover {
  background-color: #4B4E52;
}

.header .user-container:hover .username:after {
  color: #4698cb;
}
