.curtailment-controls .rc-slider-disabled {
  background-color: unset;
}

.curtailment-controls .rc-slider-disabled .rc-slider-handle {
  box-shadow: none;
}

.curtailment-controls .rc-slider-rail {
  width: 100%;
  height: 8px;
  border-radius: 2px;
}

.curtailment-controls .rc-slider-handle {
  margin-top: -15px;
  margin-left: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.curtailment-controls .headers {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.curtailment-controls .footers {
  display: flex;
  justify-content: space-between;
}

.curtailment-controls .headers {
  margin-bottom: 0.6rem;
}

.curtailment-controls .footers {
  font-size: 0.87em;
  margin-top: 1.1rem;
  color: #4698cb;
}

.curtailment-controls-drop-down {
  position: relative;
  display: inline-block;
  padding-left: 0.4em;
  box-sizing: border-box;
}

.curtailment-controls-drop-down:focus {
  outline: none;
}

.curtailment-controls-drop-down .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.curtailment-controls-drop-down.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: inherit;
}

.curtailment-controls-drop-down .current-value:hover {
  color: #1F2A44;
}

.curtailment-controls-drop-down .current-value .fa {
  margin-left: 0.45em;
}

.curtailment-controls-drop-down .curtailment-controls-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40em;
  padding: 1em;
  box-sizing: border-box;
  left: -25em;
}

.curtailment-controls-drop-down .curtailment-controls-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 26em;
  transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.curtailment-controls-drop-down .curtailment-controls-wrapper {
  width: 100%;
}
