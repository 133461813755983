.billing-gc-container {
  padding-top: 1em;
  box-sizing: border-box;
}

.billing-transaction-item {
  background-color: #fff;
  margin-bottom: 8px;
}

.billing-transaction-heading {
  line-height: 3.5em;
  box-sizing: border-box;
  padding: 0 8px;
  border-right: 3px solid transparent;
}

.billing-transaction-heading .total {
  float: right;
}

.billing-transaction-heading .expand-icon {
  float: right;
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5em;
  color: #dee4f0;
}

.billing-transaction-heading:hover {
  cursor: pointer;
}

.billing-transaction-item:hover {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.react-sanfona-item-body-wrapper {
  padding: 1em 2.5em;
}

.billing-transaction-item .label {
  width: 12rem;
}

.billing-transaction-item.billing-transaction-expanded {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.billing-transaction-item .link-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.billing-transaction-expanded .billing-transaction-heading {
  background-color: #f7f9fd;
  border-right: 3px solid #45a9d3;
}

.billing-transaction-body {
  display: flex;
}

.billing-transaction-body .summary,
.billing-transaction-body .controls {
  width: 50%;
}

.billing-transaction-body .controls {
  padding: 1.5em 2em;
}
