.dashboard-add-widget-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2.45rem;
  max-height: 70vh;
  overflow-y: auto;
}

.dashboard-add-widget-container .add-widget-tile {
  padding: 1em;
  text-align: center;
  width: 30%;
  max-width: 30%;
  border: 1px solid #dee4f0;
  font-weight: 400;
  box-sizing: border-box;
  height: 15em;
  margin-bottom: 1em;
}

.dashboard-add-widget-container .add-widget-tile:hover {
  cursor: pointer;
  background-color: #4698CB;
  color: #fff;
}


.dashboard-add-widget-container .add-widget-tile h3 {
  font-size: 1.4em;
  margin-top: 0.4em;
}

.dashboard-add-widget-container .add-widget-tile .widget-description {
  font-weight: 400;
}

