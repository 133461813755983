.inline-password-change-box {
    display: block;
}

.user-setting.password .inline-password-description {
    line-height: 1.5;
    margin-top: 1ex;
}

.user-setting.password .error-message {
    color: #E0004D;
}

.user-setting.password .password-requirements {
    vertical-align: top;
}

.user-setting.password .inline-password-change-box {
    display: block;
    margin-top: 1em;
}

.user-setting.password .inline-password-change-fields {
    display: inline-block;
    margin-right: 3em;
}

.user-setting.password .inline-password-change-form .field-box {
    position: relative;
    margin-top: 1ex;
    margin-bottom: 1ex;
    display: block;
}

.user-setting.password .inline-password-change-form .field-box h6 {
    font-size: 12px;
    color: #4698cb;
    line-height: 1;
    margin-bottom: 3px;
}

.user-setting.password .inline-password-change-form .field-box input {
    /*
     * Reserves space for error messages.
     *
     * (Stops the text boxes jumping jumping around when errors are shown/hidden.)
     */
    margin-bottom: 2.5ex;
    width: 16em;
}

.user-setting.password .inline-password-change-form button.mp-button {
    margin-left: 0;
}

.user-setting.password .input .error-message {
    line-height: 1;
    margin-top: 3px;
    position: absolute;
    bottom: 0;
}

.user-setting.password .inline-password-change-form button {
    display: block;
    margin-top: 0.5ex;
}

.user-setting.password .inline-password-change-form button[disabled] {
    background-color: rgba(50, 118, 155, 0.50);
    cursor: default;
}

.user-setting.password .inline-password-change-form .success-message {
    padding-left: 0.9em; /* matches padding of text within button */
}

.user-setting.password .inline-password-change-form .success-message .fa {
    margin-right: 0.6em; /* matches spacing in buttons */
}
