@import 'https://fonts.googleapis.com/css?family=Work+Sans:300,400,500';

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 13px;
}

html,
body {
  height: 100%;
}

body {
  font-size: 14px;
  color: #53565A;
  margin: 0;
  padding: 0;
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
}

a,
a:visited,
a:active,
a:focus,
.link,
.mp-button.link {
  text-decoration: none;
  color: #4698CB;
  transition: all 0.3s ease-out;
}

a:hover,
.link:hover,
.mp-button.link:hover {
  text-decoration: none;
  color: #1F2A44;
  cursor: pointer;
}

.public_fixedDataTableCell_cellContent a:hover,
.public_fixedDataTableCell_cellContent .link:hover {
  color: #1F2A44 !important;
}

.public_fixedDataTableCell_cellContent a,
.public_fixedDataTableCell_cellContent .link {
  color: #4698CB !important;
}

.rc-tooltip {
  z-index: 999999;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.6em;
}


table tbody tr:hover {
  box-shadow: 0px 0px 10px 0px #ddd;
}

table tbody tr[role="button"]:hover {
  cursor: pointer;
}

table tbody tr:hover a,
table tbody tr:hover link {
  text-decoration: none;
  color: #1F2A44;
}

table td {
  padding: 0.25em 0.5em;
  vertical-align: middle;
}

table tbody td {
  background: #fff;

}

table thead tr th {
  text-align: left;
  padding: 0.2em 0.5em;
  color: #4698CB;
}

table tbody tr.no-click {
  cursor: default;
}

table.table-light {
  width: 100%;
  border-collapse: collapse;
}

table.table-light tr {
  border-top: 1px solid #DBE2E9;
}

table.table-light tbody tr:hover {
  cursor: default;
}

table.table-light td {
  padding: 1em;
  vertical-align: middle;
  background: none;
  height: 28px;
  border-top: 1px solid #DBE2E9;
}

table.table-light thead tr th {
  padding: 0.5em 0em 0em 1em;
  text-align: left;
  color: #4698CB;
  height: 0;
  border-top: 1px solid #DBE2E9;
}

table.table-light tbody tr:hover {
  box-shadow: none;
}

table.table-light tbody tr a:hover,
table.table-light tbody tr link:hover {
  text-decoration: none;
  color: #1F2A44;
  cursor: default;
}

.fleet-table-no-results {
  width: 100%;
  text-align: center;
  padding: 2em 0;
  font-size: 1.3em;
  font-weight: 300;
}

.label-text {
  color: #4698CB;
}

.notification-dismiss:before {
  content: '\f00d';
  font-family: "FontAwesome", monospace;
  color: #fff;
  height: 14px;
  top: calc(50% - 7px);
  position: absolute;
  font-size: 1rem;
}

td.actions .mp-button.cancel {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 1em;
  color: #ADB4C1;
}

td.actions .mp-button.cancel:hover {
  color: #737a87;
}
