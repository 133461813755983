.user-menu {
  position: absolute;
  display: block;
  right: 2em;
  top: 5.7em;
  background-color: #FFF;
  width: 275px;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  font-weight: 300;
  z-index: 100;
  cursor: default;
}

.user-menu .full-name-container {
  padding: 1.4em 1.7em 0.8em 1.7em;
  border-bottom: 1px solid #DBE2E9;
}

.user-menu .full-name-container .logged-in-as {
  font-size: 0.85em;
  letter-spacing: 0.03em;
}

.user-menu .full-name-container .full-name {
  font-size: 1.6em;
}

.user-menu .user-menu-options li {
  padding: 0.8em 1.7em 0.8em 1.7em;
  color: #4698CB;
  cursor: pointer;
}

.user-menu .user-menu-options li:not(last-child) {
  border-bottom: 1px solid #DBE2E9;
}

.user-menu li:hover .link {
  text-decoration: underline;
}

.user-menu li:before {
  font-family: "FontAwesome", monospace;
  margin-right: 0.6em;
}

.user-menu li.logout:before {
  content: "\f08b"
}

.user-menu li.settings:before {
  content: "\f013";
}

.user-menu .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  right: 1.6em;
  transform: rotate(-135deg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}
