.monitoring-tooltip {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  border-radius: 5px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.monitoring-tooltip.wide {
  width: 500px;
}

.monitoring-tooltip header {
  background-color: rgba(230, 236, 247, 0.4);
  padding: 0.2em 0.4em;
  flex: 1;
}

.monitoring-tooltip .primary-container, .monitoring-tooltip .secondary-container {
  display: flex;
  flex-direction: row;
  padding: 0.4em 1em;
}

.monitoring-tooltip .column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.monitoring-tooltip .metric {
  display: flex;
  flex-direction: row;
}


.monitoring-tooltip .metric .color-container {
  display: flex;
  align-items: center;
}

.monitoring-tooltip .metric .color {
  width: 15px;
  margin-right: 5px;
  border-width: 1px;
  border-style: solid;
}

.monitoring-tooltip .metric .value {
  margin-left: 5px;
  font-family: monospace;
}

.monitoring-tooltip .metric .direction {
  font-variant: small-caps;
  margin-left: 5px;
}

.monitoring-tooltip .secondary-container .metric .color {
  border-style: dashed;
}