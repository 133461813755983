.monitoring {
  display: flex;
  flex: 1;
}

.monitoring .graph-container {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.monitoring .stats-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1.2em;
  box-sizing: border-box;
  max-width: 26em;
}

.monitoring .graph-container .graph {
  flex: 1;
  min-height: 25em;
  position: relative;
}

.monitoring .monitoring-graph-no-data {
  background-color: #c7e6ff;
  border-radius: 5px;
  text-align: center;
  z-index: 100;
}

.monitoring .monitoring-graph-notification-container {
  border-radius: 5px;
  margin-left: 30px;
  position: absolute;
  width: calc(100% - 76px);
  top: -12px;
}
