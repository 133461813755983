.analysis-exports-heading {
  line-height: 3.5em;
  box-sizing: border-box;
  padding: 0 8px;
  border-right: 3px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.analysis-exports-heading .expand-icon {
  float: right;
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5em;
  color: #DEE4F0;
}

.analysis-exports-heading:hover {
  cursor: pointer;
}

.analysis-exports-heading .date {
  flex: 1
}

.analysis-exports-heading .export-type-customer {
  flex: 1
}

.analysis-exports-heading .export-type-operational {
  flex: 1
}

.analysis-exports-heading .summary-blurb {
  flex: 5;
}

.analysis-exports-heading .status {
  width: 6em;
}

.analysis-exports-heading .powerstation-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 2;
  margin-right: 0.35em;
}

.export-item-expanded .analysis-exports-heading .powerstation-name {
  white-space: normal;
  line-height: 2em;
}

.export-item-expanded .analysis-exports-heading .metrics {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 2em;
  flex: 3;
}

.analysis-exports-heading .metrics {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 3;
}

.export-item-expanded .analysis-exports-heading {
  background-color: #f7f9fd;
  border-right: 3px solid #45a9d3;
}

/*
Expanded headings should show a double-up icon, instead of the double-down
 */
.export-item-expanded .expand-icon i.fa:before {
  content: "\f102";
}
