.rp-widget.textarea {
  display: flex;
  align-items: flex-end;
}

.rp-widget.textarea .tooltip-container {
  top: -0.6em;
  left: -0.5em;
}

.rp-widget.textarea textarea {
  background-color: #FFF;
  width: 100%;
  border: 1px solid #DBE2E9;
  border-radius: 3px;
  color: #53565A;
  padding: 0.7em;
  box-sizing: border-box;
  font-size: 0.79rem;
  transition: border-color 0.3s ease;
}

.rp-widget.textarea.error textarea {
  border-color: #E0004D;
}

.rp-widget.textarea textarea:focus {
  outline: none;
  border-color: #4698cb;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
