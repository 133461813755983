.rp-widget.checkbox {
  width: 35px;
  height: 35px;
  position: relative;
}

.rp-widget.checkbox input {
  width: initial;
  visibility: hidden;
  position: absolute;
}

.rp-widget.checkbox .control {
  display: inline-block;
  background-color: #fff;
  height: 21px;
  width: 21px;
  margin-top: 7px;
  margin-left: 7px;
  border: 1px solid #DBE2E9;
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  position: relative;
  padding: 0 !important;
  box-sizing: border-box;
  font-size: 1rem;
}

.rp-widget.checkbox .control:hover {
  border-color: #4698cb;
}

.rp-widget.checkbox .control:hover::before {
  content: '\f00d';
  color: #dee4f0;
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  position: absolute;
  top: -0.2em;
  left: 0.1em;
}

.rp-widget.checkbox input:checked + .control::before {
  content: '\f00d';
  color: #4698cb;
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  position: absolute;
  top: -0.2em;
  left: 0.1em;
}

@media screen and (max-height: 930px) {
  .rp-widget.checkbox .control:hover::before {
    top: -0.2em;
  }

  .rp-widget.checkbox input:checked + .control::before {
    top: -0.2em;
  }
}

.rp-widget.checkbox.error label.control {
  border-color: #E0004D;
}

.rp-widget.checkbox.ro .checked:before,
.rp-widget.checkbox.ro .not-checked:before {
  content: '\f00d';
  color: #dee4f0;
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  top: -0.24em;
  left: calc(50% - 12px);
  width: 100%;
  display: block;
}

.rp-widget.checkbox.ro .checked:before {
  color: #4698cb;
}
