.loading-container {
  position: relative;
  height: 4em;
}

.login-message {
  margin-top: 1em;
  font-weight: 600;
  color: red;
}
