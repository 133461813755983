.kit-design-checklist {
  margin-top: 1em;
}

.kit-design-checklist sup {
  vertical-align: super;
  font-size: smaller;
}

.kit-design-checklist p {
  margin: 1em;
}

.kit-design-checklist ul {
  margin-left: 3em;
  margin-bottom: 1.5em;
  list-style: outside;
}

.kit-design-checklist li {
  margin-top: 1ex;
}
