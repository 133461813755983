.progress-bar {
    background-color: #DBE2E9;
    border-radius: 12px; /* (height of inner div) / 2 + padding */
    padding: 1px;
}

.progress-bar > div {
    background-color: #26D07C;
    width: 0%; /* Adjust with JavaScript */
    height: 8px;
    border-radius: 10px;
}

.progress-text .final {
    font-size: 1.5rem;
    display: inline;
}

.progress-text .progress {
    font-size: 2.0rem;
    display: inline;
}