.fleet-deployment-tabbar {
  margin-bottom: 0;
}

.connectivity-container .status::before {
  content: '\f111';
  font-family: "FontAwesome", monospace;
  margin-right: 0.3em;
}

.connectivity-container .status.online::before {
  color: #26d07c;
}

.connectivity-container .status.offline::before {
  color: #e0004d;
}

.connectivity-container .timestamp {
  font-size: 0.8em;
}