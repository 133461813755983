.analysis-exports-container {
  padding-top: 1em;
  box-sizing: border-box;
}

.analysis-exports-header {
  display: flex;
  padding-bottom: 1em;
  color: #4698CB
}


.analysis-exports-header .date {
  flex: 1;
  margin-right: 3em;
}

.analysis-exports-header .status {
  width: 6em;
}

.analysis-exports-header .type {
  flex: 3;
  margin-right: 2em;
}

.analysis-exports-header .details {
  flex: 3;
  margin-right: 6em;
}

.export-item {
  background-color: #fff;
  margin-bottom: 8px;
}


.export-item:hover {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.react-sanfona-item-body-wrapper {
  padding: 2em;
}

.export-item .label {
  width: 12rem;
}

.export-item.export-item-expanded {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.export-item .link-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
