.new-curtailment-confirmation .new-curtailment-info > div {
  margin: 0;
}

.new-curtailment-confirmation .new-curtailment-info .ro {
  font-weight: 500;
}

.new-curtailment-confirmation .labelled-content .label {
  font-weight: normal;
}

.new-curtailment-confirmation .confirm-message {
  padding: 3rem 2.45rem 3rem 2.45rem;
  font-size: 1.2em;
  text-align: center;
}
