.rp-widget.toggle {
  position: relative;
  display: flex;
  align-items: center;
}

.rp-widget .loading-icon {
  margin-left: 5px;
}

.rp-widget .toggle-after-text {
  margin-left: 10px;
}

.rp-widget .not-loading {
  padding-right: 20px;
}

.rp-widget .toggle-before-text {
  margin-right: 10px;
}

.rp-widget .toggle-after-text,
.rp-widget .toggle-before-text {
  opacity: 0.8;
}

.rp-widget.toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.rp-widget.toggle .switch {
  width: 50px;
  height: 30px;
  border-radius: 15px;
  background-color: #53565A;
  box-shadow: inset 0 1px 4px 0 #53565A;
  cursor: pointer;
}

.rp-widget.toggle.small .switch {
  width: 35px;
  height: 20px;
  border-radius: 10px;
}

.rp-widget.toggle.ro .switch {
  opacity: 0.35;
  cursor: not-allowed;
}

.rp-widget.toggle .switch:after {
  content: ' ';
  background-color: #fff;
  display: inline-block;
  width: 29px;
  height: 29px;
  border-radius: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #C5CBD1;
  margin-top: -1px;
  margin-left: -5px;
  transition: transform 0.2s ease-in-out;
}

.rp-widget.toggle.small .switch:after {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.rp-widget.toggle input[type="checkbox"]:checked~label .switch {
  background-color: #4698cb;
}

.rp-widget.toggle input[type="checkbox"]:checked~label .switch:after {
  transform: translate3d(30px, 0, 0);
}

.rp-widget.toggle.small input[type="checkbox"]:checked~label .switch:after {
  transform: translate3d(22px, 0, 0);
}
