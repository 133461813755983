.monitoring-time-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  margin-bottom: 1em;
  align-items: center;
}

.monitoring-time-controls .mp-button.link-inverted {
  margin-right: 2em;
}

.monitoring-time-controls .mp-button.link-inverted.active {
  color: #4698CB;
}

.monitoring-time-controls .data-download-container {

}

.monitoring-time-controls .date-selector-container {
  display: flex;
  align-items: center;
  height: 10px;
  justify-content: space-between;
}

.monitoring-time-controls .date-selector-container h3 {
  text-align: center;
}

.monitoring-time-controls .date-selector-container h3.day {
  min-width: 12.5em;
}

.monitoring-time-controls .date-selector-container h3.week {
  min-width: 17em;
}

.monitoring-time-controls .date-selector-container h3.month {
  min-width: 8.5em;
}

.monitoring-time-controls .date-selector-container h3.year {
  min-width: 2.5em;
}

.monitoring-time-controls .goto-today .fa {
  font-size: 1.3em;
  margin-top: 0.3em;
}