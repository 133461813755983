.content-container.with-sidebar {
  width: calc(100% - 300px);
  padding: 2.4em 2.5em;
  padding-top: 50px;
  box-sizing: border-box;
  margin-left: 300px;
  min-height: 100vh;
}

/* This is a hack to use MS/IE11-only selectors so that the style will only be applied in IE11. */
@media all and (-ms-high-contrast:none) {
  .content-container.with-sidebar {
    padding-top: 50px;
    box-sizing: border-box;
  }

  /* IE11 */
}
