.fleet-deployments-table-status-wrapper .status-label {
  display: flex;
  align-items: center;
}

.fleet-deployments-table-status-wrapper .circle {
  height: 17px;
  width: 17px;
  border-radius: 8px;
  font-family: "FontAwesome", monospace;
}

.fleet-deployments-table-status-wrapper .circle::before {
  display: block;
  height: 17px;
  width: 17px;
  line-height: 19px;
  text-align: center;
  font-size: 0.85em;
  color: #fff;
}

.fleet-deployments-table-status-wrapper .circle.offline {
  background-color: #E0004D;
}

.fleet-deployments-table-status-wrapper .circle.offline::before {
  content: '\f12a';
}

.fleet-deployments-table-status-wrapper .circle.awaiting-contact {
  background-color: #92969B;
}

.fleet-deployments-table-status-wrapper .circle.awaiting-contact::before {
  content: '\f141';
}

.fleet-deployments-table-status-wrapper .circle.online {
  background-color: #26D07C;
}

.fleet-deployments-table-status-wrapper .circle.online::before {
  content: '\f00c';
}

.fleet-deployments-table-status-wrapper .title {
  letter-spacing: 0.03em;
  margin-left: 0.4em;
}

.fleet-deployments-table-status-wrapper .last-contact {
  font-size: 0.8em;
  margin-left: calc(19px + 0.4em);
}