.customer-app-message-edit-container {
  padding: 0 2.45rem 1rem 2.45rem;
  display: flex;
  justify-content: space-between;
}

.customer-app-message-edit-container p:not(:first-child) {
  margin: 1em 0;
}

.customer-app-message-edit-container .rp-widget.textarea textarea {
  min-height: 12rem;
  font-weight: 300;
}

.customer-app-message-edit-container .message-container {
  width: 60%;
}

.customer-app-message-edit-container .preview-container {
  width: 36%;
}

.customer-app-message-edit-container .history-container {
  font-size: 0.9em;
  text-align: right;
  width: 100%;
  color: #4698cb;
  font-weight: 300;
}

.customer-app-message-edit-container .history-container .history-icon {
  margin-right: 0.5em;
}

.customer-app-message-edit-container .rp-widget.select-dd {
  text-align: left;
  padding: 0;
}

.customer-app-message-edit-container .rp-widget.select-dd .current-value {
  border-bottom: none;
}

.customer-app-message-edit-container .rp-widget.select-dd .options-container {
  width: 20em;
  font-size: 1.2em;
  color: #53565A;
}

.customer-app-message-edit-container .rp-widget.select-dd .options-container .option {
  white-space: normal;
}

