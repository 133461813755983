.unconfigured {
    height: 100%;
    width: 100%;
    text-align: center;
    margin-top: 7em;
    font-size: 1.3em;
    font-weight: 300;
    letter-spacing: 0.04em;
    color: #1f2a40;
}
