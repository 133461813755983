.widget-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100%;
  width: 100%;
}

.widget-content.fullscreen {
  border-radius: 0;
}

.widget-content:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.widget-content header {
  width: calc(100% - 2.8em);
  height: 2.8em;
  margin: 0 1.4em;
  border-bottom: 1px solid rgba(70, 152, 203, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.widget-content header h3.not-configured {
  color: #1f2a40;
}

.widget-content.fullscreen header {
  height: 8em;
}

.widget-content header .title-container {
  width: 100%;
}

.widget-content header .title-container h3 {
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.widget-content-wrapper {
  width: 100%;
  height: calc(100% - 2.8em);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.widget-content header .widget-controls {
  width: 2.1em;
  cursor: pointer;
  display: flex;
  font-size: 0.9em;
}

.widget-content.fullscreen header .widget-controls {
  width: 15rem;
  font-size: 2em;
}

.widget-content.fullscreen header .widget-controls.right > span {
  margin-left: 0.6em;
}
.widget-content header .widget-controls span:hover {
  opacity: 0.5;
}

.widget-content header .widget-controls.right {
  justify-content: flex-end;
}

.widget-content header .widget-controls span:first-child {
  margin-right: 0.4em;
}

.widget-content header .title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.widget-content header .pages-container {
  margin-top: -0.5em;
}

.widget-content header .pages-container > span {
  margin: 0 0.2em;
  font-size: 0.6em;
  cursor: pointer;
}

.widget-content.fullscreen header .title-container {
  font-size: 1.8em;
}

.widget-drag-handle {
  cursor: move;
}

.widget-drag-handle.not-configured {
  color: #1f2a40;
}

