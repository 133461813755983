table.powerstation-table td {
  max-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table.powerstation-table td p {
  text-overflow: ellipsis;
  overflow: hidden;
}

table .powerstation-table-column-long {
  width: 30%;
}
