.rp-widget.time-picker {
  position: relative;
  display: inline-block;
  padding: 0 0.4em;
  box-sizing: border-box;
}

.rp-widget.time-picker:focus {
  outline: none;
}

.rp-widget.time-picker .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.rp-widget.time-picker.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: inherit;
}

.rp-widget.time-picker .current-value:after {
  content: '\f107';
  font-family: "FontAwesome", monospace;
  font-weight: 100;
  margin-left: 0.45em;
}

.rp-widget.time-picker.ro .current-value:after {
  content: '';
  margin-left: 0;
}

.rp-widget.time-picker .current-value:hover {
  color: #1F2A44;
}

.rp-widget.time-picker .current-value .fa {
  margin-left: 0.45em;
}

.rp-widget.time-picker .time-picker-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9em;
}

.rp-widget.time-picker .time-picker-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 1em;
  transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.rp-widget.time-picker .time-components-container {
  padding: 1rem 2rem;
}

.rp-widget.time-picker .time-components-container .component {
  text-align: center;
  width: 2.4em;
}

.rp-widget.time-picker .time-components-container .component.controls-container {
  width: 100%;
  padding: 0 0.4em;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 1.4em;
  color: #4698cb;
}

.rp-widget.time-picker .time-components-container .component.controls-container .fa {
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.rp-widget.time-picker .time-components-container .component.controls-container .fa:hover {
  color: #1F2A44;
}

.rp-widget.time-picker .time-components-container .current-value-edit {
  font-size: 2em;
  font-weight: 300;
}

.rp-widget.time-picker .current-value .offset {
  font-size: 0.8em;
  margin-left: 0.5em;
}

.rp-widget.time-picker .time-components-container .offset {
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
  width: 100%;
  margin-top: 1em;
}

.rp-widget.time-picker .time-components-container .now {
  text-align: center;
  width: 100%;
  font-size: 1.2em;
  margin-top: 1em;
}

