.single-stat-widget header {
  position: absolute;
  color: #fff;
  font-weight: 300;
}

.single-stat-widget.commissioned header {
  border-bottom: 1px solid #4698CB;
}

.single-stat.commissioned {
  background: linear-gradient(to bottom right, #347299, #3191CC);
}

.single-stat-widget.offline header {
  border-bottom: 1px solid rgba(70, 152, 203, 0.2);
}

.single-stat.offline {
  background: linear-gradient(to bottom right, #E0004D, #AD003D);
}

.single-stat-widget.not-commissioned header {
  border-bottom: 1px solid rgba(70, 152, 203, 0.2);
}

.single-stat.not-commissioned {
  background: linear-gradient(to bottom right, #F58D25, #DB7E21);
}

.single-stat.single-stat-unconfigured {
  background: #fff;
  color: #1f2a40;
}

.single-stat-widget .widget-content-wrapper {
  height: 100%;
}

.single-stat-widget .react-resizable-handle {
  color: #fff;
}

.single-stat {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widget-content.fullscreen .single-stat {
  border-radius: 0;
  font-size: 3em;
}

.single-stat .counter {
  font-size: 10em;
  font-weight: 100;
  letter-spacing: -0.05em;
  margin-top: -0.2em;
}

.single-stat .description {
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: 0.05em;
  margin-top: -3em;
}

.widget-content.fullscreen .counter {
  font-weight: 300;
}

.widget-content.fullscreen .description {
  font-weight: 400;
}

.single-stat .explore-link {
  position: absolute;
  bottom: 0.6em;
  font-size: 0.8em;
}
