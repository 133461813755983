.tabbed-container {
  width: 100%;
  border: 1px solid #DBE2E9;
  margin-top: 1.5em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.tabbed-container .tabbed-container-tabs {
  display: flex;
  border-bottom: 1px solid #DBE2E9;
  min-height: 50px;
}

.tabbed-container .tabbed-container-tab {
  border-bottom: 3px solid transparent;
  border-right: 1px solid #DBE2E9;
  padding: 1em 2em;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease-out;
}

.tabbed-container .tabbed-container-tab:hover {
  border-bottom: 3px solid rgba(70, 152, 203, 0.4);
  color: #4698cb;
}

.tabbed-container .tabbed-container-tab.active {
  color: #4698cb;
  border-bottom: 3px solid #4698cb;
}

.tabbed-container .tabbed-container-tab.active {
  color: #4698cb;
  border-bottom: 3px solid #4698cb;
}

.tabbed-container .tabbed-container-content {
  flex-grow: 1;
  position: relative;
  height: 100%;
}

.tabbed-container .tabbed-container-content .public_fixedDataTable_header,
.tabbed-container .tabbed-container-content .public_fixedDataTable_header .public_fixedDataTableCell_main,
.tabbed-container .tabbed-container-content .fixedDataTableLayout_header.public_fixedDataTableRow_main:hover,
.tabbed-container .tabbed-container-content .fixedDataTableLayout_header.public_fixedDataTableRow_main:hover,
.tabbed-container .tabbed-container-content .fixedDataTableLayout_header .fixedDataTableCellGroupLayout_cellGroup:hover .fixedDataTableCellLayout_wrap1,
.tabbed-container .tabbed-container-content .public_fixedDataTable_header .fixedDataTableCellGroupLayout_cellGroup:hover .fixedDataTableCellLayout_wrap1 {
  background-color: #fff;
}
