.dashboard-widget-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-widget-settings-container footer {
  border-top: 1px solid #dee4f0;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: right;
  padding: 1em;
  width: 100%;
}

.dashboard-widget-settings-container .setting {
  width: 20rem;
}

.dashboard-widget-settings-container .setting .display-name {
  font-weight: 400;
  color: #4698cb;
  padding: 0.3em;
}
.dashboard-widget-settings-container .checkbox-label {
  display: flex;
  align-items: center;
}
