.tooltip-container {
  position: relative;
}

.tooltip-container .info-icon {
  padding-left: 0.5em;
  font-size: 0.9rem !important;
}

.tooltip-container.debug-error,
.tooltip-container.debug-error .info-icon .fa-info-circle {
  color: red;
}

.tooltip-container .info-icon .fa-info-circle {
  color: #4698CB;
}

.tooltip-body {
  position: absolute;
  margin-top: 2.2em;
  margin-left: -0.5em;
  z-index: 999999;
  pointer-events: all;
}

.tooltip-content {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 3px;
  line-height: initial;
  padding: 1em;
  pointer-events: all;
  position: absolute;
}

.tooltip-body .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 0.7em;
  transform: rotate(-135deg);
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
  z-index: 999;
}

.tooltip-body h1 {
  font-size: 1.1em;
  padding-bottom: 1em;
  font-weight: 300;
  border-bottom: 1px solid rgba(31, 42, 68, 0.2);
  margin-bottom: 1em;
}

.tooltip-body .tooltip-content-wrapper h1,
.tooltip-body h2 {
  color: #4698CB;
  font-weight: 300;
  margin-top: 1em;
  font-size: 0.9em;
  border-bottom: none;
  padding-bottom: 0;
}

.tooltip-body p {
  margin: 0.5em 0;
  font-size: 0.9em;
}
