.powerstation-deployments-list-selector-container {
  background-color: #FFF;
  width: 100%;
  padding: 0.4em 0 0.2em 0.8em;
  border-top: 1px solid #DBE2E9;
  border-bottom: 1px solid #DBE2E9;
  box-sizing: border-box;
  font-size: 12px;
  margin-top: 3px;
}

.powerstation-deployments-list-selector {
  display: flex;
}
