.voltage-heat-map-widget {
  position: relative;
}

.voltage-heat-map-widget .key-container {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 10px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 0.5em;
}

.widget-content.fullscreen .voltage-heat-map-widget .key-container {
  box-sizing: border-box;
  bottom: 75px;
  font-size: 2em;
}

.voltage-heat-map-widget .key-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4em 0 0.8em 0;
  font-size: 0.9em;
}

.voltage-heat-map-widget .key-container .key-block {
  height: 1em;
  width: 6em;
  border: 1px solid;
  margin-top: 0.2em;
}

.voltage-heat-map-widget .key-container .key-block.under {
  border-color: rgb(161, 207, 206);
  background-color: rgba(161, 207, 206, 0.7);
}

.voltage-heat-map-widget .key-container .key-block.ok {
  border-color: rgb(38, 208, 124);
  background-color: rgba(38, 208, 124, 0.7);
}

.voltage-heat-map-widget .key-container .key-block.over {
  border-color: rgb(235, 175, 115);
  background-color: rgba(235, 175, 115, 0.7);
}
