.rp-widget.input input {
  border: 1px solid #DBE2E9;
  height: 2.38rem;
  border-radius: 3px;
  padding: 0 0.5rem;
  -webkit-appearance: none;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 0.9em;
  box-sizing: border-box;

}

.rp-widget.input input:focus {
  border-color: #4698cb;
}

.rp-widget.input.error input {
  border-color: #E0004D;
}

.rp-widget.input.error .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.rp-widget.input.ro {
  color: #53565A;
  line-height: 45px;
  padding: 0 0.75rem;
  box-sizing: border-box;
  margin: 0;
}
