.customer-location {
  display: flex;
  width: 100%;
  background-color: #fff;
  margin: 1em 0;
}

.customer-location .location-info {
  padding: 1em 2em;
  flex: 2;
}

.customer-location .map-container {
  flex: 5;
  min-height: 315px;
  height: 100%;
}

.customer-location .location-info .labelled-content:not(:last-child) {
  margin-bottom: 2em;
}

.customer-location .location-info .labelled-content .label {
  margin-bottom: 0.4em;
}

.customer-location .location-info .labelled-content .value {
  font-size: 1.3em;
  font-weight: 300;
}

.customer-location .location-info .labelled-content .value .labelled-content {
  font-size: 0.7em;
  margin: 0;
}

.customer-location .location-info .labelled-content .value .labelled-content .value {
  font-size: 1.1em;
}
