.grid-outage-container {
  display: flex;
}

.grid-outage-overview {
  position: relative;
  display: flex;
  flex: 3;
  flex-direction: column;
}

.grid-outage-overview .graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
}

.grid-outage-overview .node-count-container {
  width: 100%;
  margin-top: 1.6em;
  flex: 3;
}

.grid-outage-overview .details-container {
  flex: 2;
}

.grid-outage-overview .capacity {
  margin-bottom: 2rem;
}

.grid-outage-overview .capacity .value {
  font-size: 34px;
  font-weight: 300;
}

.grid-outage-overview .capacity .label {
  font-size: 16px;
  margin-top: -0.55rem;
}

.grid-outage-overview .capacity.accepted {
  padding: 2rem 0;
  border-bottom: 1px solid #DBE2E9;
}

.grid-outage-overview .capacity.accepted .label {
  color: #4698cb;
}

.grid-outage-overview .capacity.battery-power .label {
  color: rgb(38, 208, 124);
}

.grid-outage-overview .capacity.meter-power .label {
  color: rgb(170, 170, 170);
}

.grid-outage-overview strong.capacity {
  font-size: 34px;
  font-weight: 300;
  display: block;
  margin: -0.5rem 0;
}

.grid-outage-overview .reasons.rejected {
  margin-top: 0.5em;
}

.grid-outage-overview .reasons.rejected strong {
  font-weight: 500;
}

.grid-outage-overview .nodes-responded-container {
  padding: 5px;
  align-items: center;
  display: flex;
}

.grid-outage-overview-text {
  padding-top: 2em;
}

.opted-out-container {
  flex: 2;
  padding: 1rem;
  border-bottom: 1px solid #dbe2e9;
}

.opted-out-container .cancelled-node {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}