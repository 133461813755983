.dashboard-ticker {
  background-color: #1F2A44;
  width: 100%;
  height: 50px;
  position: relative;
}

.dashboard-ticker ul {
  white-space: nowrap;
  height: 100%;
  position: absolute;
  font-size: 1.6em;
  color: #fff;
  text-transform: uppercase;
}

.dashboard-ticker .end-cap {
  width: 80px;
  height: 100%;
}

.dashboard-ticker .end-cap.left {
  position: absolute;
  left: 0;
  background: linear-gradient(to right, #1F2A44, #1F2A4400);
}

.dashboard-ticker .end-cap.right {
  position: absolute;
  right: 0;
  background: linear-gradient(to right, #1F2A4400, #1F2A44);
}

.dashboard-ticker ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0 1em;
  box-sizing: border-box;
}

.dashboard-ticker ul li:not(:last-child) {
  border-right: 1px solid #141B2B;
}

.dashboard-ticker .ticker-content {
  display: flex;
  align-items: center;
}

.dashboard-ticker .ticker-content img {
  margin-right: 0.3em;
  line-height: 2em;
}

.dashboard-ticker .dispatch-ticker .powerstation-name,
.dashboard-ticker .dispatch-ticker .power {
  color: #1699D0;
}

.dashboard-ticker .weather-ticker .temp {
  color: #F68D26;
}

.dashboard-ticker .weather-ticker .cloud {
  color: #1699D0;
}

.dashboard-ticker .installer-ticker .install {
  color: #00D573;
}

.dashboard-ticker .customer-ticker .customer {
  color: #00D573;
}