.choose-export-modal {
    display: flex;
    flex-flow: column;
    padding: 2em;
}

.choose-export-modal .choose-export-tile {
    padding: 1em;
    text-align: center;
    width: 100%;
    border: 1px solid #dee4f0;
    font-weight: 400;
    box-sizing: border-box;
    height: 8em;
    margin-bottom: 1em;
}

.choose-export-modal .choose-export-tile:hover {
    cursor: pointer;
    background-color: #4698CB;
    color: #fff;
}

.choose-export-modal .choose-export-tile h3 {
    font-size: 1.4em;
    margin-top: 0.4em;
}

.choose-export-modal .choose-export-tile .description {
    font-weight: 400;
}