.rp-widget.infobox {
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0.6em 1em;
  margin-bottom: 0.5em;
}

.rp-widget.infobox .title {
  font-weight: 500;
}

.rp-widget.infobox.info {
  background-color: rgba(70, 152, 203, 0.1);
  border-color: rgba(70, 152, 203, 0.3);
}

.rp-widget.infobox.warning {
  background-color: rgba(224, 0, 77, 0.1);
  border-color: rgba(224, 0, 77, 0.3);
}

.rp-widget.infobox.success {
  background-color: rgba(0, 224, 92, 0.1);
  border-color: rgba(0, 224, 149, 0.3);
}

.rp-widget.infobox.error {
  background-color: rgba(255, 102, 85, 0.1);
  border-color: rgba(255, 102, 85, 0.3);
}
