.system-components .row {
    padding: 20px 10px 40px 10px;
}

.system-components .full-width-data-summary > span:not(:first-child) .full-width-row {
    padding-top: 15px;
}

.system-components .full-width-data-summary > span:not(:last-child) .full-width-row {
    padding-bottom: 15px;
}

.system-components .row:not(:last-child),
.full-width-data-summary .full-width-row:not(:last-child) {
    border-bottom: 1px solid #D9E1E8;
}

.system-components .row.thick-top-border {
    border-top: 2px solid #D9E1E8;
}

.system-components .full-width-data-summary > span:not(:first-child) .thin-top-border {
    border-top: 1px solid #D9E1E8;
}

.system-components .row.energy-system-header {
    padding: 0 10px 25px 10px;
}

.system-components .row.energy-system-header h2 {
    margin-top: 20px;
    padding-left: 4.5em;
}

.system-components .row .cell {
    flex: 1;
    max-width: 30em;
}

.system-components .row .cell.no-width {
    max-width: initial;
}

.system-components .row .cell.center {
    align-items: center;
    /*justify-content: center;*/
    display: flex;
}

.system-components .cell.cell-header {
    max-width: 15em;
    font-size: 1.25em;
    margin-top: -0.25em
}

.system-components .cell h2 {
    font-size: 1em;
    line-height: initial;
    display: inline-block;
    font-weight: 400;
}

.system-components .cell h3 {
    font-size: 1.1em;
    margin-bottom: 15px;
    line-height: initial;
    display: inline-block;
    font-weight: 300;
}

.data-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
}

.data-list div {
    flex: 55%;
    margin-bottom: 3px;
}

.data-list .data-title {
    font-weight: bold;
    flex: 40%;
}

.system-components {
    display: flex;
    flex-direction: column;
}

.system-components .row {
    display: flex;
    flex-direction: row;
}

.systme-components .reposit-summary {
    display: flex;
    flex-direction: column;
}

.data-summary .data-item {
    display: flex;
}

.data-summary .data-item .heading {
    flex: 1;
    max-width: 10em;
    font-weight: bold;
}

.data-summary .data-item .content {
    flex: 1;
}

.fill-width-data-summary {
    display: flex;
    flex-direction: column;
}

.full-width-data-summary .full-width-row {
    display: flex;
}

.full-width-data-summary .full-width-row .full-width-heading {
    flex: 1;
    max-width: 15em;
}

.full-width-data-summary .full-width-row .full-width-content {
    flex: 1;
}

.system-components .web-portal:after {
    content: "\f08e";
    font-family: "FontAwesome", monospace;
    margin-left: 0.5em;
    color: #4698CB;
    top: 1px;
    position: relative;
    transition: all 0.3s ease-out;
}

.system-components .web-portal:hover:after {
    text-decoration: none;
    color: #1F2A44;
    cursor: pointer;
}

.system-components .energy-system-image-container {
    display: flex;
    align-items: center;
}

.system-components .energy-system-image {
    max-height: 100px;
    max-width: 100px;
    padding: 0 8px;
}

.system-components .solar-panel-image {
    max-height: 62px;
}