.page .page-content .tariff {
  margin-top: 1rem;
  height: initial;
}

.tariff .tariff-details-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1em;
  flex-wrap: wrap;
}

.tariff .tariff-periods,
.tariff .tariff-rates {
  margin-top: 2em;
}

/*.tariff .tariff-periods .rp-widget.input.ro,*/
/*.tariff .tariff-rates .rp-widget.input.ro {*/
  /*line-height: initial;*/
/*}*/


.tariff.ro .tariff-periods,
.tariff.ro .tariff-rates {
  margin-top: 1em;
}

@media screen and (max-width: 800px) {
  .tariff .tariff-details label {
    display: block;
    width: 100%;
    margin-right: 0 !important;
  }
}

.tariff .tariff-details label {
  display: inline-block;
  font-weight: 400;
  color: #4698cb;
}

.tariff .tariff-details label {
  margin-right: 1em;
}

.tariff .tariff-details label .label-text {
  padding-left: 0.5em;
}

.tariff .tariff-details label .widget {
  margin-top: 0.2em;
}

.tariff .tariff-details label.plan-name .widget {
  min-width: 400px;
}

.tariff .tariff-details label.plan-name .widget input {
  width: 100%;
}

.tariff .tariff-details label.plan-code .widget {
  min-width: 14.3em;
}

.tariff .tariff-details label.plan-code .widget input {
  width: 100%;

}

.tariff .tariff-details label.tariff-type .widget {
  min-width: 200px;
}

.tariff .tariff-details label.dollar-input .widget {
  min-width: 105px;
  position: relative;
}

.tariff .tariff-details label.dollar-input .widget::before {
  content: '$';
  color: #A8AFBD;
  position: absolute;
  height: 100%;
  line-height: 2.4em;
  left: 0.7em;
  font-size: 1.1em;
}

.tariff .tariff-details label.dollar-input.ro .widget::before {
  line-height: 3.1em;
}

.tariff .tariff-details label.dollar-input .widget input {
  padding: 0 0.2em 0 1.6em;
}

.tariff .tariff-details label.dollar-input .rp-widget.input.ro {
  padding-left: 1.6em;
}

.tariff .tariff-details label.reset-period .widget {
  min-width: 140px;
}

.tariff .tariff-details label.operational-network .widget {
  min-width: 240px;
}

.tariff .tariff-details label.description .widget {
  min-width: 400px;
}

.tariff .tariff-details label.description .widget input {
  width: 100%;
}

.tariff input.tariff-name {
  max-width: 400px;
}

.tariff .help-text {
  padding: 0 0 0.5em 0.5em;
  box-sizing: border-box;
}

.tariff .non-field-errors {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.tariff .operational-networks-loading {
  color: #363636;
  padding: 1em;
}
