.markdown-modal-container {
    padding: 0 2em;
}
.markdown-modal-container table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
}
.markdown-modal-container tr, .markdown-modal-container td {
    cursor: default;

    border: 1px solid rgba(70, 152, 203, 0.3) !important;
}

.markdown-modal-container > tr:hover, td:hover {
    box-shadow: none !important;
}