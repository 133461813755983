@import url(https://fonts.googleapis.com/css?family=Work+Sans:300,400,500);
.login-view {
  background-size: cover;
  background-color: #1F2A44;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
}

.login-panel {
  width: 580px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  padding: 5em;
  box-sizing: border-box;
}

.login-panel h1 {
  font-size: 1.7em;
  font-weight: 400;
  font-family: "Raleway", "Work Sans", sans-serif;
  margin: 1em 0;
}

.login-panel .message-container {
  margin: 1em 0;
}

.login-panel p,
.login-panel form {
  padding: 0 4em;
}

.login-panel form input {
  display: block;
  width: 100%;
}

.login-panel form input[type="text"]:first-child {
  margin-top: 2em;
}

.login-panel form input[type="text"],
.login-panel form input[type="password"] {
  background-color: #fff;
  color: #4698CB;
  border: none;
  font-size: 1em;
  text-align: center;
  line-height: 1.3em;
  border-bottom: 1px solid #DAE0EB;
  margin-top: 2em;
}

.login-panel form input[type="text"]::-webkit-input-placeholder, .login-panel form input[type="password"]::-webkit-input-placeholder {
  color: #4698CB;
}

.login-panel form input[type="text"]:-ms-input-placeholder, .login-panel form input[type="password"]:-ms-input-placeholder {
  color: #4698CB;
}

.login-panel form input[type="text"]::placeholder,
.login-panel form input[type="password"]::placeholder {
  color: #4698CB;
}

.login-panel input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #4698CB !important;
}

.login-panel .mp-button {
  margin: 2em 0;
}

.login-panel .link {
  font-size: 0.9em;
  text-decoration: none;
  color: #53565A;
  padding-bottom: 2px;
  border-bottom: 1px solid #53565A;
}

.user-menu {
  position: absolute;
  display: block;
  right: 2em;
  top: 5.7em;
  background-color: #FFF;
  width: 275px;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  font-weight: 300;
  z-index: 100;
  cursor: default;
}

.user-menu .full-name-container {
  padding: 1.4em 1.7em 0.8em 1.7em;
  border-bottom: 1px solid #DBE2E9;
}

.user-menu .full-name-container .logged-in-as {
  font-size: 0.85em;
  letter-spacing: 0.03em;
}

.user-menu .full-name-container .full-name {
  font-size: 1.6em;
}

.user-menu .user-menu-options li {
  padding: 0.8em 1.7em 0.8em 1.7em;
  color: #4698CB;
  cursor: pointer;
}

.user-menu .user-menu-options li:not(last-child) {
  border-bottom: 1px solid #DBE2E9;
}

.user-menu li:hover .link {
  text-decoration: underline;
}

.user-menu li:before {
  font-family: "FontAwesome", monospace;
  margin-right: 0.6em;
}

.user-menu li.logout:before {
  content: "\f08b"
}

.user-menu li.settings:before {
  content: "\f013";
}

.user-menu .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  right: 1.6em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.header {
  height: 50px;
  background-color: #53565A;
  display: block;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.header .header-content {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #53565A;
}

.header .notification-container {
  width: 300px;
  color: #FFFFFF;
  background-color: #37393D;
  height: 100%;
  font-weight: 300;
  line-height: 50px;
  display: flex;
  position: relative;
  border-right: 1px solid #292B2E;
  cursor: pointer;
}

.header .notification-container:hover {
  background-color: #53565A;
  text-decoration: none;
}

.header .notification-bell-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 4.5em;
  justify-content: center;
}

.header .notification-bell {
  height: 3.13rem;
  width: 1.19rem;
}

.header .notification-unread-alert {
  position: absolute;
  top: 0.85em;
  left: 2.3em
}

.header .user-container {
  display: flex;
  position: relative;
  align-items: center;
  border-left: 1px solid #37393D;
  padding: 0 2.5em;
  cursor: pointer;
}

.header .user-container:focus {
  outline: none;
}

.header .user-container .username {
  color: #DBE2E9;
  font-weight: 300;
  letter-spacing: 0.05em;
}

.header .user-container .username:after {
  content: "\f078";
  font-family: "FontAwesome", monospace;
  margin-left: 1em;
  top: -1px;
  position: relative;
}

.header .user-container:hover {
  background-color: #4B4E52;
}

.header .user-container:hover .username:after {
  color: #4698cb;
}

.tooltip-container {
  position: relative;
}

.tooltip-container .info-icon {
  padding-left: 0.5em;
  font-size: 0.9rem !important;
}

.tooltip-container.debug-error,
.tooltip-container.debug-error .info-icon .fa-info-circle {
  color: red;
}

.tooltip-container .info-icon .fa-info-circle {
  color: #4698CB;
}

.tooltip-body {
  position: absolute;
  margin-top: 2.2em;
  margin-left: -0.5em;
  z-index: 999999;
  pointer-events: all;
}

.tooltip-content {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 3px;
  line-height: normal;
  line-height: initial;
  padding: 1em;
  pointer-events: all;
  position: absolute;
}

.tooltip-body .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 0.7em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
  z-index: 999;
}

.tooltip-body h1 {
  font-size: 1.1em;
  padding-bottom: 1em;
  font-weight: 300;
  border-bottom: 1px solid rgba(31, 42, 68, 0.2);
  margin-bottom: 1em;
}

.tooltip-body .tooltip-content-wrapper h1,
.tooltip-body h2 {
  color: #4698CB;
  font-weight: 300;
  margin-top: 1em;
  font-size: 0.9em;
  border-bottom: none;
  padding-bottom: 0;
}

.tooltip-body p {
  margin: 0.5em 0;
  font-size: 0.9em;
}

.rp-widget.checkbox {
  width: 35px;
  height: 35px;
  position: relative;
}

.rp-widget.checkbox input {
  width: auto;
  width: initial;
  visibility: hidden;
  position: absolute;
}

.rp-widget.checkbox .control {
  display: inline-block;
  background-color: #fff;
  height: 21px;
  width: 21px;
  margin-top: 7px;
  margin-left: 7px;
  border: 1px solid #DBE2E9;
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  position: relative;
  padding: 0 !important;
  box-sizing: border-box;
  font-size: 1rem;
}

.rp-widget.checkbox .control:hover {
  border-color: #4698cb;
}

.rp-widget.checkbox .control:hover::before {
  content: '\f00d';
  color: #dee4f0;
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  position: absolute;
  top: -0.2em;
  left: 0.1em;
}

.rp-widget.checkbox input:checked + .control::before {
  content: '\f00d';
  color: #4698cb;
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  position: absolute;
  top: -0.2em;
  left: 0.1em;
}

@media screen and (max-height: 930px) {
  .rp-widget.checkbox .control:hover::before {
    top: -0.2em;
  }

  .rp-widget.checkbox input:checked + .control::before {
    top: -0.2em;
  }
}

.rp-widget.checkbox.error label.control {
  border-color: #E0004D;
}

.rp-widget.checkbox.ro .checked:before,
.rp-widget.checkbox.ro .not-checked:before {
  content: '\f00d';
  color: #dee4f0;
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  top: -0.24em;
  left: calc(50% - 12px);
  width: 100%;
  display: block;
}

.rp-widget.checkbox.ro .checked:before {
  color: #4698cb;
}

.mp-button {
  display: inline-block;
  line-height: 1em;
  height: 2.71em;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.5em 0.9em;
  font-size: 1em;
  font-weight: 300;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
  position: relative;
  background: none;
}

a.mp-button {
  height: auto;
  height: initial;
  line-height: 1.6em;
}

.mp-button:hover {
  text-decoration: none;
}

.mp-button:not(:first-child) {
  margin-left: 0.8em;
}

.mp-button.primary {
  background-color: #4698CB;
  color: #FFF;
}

.mp-button.primary:hover {
  background-color: #1F2A44;
}

.mp-button.secondary {
  border: 1px solid #DBE2E9;
  color: #4698CB;
  background-color: transparent;
  font-weight: 400;
}

.mp-button.secondary:hover {
  border: 1px solid #4698CB;
  color: #1F2A44;
}

.mp-button.danger {
  background-color: #CB4646;
  color: #FFF;
}

.mp-button.danger:hover {
  background-color: #441F1F;
}

.mp-button .fa {
  margin-right: 0.6em;
}

.mp-button:focus {
  outline: 0;
}

.mp-button.link,
.mp-button.link-inverted {
  font-weight: 400;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  height: auto;
  height: initial;
  line-height: inherit;
  border-radius: unset;
}

.mp-button.link-inverted {
  color: #53565A;
}

.mp-button.link-inverted:hover {
  color: #4698CB;
}

.mp-button:disabled {
  cursor: not-allowed !important;
  opacity: 0.8 !important;
}

.rp-widget.input input {
  border: 1px solid #DBE2E9;
  height: 2.38rem;
  border-radius: 3px;
  padding: 0 0.5rem;
  -webkit-appearance: none;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 0.9em;
  box-sizing: border-box;

}

.rp-widget.input input:focus {
  border-color: #4698cb;
}

.rp-widget.input.error input {
  border-color: #E0004D;
}

.rp-widget.input.error .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.rp-widget.input.ro {
  color: #53565A;
  line-height: 45px;
  padding: 0 0.75rem;
  box-sizing: border-box;
  margin: 0;
}

.rp-widget.textarea {
  display: flex;
  align-items: flex-end;
}

.rp-widget.textarea .tooltip-container {
  top: -0.6em;
  left: -0.5em;
}

.rp-widget.textarea textarea {
  background-color: #FFF;
  width: 100%;
  border: 1px solid #DBE2E9;
  border-radius: 3px;
  color: #53565A;
  padding: 0.7em;
  box-sizing: border-box;
  font-size: 0.79rem;
  transition: border-color 0.3s ease;
}

.rp-widget.textarea.error textarea {
  border-color: #E0004D;
}

.rp-widget.textarea textarea:focus {
  outline: none;
  border-color: #4698cb;
  box-shadow: none;
}

.rp-widget.select .Select-control {
  border: 1px solid #DBE2E9;
  height: 2.4rem;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
}

.rp-widget.select .Select-control .Select-arrow-zone {
  position: absolute;
  right: 1rem;
  width: 0;
}

.rp-widget.select .Select-control .Select-clear-zone {
  position: absolute;
  right: 1.4rem;
  margin-top: 1px;
}

.rp-widget.select .Select.is-focused,
.rp-widget.select .Select.is-pseudo-focused {
  outline: none;
}

.rp-widget.select .Select.is-open .Select-control {
  border-color: #4698cb;
  outline: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rp-widget.select .Select-menu-outer {
  border-right: 1px solid #4698cb;
  border-left: 1px solid #4698cb;
  border-bottom: 1px solid #4698cb;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}

.rp-widget.select .Select-menu-outer .is-selected {
  color: #4698cb;
  background-color: #F8F9FB;
}

.rp-widget.select .Select-menu-outer .is-focused {
  background-color: #EEF2FA;
}

.rp-widget.select.error .Select-control {
  border-color: #E0004D;
}

.rp-widget.select.error .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.rp-widget.select.ro {
  color: #53565A;
  line-height: 45px;
  padding: 0 0.75rem;
  box-sizing: border-box;
  margin: 0;
}

.rp-widget.select .Select-placeholder,
.rp-widget.select .Select-value-label {
  font-size: 0.9em;
  line-height: 3em;
  font-weight: 400;
  color: #53565A;
}

.rp-widget.select .Select--multi .Select-control {
  padding-right: 2.6em;
  height: 100%;
}

.rp-widget.select .Select--multi .Select-control .Select-value .Select-value-label {
  line-height: 0 !important;
}

.rp-widget.select .is-focused:not(.is-open)>.Select-control {
  box-shadow: none;
}

.rp-widget.select .Select--multi .Select-value {
  background-color: #4698CB22;
  border: 1px solid #4698CB;
}

.rp-widget.select .Select--multi .Select-value-icon {
  padding: 3px 5px 3px 5px;
}

.rp-widget.select-dd {
  position: relative;
  display: inline-block;
  padding: 0 0.4em;
  box-sizing: border-box;
}

.rp-widget.select-dd:focus {
  outline: none;
}

.rp-widget.select-dd .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.rp-widget.select-dd .current-value.error {
  border-color: #F68D26;
  color: #F68D26;
  transition: color 0.3s ease-out;
}

.rp-widget.select-dd .current-value:after {
  content: '\f107';
  font-family: "FontAwesome", monospace;
  font-weight: 100;
  margin-left: 0.45em;
}

.rp-widget.select-dd.ro .current-value:after {
  content: '';
  margin-left: 0;
}

.rp-widget.select-dd .current-value:hover {
  color: #1F2A44;
}

.rp-widget.select-dd.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: #000;
  color: initial;
}

.rp-widget.select-dd .options-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: pointer;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: normal;
  line-height: initial;
}

.rp-widget.select-dd .options-container.up {
  bottom: 3em;
}

.rp-widget.select-dd .options-scroll-container {
  max-height: 250px;
  overflow-y: auto;
  margin-top: 1em;
}

.rp-widget.select-dd .options-container.up .options-scroll-container {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1em;
  margin-top: unset;
}

.rp-widget.select-dd .options-container .option {
  white-space: nowrap;
  border-bottom: 1px solid #DBE2E9;
  box-sizing: border-box;
  padding: 0.8em 1em;
  font-size: 0.9em;
}

.rp-widget.select-dd .options-container.up .option:first-child {
  border-bottom: unset;
}

.rp-widget.select-dd .options-container:not(.up) .option:last-child {
  border-bottom: none;
}

.rp-widget.select-dd .options-container .option:hover {
  text-decoration: underline;
}

.rp-widget.select-dd .options-container .option.selected {
  color: #4698cb;
}

.rp-widget.select-dd .options-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 1em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.rp-widget.select-dd .options-container.up .tip {
  bottom: -10px;
  top: unset;
  box-shadow: -4px -6px 12px 0 rgba(0, 0, 0, 0.16);
}


.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 100;
}

.loading-inline {
  -webkit-transform: translate(50%);
          transform: translate(50%);
}

.loading-inline-vertical {
  -webkit-transform: translate(50%, 25%);
          transform: translate(50%, 25%);
}

.loading-horizontal {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #e4eaf7;
  background-clip: padding-box;
  overflow: hidden;
}

.loading-horizontal .indeterminate {
  background-color: #4698CB;
}

.loading-horizontal .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.loading-horizontal .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0%   { left: -35%; right: 100%; }
  60%  { left: 100%; right: -90%; }
  100% { left: 100%; right: -90%; }
}

@keyframes indeterminate {
  0%   { left: -35%; right: 100%; }
  60%  { left: 100%; right: -90%; }
  100% { left: 100%; right: -90%; }
}

@-webkit-keyframes indeterminate-short {
  0%   { left: -200%; right: 100%; }
  60%  { left: 107%;  right: -8%; }
  100% { left: 107%;  right: -8%; }
}

@keyframes indeterminate-short {
  0%   { left: -200%; right: 100%; }
  60%  { left: 107%;  right: -8%; }
  100% { left: 107%;  right: -8%; }}

.labelled-content .label {
  width: 6rem;
  display: inline-block;
  font-weight: 500;
}

.labelled-content.north .label {
  display: block;
  width: auto;
  width: initial;
}

.labelled-content.west .label {
  display: inline-block;
  width: 6rem;
}

.labelled-content {
  display: block;
  margin: 0.7em 0 0.7em 0;
  letter-spacing: -0.015em;
}

.fleet-date-picker .react-datepicker__triangle {
  left: 25px;
}

.fleet-date-picker-icon {
  cursor: pointer;
  font-size: 1rem;
}

.fleet-date-picker.with-input {
  display: flex;
}

.fleet-date-picker.with-input input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fleet-date-picker.with-input.focus .fleet-date-picker-icon {
  border: 1px solid #4698CB;
  border-left: 1px solid #DBE2E9;
}

.fleet-date-picker.with-input .fleet-date-picker-icon {
  border: 1px solid #DBE2E9;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -1px;
  transition: all 0.3s ease-out;
}

.fleet-date-picker .fleet-date-picker-icon:hover {
  color: #1F2A44;
}

.fleet-date-picker .fleet-date-picker-icon {
  height: 2.25rem;
  padding: 0 0.6rem;
  display: flex;
  align-items: center;
  vertical-align: middle;
  border: 1px solid transparent;
}

.fleet-date-picker.inline {
  display: inline-block;
  margin: 0 0.4em;
  box-sizing: border-box;
}

.fleet-date-picker.inline:focus {
  outline: none;
}

.fleet-date-picker.inline {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.fleet-date-picker.inline .fa {
  margin-left: 0.45em;
}

.fleet-date-picker.inline:hover {
  color: #1F2A44;
}

.widget.error  {
  height: 100%;
  border-color: #E0004D;
}

.fleet-date-picker-icon.error {
  border-color: #E0004D !important;
}

.error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.fleet-date-picker.ro {
  color: #53565A;
  line-height: 45px;
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin: 0;
}

.rp-widget.select-dd .current-value .fa {
  margin-left: 0.45em;
}

.rp-widget.select-dd.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: #000;
  color: initial;
}

.react-datepicker {
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 1px solid #dee4f0;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
}

.react-datepicker,
.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: #363636;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #4698CB;
  color: #fff !important;
}

.react-datepicker__day:hover {
  background-color: #e6ecf6;
  color: #363636;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  color: #363636 !important;
}

.react-datepicker__triangle {
  border-bottom-color: #f7f9fd !important;
}

.react-datepicker__triangle::before {
  border-bottom-color: #dee4f0 !important;
}

.react-datepicker__header {
  background-color: #f7f9fd;
  border-bottom: 1px solid #dee4f0;
}

.react-datepicker__current-month {
  font-weight: 500;
}

.react-datepicker__day {
  font-size: 0.85rem;
  font-weight: 400;
}

.react-datepicker__day--today {
  font-weight: 600 !important;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc !important;
}

.react-datepicker__day--disabled:hover {
  cursor: default;
  background: none;
}

.react-datepicker__today-button {
  background-color: #F7F9FD;
  border-top: 1px solid #dee4f0;
}

.react-datepicker__tether-element {
  left: -8px !important;
}

.moment-date, .moment-time {
  white-space: nowrap;
}
.map-help-message-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 2em;
  pointer-events: none;
}

.map-help-message {
  background-color: #fff;
  line-height: 3em;
  color: #4698cb;
  border-radius: 2em;
  padding: 0 2em;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.5);
}

.map-loading-overlay {
  display: none;
}

.map-loading-overlay.visible {
  display: block;
  position: absolute;
  height: calc(100% - 17px);
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: map-loading-overlay-fadein 0.2s ease-in-out;
          animation: map-loading-overlay-fadein 0.2s ease-in-out;
}

@-webkit-keyframes map-loading-overlay-fadein {
  0%   { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.5); }
}

@keyframes map-loading-overlay-fadein {
  0%   { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.5); }
}

.rp-widget.toggle {
  position: relative;
  display: flex;
  align-items: center;
}

.rp-widget .loading-icon {
  margin-left: 5px;
}

.rp-widget .toggle-after-text {
  margin-left: 10px;
}

.rp-widget .not-loading {
  padding-right: 20px;
}

.rp-widget .toggle-before-text {
  margin-right: 10px;
}

.rp-widget .toggle-after-text,
.rp-widget .toggle-before-text {
  opacity: 0.8;
}

.rp-widget.toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.rp-widget.toggle .switch {
  width: 50px;
  height: 30px;
  border-radius: 15px;
  background-color: #53565A;
  box-shadow: inset 0 1px 4px 0 #53565A;
  cursor: pointer;
}

.rp-widget.toggle.small .switch {
  width: 35px;
  height: 20px;
  border-radius: 10px;
}

.rp-widget.toggle.ro .switch {
  opacity: 0.35;
  cursor: not-allowed;
}

.rp-widget.toggle .switch:after {
  content: ' ';
  background-color: #fff;
  display: inline-block;
  width: 29px;
  height: 29px;
  border-radius: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #C5CBD1;
  margin-top: -1px;
  margin-left: -5px;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.rp-widget.toggle.small .switch:after {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.rp-widget.toggle input[type="checkbox"]:checked~label .switch {
  background-color: #4698cb;
}

.rp-widget.toggle input[type="checkbox"]:checked~label .switch:after {
  -webkit-transform: translate3d(30px, 0, 0);
          transform: translate3d(30px, 0, 0);
}

.rp-widget.toggle.small input[type="checkbox"]:checked~label .switch:after {
  -webkit-transform: translate3d(22px, 0, 0);
          transform: translate3d(22px, 0, 0);
}

.progress-bar {
    background-color: #DBE2E9;
    border-radius: 12px; /* (height of inner div) / 2 + padding */
    padding: 1px;
}

.progress-bar > div {
    background-color: #26D07C;
    width: 0%; /* Adjust with JavaScript */
    height: 8px;
    border-radius: 10px;
}

.progress-text .final {
    font-size: 1.5rem;
    display: inline;
}

.progress-text .progress {
    font-size: 2.0rem;
    display: inline;
}
.rp-widget.infobox {
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0.6em 1em;
  margin-bottom: 0.5em;
}

.rp-widget.infobox .title {
  font-weight: 500;
}

.rp-widget.infobox.info {
  background-color: rgba(70, 152, 203, 0.1);
  border-color: rgba(70, 152, 203, 0.3);
}

.rp-widget.infobox.warning {
  background-color: rgba(224, 0, 77, 0.1);
  border-color: rgba(224, 0, 77, 0.3);
}

.rp-widget.infobox.success {
  background-color: rgba(0, 224, 92, 0.1);
  border-color: rgba(0, 224, 149, 0.3);
}

.rp-widget.infobox.error {
  background-color: rgba(255, 102, 85, 0.1);
  border-color: rgba(255, 102, 85, 0.3);
}

.rp-widget.time-picker {
  position: relative;
  display: inline-block;
  padding: 0 0.4em;
  box-sizing: border-box;
}

.rp-widget.time-picker:focus {
  outline: none;
}

.rp-widget.time-picker .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.rp-widget.time-picker.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: inherit;
}

.rp-widget.time-picker .current-value:after {
  content: '\f107';
  font-family: "FontAwesome", monospace;
  font-weight: 100;
  margin-left: 0.45em;
}

.rp-widget.time-picker.ro .current-value:after {
  content: '';
  margin-left: 0;
}

.rp-widget.time-picker .current-value:hover {
  color: #1F2A44;
}

.rp-widget.time-picker .current-value .fa {
  margin-left: 0.45em;
}

.rp-widget.time-picker .time-picker-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: normal;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9em;
}

.rp-widget.time-picker .time-picker-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 1em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.rp-widget.time-picker .time-components-container {
  padding: 1rem 2rem;
}

.rp-widget.time-picker .time-components-container .component {
  text-align: center;
  width: 2.4em;
}

.rp-widget.time-picker .time-components-container .component.controls-container {
  width: 100%;
  padding: 0 0.4em;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 1.4em;
  color: #4698cb;
}

.rp-widget.time-picker .time-components-container .component.controls-container .fa {
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.rp-widget.time-picker .time-components-container .component.controls-container .fa:hover {
  color: #1F2A44;
}

.rp-widget.time-picker .time-components-container .current-value-edit {
  font-size: 2em;
  font-weight: 300;
}

.rp-widget.time-picker .current-value .offset {
  font-size: 0.8em;
  margin-left: 0.5em;
}

.rp-widget.time-picker .time-components-container .offset {
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
  width: 100%;
  margin-top: 1em;
}

.rp-widget.time-picker .time-components-container .now {
  text-align: center;
  width: 100%;
  font-size: 1.2em;
  margin-top: 1em;
}


.fleet-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 99999;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1000ms linear;
  -webkit-animation: fleet-modal-overlay-fade-in 600ms 1;
          animation: fleet-modal-overlay-fade-in 600ms 1;
}

.fleet-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  min-height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

@-webkit-keyframes fleet-modal-overlay-fade-in {
  0%   { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.6); }
}

@keyframes fleet-modal-overlay-fade-in {
  0%   { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.6); }
}

.fleet-modal {
  background-color: #fff;
  width: 50em;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
}

.fleet-modal header {
  font-size: 1.75em;
  font-weight: 300;
  width: 100%;
  padding: 1.5rem 2.45rem 0;
  box-sizing: border-box;
}

.fleet-modal header > div {
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #DBE2E9;
}

.fleet-modal footer {
  width: 100%;
  padding: 1.5rem 2.45rem;
  box-sizing: border-box;
  margin-top: 1.5rem;
  border-top: 1px solid #DBE2E9;
  background-color: #F7F9FD;
  border-radius: 0 0 10px 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.fleet-modal footer button.secondary {
  float: left;
}

.fleet-modal footer button.primary {
  float: right;
}

.fleet-modal.confirm .content {
  font-size: 1.3em;
  font-weight: 300;
  padding: 1.5rem 2.45rem 0 2.45rem;
}

.fleet-modal .rp-widget.infobox {
  margin-left: 2.45rem;
  margin-right: 2.45rem;
}

.fleet-modal .content {
  max-height: 80%;
}

.root {
  min-height: 100vh;
  height: auto;
  height: initial;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content-wrapper {
  display: flex;
  bottom: 0;
  min-height: calc(100vh - 50px);
  height: 100%;
  /* Allow room for the header */
  flex: 1 0 auto;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.content-container {
  background-color: #F7F9FD;
  box-sizing: border-box;
  min-height: 100vh;
}

.fullscreen-dashboard {
  position: absolute;
  top: 0;
  left: 0;
}

.tooltip-body-container {
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}

.content-container.no-sidebar {
  width: 100%;
  /* Note the use of 300px below - this matches the width of the sidebar meaning that the
     content then aligns nicely with the darker black bit in the header nav. */
  padding: 3.8em 300px;
  display: flex;
  justify-content: center;
}

.page .page-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBE2E9;
  position: relative;
  min-height: 62px;
}

.page .page-header .break {
  flex-basis: 100%;
  height: 0;
}

.page .page-header h1 {
  font-size: 1.71em;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page .page-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
}

.page .page-content h2 {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 2.5;
}

.page .page-search-container {
  padding: 2rem 0 1rem 0;
  display: flex;
  align-items: center;
}

.page .page-search-container>div:not(:first-child),
.page .page-search-container>span:not(:first-child) {
  margin-left: 1em;
}

.page .breadcrumb {
  font-size: 0.8em;
  margin-top: 5px;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  left: 0;
}

.page .breadcrumb:hover,
.page .breadcrumb:hover a {
  opacity: 1;
  text-decoration: none;
}

.page .breadcrumb a {
  position: relative;
}

.page .breadcrumb .back-icon {
  font-size: 0.8em;
  position: absolute;
  top: 3px;
}

.page .breadcrumb .breadcrumb-label {
  margin-left: 1em;
}

.page .header-actions {
  white-space: nowrap;
}

.page .subheading {
  position: absolute;
  bottom: 0.1em;
  left: 0.1em;
  color: #6c6f73;
}

.new-dispatch-info {
  padding: 0 2.45rem 1rem 2.45rem;
}

.new-dispatch-info > div {
  margin: 0.6em 0;
}

.new-dispatch-info .customer-msg {
  display: flex;
}

.new-dispatch-info .customer-msg-summary {
  max-width: 95%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
  border-bottom: 1px dotted #4698cb;
}

.new-dispatch-info .customer-msg .edit-container {
  overflow: hidden;
  position: relative;
  color: #4698cb;
  cursor: pointer;
  line-height: 0.8em;
  transition: color 0.3s ease;
  display: flex;
  width: 85%
}

.new-dispatch-info .customer-msg .edit-container:hover {
  color: #1F2A44;
}

.new-dispatch-info .customer-msg .title {
  white-space: nowrap;
  margin-right: 0.3em;
}

.new-dispatch-info .customer-msg .edit-icon {
  font-size: 0.7em;
  margin-top: 7px;
  margin-left: 5px;
}

.new-dispatch-info .lockout-message {
  font-style: italic;
  color: #85888c;
  font-size: 0.9em;
  line-height: 1.8em;
}

.dispatch-controls .rc-slider-disabled {
  background-color: unset;
}

.dispatch-controls .rc-slider-disabled .rc-slider-handle {
  box-shadow: none;
}

.dispatch-controls .rc-slider-rail {
  width: 100%;
  height: 8px;
  border-radius: 2px;
}

.dispatch-controls .rc-slider-handle {
  margin-top: -15px;
  margin-left: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.dispatch-controls .headers,
.dispatch-controls .footers {
  display: flex;
  justify-content: space-between;
}

.dispatch-controls .headers {
  margin-bottom: 0.6rem;
}

.dispatch-controls .footers {
  font-size: 0.87em;
  margin-top: 1.1rem;
  color: #4698cb;
}

.dispatch-controls-drop-down {
  position: relative;
  display: inline-block;
  padding-left: 0.4em;
  box-sizing: border-box;
}

.dispatch-controls-drop-down:focus {
  outline: none;
}

.dispatch-controls-drop-down .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.dispatch-controls-drop-down.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: inherit;
}

.dispatch-controls-drop-down .current-value:hover {
  color: #1F2A44;
}

.dispatch-controls-drop-down .current-value .fa {
  margin-left: 0.45em;
}

.dispatch-controls-drop-down .dispatch-controls-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: normal;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40em;
  padding: 1em;
  box-sizing: border-box;
  left: -25em;
}

.dispatch-controls-drop-down .dispatch-controls-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 26em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.dispatch-controls-drop-down .dispatch-controls-wrapper {
  width: 100%;
}

.dispatch-controls-drop-down .dispatch-controls-container .power-factor {
  margin-top: 1.5em;
  border-top: 1px solid #DBE2E9;
  padding-top: 1.5em;
}

.power-factor-constraint-message {
  font-style: italic;
  color: #85888c;
}

.dispatch-controls-drop-down .lag-lead-container {
  display: flex;
  margin-top: 0.8em;
  align-items: center;
  justify-content: center;
}

.dispatch-controls-drop-down .lag-lead-container.disabled {
  color: #a3a6aa;
}

.dispatch-controls-drop-down .lag-lead-container label {
  min-width: 8em;
  display: inline-block;
}

.strategy-controls .rc-slider-disabled {
  background-color: unset;
}

.strategy-controls .rc-slider-disabled .rc-slider-handle {
  box-shadow: none;
}

.strategy-controls .rc-slider-rail {
  width: 100%;
  height: 8px;
  border-radius: 2px;
}

.strategy-controls .rc-slider-handle {
  margin-top: -15px;
  margin-left: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.strategy-controls .headers,
.strategy-controls .footers {
  display: flex;
  justify-content: space-between;
}

.strategy-controls .headers {
  margin-bottom: 0.6rem;
}

.strategy-controls .footers {
  font-size: 0.87em;
  margin-top: 1.1rem;
  color: #4698cb;
}

.strategy-controls-drop-down {
  position: relative;
  display: inline-block;
  padding: 0 0.4em;
  box-sizing: border-box;
}

.strategy-controls-drop-down:focus {
  outline: none;
}

.strategy-controls-drop-down .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.strategy-controls-drop-down.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: inherit;
}

.strategy-controls-drop-down .current-value:hover {
  color: #1F2A44;
}

.strategy-controls-drop-down .current-value .fa {
  margin-left: 0.45em;
}

.strategy-controls-drop-down .strategy-controls-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: normal;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40em;
  padding: 1em;
  box-sizing: border-box;
  left: -25em;
}

.strategy-controls-drop-down .strategy-controls-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 26em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.strategy-controls-drop-down .strategy-controls-wrapper {
  width: 100%;
}

.strategy-controls-drop-down .strategy-container {
  display: flex;
  margin-top: 0.8em;
  align-items: center;
  justify-content: center;
}

.strategy-controls-drop-down .strategy-container.disabled {
  color: #a3a6aa;
}

.strategy-controls-drop-down .strategy-container label {
  min-width: 8em;
  display: inline-block;
}

.strategy-controls-drop-down .strategy-explanation {
  margin: 1.5em 1ex 1ex;
  border-top: 1px solid #DBE2E9;
  line-height: 1.5;
}

.strategy-controls-drop-down .strategy-explanation h2 {
  color: #4698cb;
  margin-top: 1.5ex;
}

.new-dispatch-response-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.new-dispatch-response-graph .graph-container {
  width: 100%;
  height: 100%;
}

.new-dispatch-response-graph .legend-container {
  display: flex;
  background-color: #fff;
  font-size: 0.85em;
  border-top: 1px solid #DBE2E9;
  padding: 0.46em 2em;
}

.new-dispatch-response-graph .legend-container .legend {
  margin: 0 0.6em;
  display: flex;
  align-items: center;
}

.new-dispatch-response-graph .legend-container .key-style {
  margin-right: 0.4em;
  display: inline-block;
  width: 15px;
}

.new-dispatch-response-graph .legend-container .key-style.prediction {
  border-top: 3px dotted #26D07C;
}

.new-dispatch-response-graph .legend-container .key-style.adjusted-prediction {
  border-top: 3px dotted #4698cb;
}

.new-dispatch-response-graph .legend-container .key-style.market-prediction {
  border-top: 3px dotted rgba(68, 68, 68, 0.6);
}

.new-dispatch-response-graph .graph-label {
  position: absolute;
  right: 4.5rem;
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.8;
}

.new-dispatch-response-graph .graph-label.import {
  top: 2rem;
}

.new-dispatch-response-graph .graph-label.export {
  bottom: -1.5rem;
}

.new-dispatch-pricing {
  padding: 1.5rem 2.45rem 0 2.45rem;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.new-dispatch-pricing > div {
  flex: 1 1;
}

.new-dispatch-pricing h4 {
  color: #4698cb;
  font-size: 0.9em;
}

.new-dispatch-pricing .price {
  font-size: 2.2em;
  font-weight: 300;
}

.customer-app-message-preview {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3.4rem;
  max-height: 18rem;
  overflow: hidden;
}

.customer-app-message-preview .title {
  color: #FF9A3C;
  width: 100%;
  text-align: center;
  font-size: 1em;
}

.customer-app-message-preview .message {
  color: #9b9b9b;
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  padding: 0 1.7em;
  box-sizing: border-box;
  font-size: 0.75em;
  line-height: 1.1em;
}

.customer-app-message-edit-container {
  padding: 0 2.45rem 1rem 2.45rem;
  display: flex;
  justify-content: space-between;
}

.customer-app-message-edit-container p:not(:first-child) {
  margin: 1em 0;
}

.customer-app-message-edit-container .rp-widget.textarea textarea {
  min-height: 12rem;
  font-weight: 300;
}

.customer-app-message-edit-container .message-container {
  width: 60%;
}

.customer-app-message-edit-container .preview-container {
  width: 36%;
}

.customer-app-message-edit-container .history-container {
  font-size: 0.9em;
  text-align: right;
  width: 100%;
  color: #4698cb;
  font-weight: 300;
}

.customer-app-message-edit-container .history-container .history-icon {
  margin-right: 0.5em;
}

.customer-app-message-edit-container .rp-widget.select-dd {
  text-align: left;
  padding: 0;
}

.customer-app-message-edit-container .rp-widget.select-dd .current-value {
  border-bottom: none;
}

.customer-app-message-edit-container .rp-widget.select-dd .options-container {
  width: 20em;
  font-size: 1.2em;
  color: #53565A;
}

.customer-app-message-edit-container .rp-widget.select-dd .options-container .option {
  white-space: normal;
}


.new-dispatch-confirmation .new-dispatch-info > div {
  margin: 0;
}

.new-dispatch-confirmation .new-dispatch-info .ro {
  font-weight: 500;
}


.new-dispatch-confirmation .pricing-summary {
  border-top: 1px solid #DBE2E9;
  border-bottom: 1px solid #DBE2E9;
  margin: 1rem 2.45rem 1rem 2.45rem;
  padding: 1rem 0 1rem 0;
}

.new-dispatch-confirmation .labelled-content .label {
  font-weight: normal;
}

.new-dispatch-confirmation .confirm-message {
  padding: 3rem 2.45rem 3rem 2.45rem;
  font-size: 1.2em;
  text-align: center;
}

.pagination-control-container {
  display: flex;
  font-size: 13px;
  padding: 5px 0 5px 5px;
}

.pagination-container {
  align-items: center;
  display: flex;
}

.pagination-controls {
  display: flex;
}

.pagination-controls div {
  border-radius: 5px;
  color: rgb(70, 152, 203);
  cursor: default;
  margin: 0 2px;
  min-width: 20px;
  padding: 2px;
  text-align: center;
}

.pagination-controls .current-page, .pagination-controls .clickable:hover {
  background-color: rgba(11, 123, 214, 0.925);
  color: rgb(252, 252, 252);
}

.pagination-controls .clickable {
  border: 1px solid #DBE2E9;
  cursor: pointer;
}

.pagination-container .results-per-page {
  margin-left: 30px;
}

.pagination-container .pagination-display-text {
  margin-left: 10px;
}

.new-curtailment-info {
  padding: 0 2.45rem 1rem 2.45rem;
}

.new-curtailment-info > div {
  margin: 0.6em 0;
}

.curtailment-controls .rc-slider-disabled {
  background-color: unset;
}

.curtailment-controls .rc-slider-disabled .rc-slider-handle {
  box-shadow: none;
}

.curtailment-controls .rc-slider-rail {
  width: 100%;
  height: 8px;
  border-radius: 2px;
}

.curtailment-controls .rc-slider-handle {
  margin-top: -15px;
  margin-left: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.curtailment-controls .headers {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.curtailment-controls .footers {
  display: flex;
  justify-content: space-between;
}

.curtailment-controls .headers {
  margin-bottom: 0.6rem;
}

.curtailment-controls .footers {
  font-size: 0.87em;
  margin-top: 1.1rem;
  color: #4698cb;
}

.curtailment-controls-drop-down {
  position: relative;
  display: inline-block;
  padding-left: 0.4em;
  box-sizing: border-box;
}

.curtailment-controls-drop-down:focus {
  outline: none;
}

.curtailment-controls-drop-down .current-value {
  cursor: pointer;
  border-bottom: 1px dotted #4698cb;
  display: inline-block;
  line-height: 1.5em;
  color: #4698cb;
  transition: color 0.3s ease-out;
}

.curtailment-controls-drop-down.ro .current-value {
  cursor: default;
  border-bottom: none;
  color: inherit;
}

.curtailment-controls-drop-down .current-value:hover {
  color: #1F2A44;
}

.curtailment-controls-drop-down .current-value .fa {
  margin-left: 0.45em;
}

.curtailment-controls-drop-down .curtailment-controls-container {
  position: absolute;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 1.5em;
  z-index: 999;
  border-radius: 3px;
  line-height: normal;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40em;
  padding: 1em;
  box-sizing: border-box;
  left: -25em;
}

.curtailment-controls-drop-down .curtailment-controls-container .tip {
  position: absolute;
  background: #fff;
  top: -10px;
  left: 26em;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  box-shadow: 4px 6px 12px 0 rgba(0, 0, 0, 0.16);
  width: 20px;
  height: 20px;
  display: block;
  border: none;
}

.curtailment-controls-drop-down .curtailment-controls-wrapper {
  width: 100%;
}

.new-curtailment-response-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.new-curtailment-response-graph .graph-container {
  width: 100%;
  height: 100%;
}

.new-curtailment-response-graph .legend-container {
  display: flex;
  background-color: #fff;
  font-size: 0.85em;
  border-top: 1px solid #DBE2E9;
  padding: 0.46em 2em;
}

.new-curtailment-response-graph .legend-container .legend {
  margin: 0 0.6em;
  display: flex;
  align-items: center;
}

.new-curtailment-response-graph .legend-container .key-style {
  margin-right: 0.4em;
  display: inline-block;
  width: 15px;
}

.new-curtailment-response-graph .legend-container .key-style.solar-prediction {
  border-top: 3px dotted #F68D26;
}

.new-curtailment-response-graph .legend-container .key-style.grid-prediction {
  border-top: 3px dotted #4698cb;
}

.new-curtailment-response-graph .graph-label {
  position: absolute;
  right: 4.5rem;
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.8;
}

.new-curtailment-response-graph .graph-label.import {
  top: 2rem;
}

.new-curtailment-response-graph .graph-label.export {
  bottom: -1.5rem;
}

.new-curtailment-confirmation .new-curtailment-info > div {
  margin: 0;
}

.new-curtailment-confirmation .new-curtailment-info .ro {
  font-weight: 500;
}

.new-curtailment-confirmation .labelled-content .label {
  font-weight: normal;
}

.new-curtailment-confirmation .confirm-message {
  padding: 3rem 2.45rem 3rem 2.45rem;
  font-size: 1.2em;
  text-align: center;
}

.fleet-tab-bar {
  display: flex;
  margin-top: -1px;
  margin-bottom: 1.4em;
  font-weight: normal;
  font-size: 0.95em;
}

.fleet-tab-bar .fleet-tab {
  padding: 1.2em 0;
  transition: all 0.3s ease-out;
  border-top: 1px solid transparent;
  display: flex;
  align-items: center;
}

.fleet-tab-bar .fleet-tab:not(:first-child) {
  margin-left: 1.8em;
}

.fleet-tab-bar .fleet-tab:hover {
  cursor: pointer;
  border-top: 1px solid #000;
  z-index: 9999;
}

.fleet-tab-bar .fleet-tab.active {
  border-top: 1px solid #4698cb;
  color: #4698cb;
  z-index: 9999;
}

.fleet-tab-bar .badge {
  background-color: #E0004D;
  padding: 0.1em 0.4em;
  border-radius: 5px;
  color: #fff;
  height: 1.25em;
  line-height: 1.2em;
  font-size: 0.9em;
  margin-left: 0.6em;
  font-weight: bold;
}

.new-setpoint-info {
    padding: 0 2.45rem 1rem 2.45rem;
}

.new-setpoint-info > div {
    margin: 0.6em 0;
}
.fleet-deployments-table-status-wrapper .status-label {
  display: flex;
  align-items: center;
}

.fleet-deployments-table-status-wrapper .circle {
  height: 17px;
  width: 17px;
  border-radius: 8px;
  font-family: "FontAwesome", monospace;
}

.fleet-deployments-table-status-wrapper .circle::before {
  display: block;
  height: 17px;
  width: 17px;
  line-height: 19px;
  text-align: center;
  font-size: 0.85em;
  color: #fff;
}

.fleet-deployments-table-status-wrapper .circle.offline {
  background-color: #E0004D;
}

.fleet-deployments-table-status-wrapper .circle.offline::before {
  content: '\f12a';
}

.fleet-deployments-table-status-wrapper .circle.awaiting-contact {
  background-color: #92969B;
}

.fleet-deployments-table-status-wrapper .circle.awaiting-contact::before {
  content: '\f141';
}

.fleet-deployments-table-status-wrapper .circle.online {
  background-color: #26D07C;
}

.fleet-deployments-table-status-wrapper .circle.online::before {
  content: '\f00c';
}

.fleet-deployments-table-status-wrapper .title {
  letter-spacing: 0.03em;
  margin-left: 0.4em;
}

.fleet-deployments-table-status-wrapper .last-contact {
  font-size: 0.8em;
  margin-left: calc(19px + 0.4em);
}
h6.no-deployments-message {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.site-label-cell {
  max-height: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.powerstation-type {
  align-items: center;
  display: flex;
  height: 1.8rem;
  padding: 1em 0;
  vertical-align: middle;
}

.powerstation-type.simple {
  border-bottom: 0;
  height: auto;
  height: initial;
  padding: 0;
}

.powerstation-type {
  border-bottom: 1px solid #DBE2E9;
}

.powerstation-type.simple .rp-widget.select-dd.ro,
.powerstation-type.simple .rp-widget.input.ro {
  padding: 0;
  line-height: normal;
  line-height: initial;
  cursor: pointer;
}

.powerstation-type.simple .rp-widget.select-dd.ro .current-value,
.powerstation-type.simple .rp-widget.input.ro .current-value {
  cursor: pointer;
}

.powerstation-type .filter-item {
  display: inline-block;
}

.powerstation-type .filter-item input {
  height: 1.8rem;
  width: 300px;
  font-size: 0.9em;
}

.powerstation-type .spacer {
  margin: 0 0.5em;
}

.powerstation-type.simple .spacer {
  margin: 0 0.2em;
}

.powerstation-type .filter-type-dd-label {
  display: flex;
  justify-content: space-between;
}

.powerstation-type .filter-type-dd-label .enrolment-type {
  margin-left: 1.6em;
  font-size: 0.9em;
  color: #8a8d91;
  text-decoration: none !important;
}

.rp-widget.select-dd .options-container .option:hover .enrolment-type {
  text-decoration: none !important;
  display: inline-block;
}

table.powerstation-table td {
  max-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table.powerstation-table td p {
  text-overflow: ellipsis;
  overflow: hidden;
}

table .powerstation-table-column-long {
  width: 30%;
}

.powerstation-deployments-list-selector-container {
  background-color: #FFF;
  width: 100%;
  padding: 0.4em 0 0.2em 0.8em;
  border-top: 1px solid #DBE2E9;
  border-bottom: 1px solid #DBE2E9;
  box-sizing: border-box;
  font-size: 12px;
  margin-top: 3px;
}

.powerstation-deployments-list-selector {
  display: flex;
}

.unassigned-deployments-table-container {
  min-height: 35em;
  margin: 0 2.45rem;
}

.unassigned-deployment-children {
  margin: 0.3rem 2.45rem;
  min-height: 36px;
}


.powerstation-type-error {
  border-color: #E0004D;
}

.vpp-name-container, .vpp-type-container {
  padding: 0.2rem 2.45rem;
}

.sidebar {
  width: 300px;
  border-right: solid 1px #DBE2E9;
  min-height: 100%;
  position: fixed;
  margin-top: 50px;
  height: 100vh;
  overflow-y: auto;
}

.sidebar li {
  border-left: 5px solid transparent;
  border-top: 1px solid #DBE2E9;
  font-weight: 400;
  line-height: 2.4em;
}

.sidebar li:hover {
  border-left: 5px solid rgba(70, 152, 203, 0.4);
}

.sidebar li.active {
  height: auto;
  height: initial;
}

.sidebar li a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #53565A;
  padding: 1em 0;
}

.sidebar li .submenu a:not(:first-child) {
  padding: 0.5em 0;
}

.sidebar li.active a:first-child {
  font-weight: 500;
}

.sidebar li .submenu a:not(:first-child) {
  padding: 0;
}

.sidebar li .submenu a:first-child {
  padding-bottom: 0;
}

.sidebar li .active-container a:first-child {
  padding-bottom: 0.2em;
}

.sidebar li .active-container a:last-child {
  padding-bottom: 1em;
}

.sidebar li a:not(:first-child) {
  line-height: 2em;
  font-weight: 300;
}

.sidebar li a:hover {
  color: #4698CB;
}

.sidebar .active-container {
  display: flex;
  align-items: flex-start;
}

.sidebar li a.active {
  color: #4698CB;
}

.sidebar li a:hover {
  text-decoration: none;
}

.sidebar li:first-child {
  border-bottom: 1px solid #90C1E0;
}

.sidebar li:last-child {
  border-top: 1px solid #90C1E0;
  border-bottom: 1px solid #DBE2E9;
}

.sidebar li.active {
  border-left: 3px solid #4698CB;
  background-color: #F8F9FB;
}

.sidebar li .icon-container {
  width: 4.5em;
  display: flex;
  justify-content: center;
}

.sidebar li.active .icon-container {
  padding-top: 1em;
}

.sidebar .badge {
  background-color: #E0004D;
  /*background-color: #4698cb;*/
  padding: 0.1em 0.4em;
  border-radius: 5px;
  color: #fff;
  height: 1.25em;
  line-height: 1.2em;
  font-size: 0.9em;
  margin-left: 0.6em;
  font-weight: bold;
}

.content-container.with-sidebar {
  width: calc(100% - 300px);
  padding: 2.4em 2.5em;
  padding-top: 50px;
  box-sizing: border-box;
  margin-left: 300px;
  min-height: 100vh;
}

/* This is a hack to use MS/IE11-only selectors so that the style will only be applied in IE11. */
@media all and (-ms-high-contrast:none) {
  .content-container.with-sidebar {
    padding-top: 50px;
    box-sizing: border-box;
  }

  /* IE11 */
}

.loading-container {
  position: relative;
  height: 4em;
}

.login-message {
  margin-top: 1em;
  font-weight: 600;
  color: red;
}

.widget-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100%;
  width: 100%;
}

.widget-content.fullscreen {
  border-radius: 0;
}

.widget-content:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.widget-content header {
  width: calc(100% - 2.8em);
  height: 2.8em;
  margin: 0 1.4em;
  border-bottom: 1px solid rgba(70, 152, 203, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.widget-content header h3.not-configured {
  color: #1f2a40;
}

.widget-content.fullscreen header {
  height: 8em;
}

.widget-content header .title-container {
  width: 100%;
}

.widget-content header .title-container h3 {
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.widget-content-wrapper {
  width: 100%;
  height: calc(100% - 2.8em);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.widget-content header .widget-controls {
  width: 2.1em;
  cursor: pointer;
  display: flex;
  font-size: 0.9em;
}

.widget-content.fullscreen header .widget-controls {
  width: 15rem;
  font-size: 2em;
}

.widget-content.fullscreen header .widget-controls.right > span {
  margin-left: 0.6em;
}
.widget-content header .widget-controls span:hover {
  opacity: 0.5;
}

.widget-content header .widget-controls.right {
  justify-content: flex-end;
}

.widget-content header .widget-controls span:first-child {
  margin-right: 0.4em;
}

.widget-content header .title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.widget-content header .pages-container {
  margin-top: -0.5em;
}

.widget-content header .pages-container > span {
  margin: 0 0.2em;
  font-size: 0.6em;
  cursor: pointer;
}

.widget-content.fullscreen header .title-container {
  font-size: 1.8em;
}

.widget-drag-handle {
  cursor: move;
}

.widget-drag-handle.not-configured {
  color: #1f2a40;
}


.react-grid-item {
  height: 100%;
}

.fullscreen-dashboard .react-grid-item {
  height: calc(100% - 50px);
}

.react-grid-item.dashboard-widget > .react-resizable-handle {
  background: none;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.react-grid-item.dashboard-widget > .react-resizable-handle:before {
  content: '\f105';
  font-family: "FontAwesome", monospace;
  margin-left: 10px;
}

.react-grid-item.react-grid-placeholder {
  background: #4698cb !important;
}

.unconfigured {
    height: 100%;
    width: 100%;
    text-align: center;
    margin-top: 7em;
    font-size: 1.3em;
    font-weight: 300;
    letter-spacing: 0.04em;
    color: #1f2a40;
}

.voltage-heat-map-widget {
  position: relative;
}

.voltage-heat-map-widget .key-container {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 10px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 0.5em;
}

.widget-content.fullscreen .voltage-heat-map-widget .key-container {
  box-sizing: border-box;
  bottom: 75px;
  font-size: 2em;
}

.voltage-heat-map-widget .key-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4em 0 0.8em 0;
  font-size: 0.9em;
}

.voltage-heat-map-widget .key-container .key-block {
  height: 1em;
  width: 6em;
  border: 1px solid;
  margin-top: 0.2em;
}

.voltage-heat-map-widget .key-container .key-block.under {
  border-color: rgb(161, 207, 206);
  background-color: rgba(161, 207, 206, 0.7);
}

.voltage-heat-map-widget .key-container .key-block.ok {
  border-color: rgb(38, 208, 124);
  background-color: rgba(38, 208, 124, 0.7);
}

.voltage-heat-map-widget .key-container .key-block.over {
  border-color: rgb(235, 175, 115);
  background-color: rgba(235, 175, 115, 0.7);
}

.single-stat-widget header {
  position: absolute;
  color: #fff;
  font-weight: 300;
}

.single-stat-widget.commissioned header {
  border-bottom: 1px solid #4698CB;
}

.single-stat.commissioned {
  background: linear-gradient(to bottom right, #347299, #3191CC);
}

.single-stat-widget.offline header {
  border-bottom: 1px solid rgba(70, 152, 203, 0.2);
}

.single-stat.offline {
  background: linear-gradient(to bottom right, #E0004D, #AD003D);
}

.single-stat-widget.not-commissioned header {
  border-bottom: 1px solid rgba(70, 152, 203, 0.2);
}

.single-stat.not-commissioned {
  background: linear-gradient(to bottom right, #F58D25, #DB7E21);
}

.single-stat.single-stat-unconfigured {
  background: #fff;
  color: #1f2a40;
}

.single-stat-widget .widget-content-wrapper {
  height: 100%;
}

.single-stat-widget .react-resizable-handle {
  color: #fff;
}

.single-stat {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widget-content.fullscreen .single-stat {
  border-radius: 0;
  font-size: 3em;
}

.single-stat .counter {
  font-size: 10em;
  font-weight: 100;
  letter-spacing: -0.05em;
  margin-top: -0.2em;
}

.single-stat .description {
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: 0.05em;
  margin-top: -3em;
}

.widget-content.fullscreen .counter {
  font-weight: 300;
}

.widget-content.fullscreen .description {
  font-weight: 400;
}

.single-stat .explore-link {
  position: absolute;
  bottom: 0.6em;
  font-size: 0.8em;
}

.dashboard-widget-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-widget-settings-container footer {
  border-top: 1px solid #dee4f0;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: right;
  padding: 1em;
  width: 100%;
}

.dashboard-widget-settings-container .setting {
  width: 20rem;
}

.dashboard-widget-settings-container .setting .display-name {
  font-weight: 400;
  color: #4698cb;
  padding: 0.3em;
}
.dashboard-widget-settings-container .checkbox-label {
  display: flex;
  align-items: center;
}

.graph-widget {
  height: 100%;
  width: 100%;
}

.graph-widget .stats-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1.8em 1.4em;
  height: 4rem;
}

.graph-widget .stats-container .stat {
  width: 50%;
  box-sizing: border-box;
}

.graph-widget .stats-container .stat:first-child {
  margin-right: 1rem;
}

.graph-widget .stats-container .value {
  font-size: 2.25rem;
  font-weight: 200;
  line-height: 2.6rem;
}

.graph-widget .stats-container .label {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.03em;
}

.graph-widget.solar .stat .value {
  color: rgb(255, 157, 66);
}

.graph-widget.meter .stat .value {
  color: rgb(70, 152, 203);
}

.graph-widget.charge .stat .value {
  color: rgb(87, 196, 199)
}

.graph-widget .graph-container {
  width: 100%;
  height: calc(100% - 4rem - 10px); /* height of stats-container minus room for axis labels */
}

.fullscreen-dashboard .graph-widget .stats-container {
  justify-content: space-evenly;
  height: 13rem;
}

.graph-widget .stats-container .stat {
  width: auto;
  width: initial;
}

.fullscreen-dashboard .graph-widget .stats-container .value {
  font-size: 7rem;
  font-weight: 300;
  line-height: 8rem;
  width: 100%;
  text-align: center;
}

.fullscreen-dashboard .graph-widget .stats-container .label {
  font-size: 2.6rem;
  font-weight: 400;
  width: 100%;
  text-align: center
}

.fullscreen-dashboard .graph-widget .graph-container {
  width: 100%;
  height: calc(100% - 9rem - 60px); /* height of stats-container minus room for the ticker */
}

.dashboard-add-widget-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2.45rem;
  max-height: 70vh;
  overflow-y: auto;
}

.dashboard-add-widget-container .add-widget-tile {
  padding: 1em;
  text-align: center;
  width: 30%;
  max-width: 30%;
  border: 1px solid #dee4f0;
  font-weight: 400;
  box-sizing: border-box;
  height: 15em;
  margin-bottom: 1em;
}

.dashboard-add-widget-container .add-widget-tile:hover {
  cursor: pointer;
  background-color: #4698CB;
  color: #fff;
}


.dashboard-add-widget-container .add-widget-tile h3 {
  font-size: 1.4em;
  margin-top: 0.4em;
}

.dashboard-add-widget-container .add-widget-tile .widget-description {
  font-weight: 400;
}


.dashboard-ticker {
  background-color: #1F2A44;
  width: 100%;
  height: 50px;
  position: relative;
}

.dashboard-ticker ul {
  white-space: nowrap;
  height: 100%;
  position: absolute;
  font-size: 1.6em;
  color: #fff;
  text-transform: uppercase;
}

.dashboard-ticker .end-cap {
  width: 80px;
  height: 100%;
}

.dashboard-ticker .end-cap.left {
  position: absolute;
  left: 0;
  background: linear-gradient(to right, #1F2A44, #1F2A4400);
}

.dashboard-ticker .end-cap.right {
  position: absolute;
  right: 0;
  background: linear-gradient(to right, #1F2A4400, #1F2A44);
}

.dashboard-ticker ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0 1em;
  box-sizing: border-box;
}

.dashboard-ticker ul li:not(:last-child) {
  border-right: 1px solid #141B2B;
}

.dashboard-ticker .ticker-content {
  display: flex;
  align-items: center;
}

.dashboard-ticker .ticker-content img {
  margin-right: 0.3em;
  line-height: 2em;
}

.dashboard-ticker .dispatch-ticker .powerstation-name,
.dashboard-ticker .dispatch-ticker .power {
  color: #1699D0;
}

.dashboard-ticker .weather-ticker .temp {
  color: #F68D26;
}

.dashboard-ticker .weather-ticker .cloud {
  color: #1699D0;
}

.dashboard-ticker .installer-ticker .install {
  color: #00D573;
}

.dashboard-ticker .customer-ticker .customer {
  color: #00D573;
}

.tabbed-container {
  width: 100%;
  border: 1px solid #DBE2E9;
  margin-top: 1.5em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.tabbed-container .tabbed-container-tabs {
  display: flex;
  border-bottom: 1px solid #DBE2E9;
  min-height: 50px;
}

.tabbed-container .tabbed-container-tab {
  border-bottom: 3px solid transparent;
  border-right: 1px solid #DBE2E9;
  padding: 1em 2em;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease-out;
}

.tabbed-container .tabbed-container-tab:hover {
  border-bottom: 3px solid rgba(70, 152, 203, 0.4);
  color: #4698cb;
}

.tabbed-container .tabbed-container-tab.active {
  color: #4698cb;
  border-bottom: 3px solid #4698cb;
}

.tabbed-container .tabbed-container-tab.active {
  color: #4698cb;
  border-bottom: 3px solid #4698cb;
}

.tabbed-container .tabbed-container-content {
  flex-grow: 1;
  position: relative;
  height: 100%;
}

.tabbed-container .tabbed-container-content .public_fixedDataTable_header,
.tabbed-container .tabbed-container-content .public_fixedDataTable_header .public_fixedDataTableCell_main,
.tabbed-container .tabbed-container-content .fixedDataTableLayout_header.public_fixedDataTableRow_main:hover,
.tabbed-container .tabbed-container-content .fixedDataTableLayout_header.public_fixedDataTableRow_main:hover,
.tabbed-container .tabbed-container-content .fixedDataTableLayout_header .fixedDataTableCellGroupLayout_cellGroup:hover .fixedDataTableCellLayout_wrap1,
.tabbed-container .tabbed-container-content .public_fixedDataTable_header .fixedDataTableCellGroupLayout_cellGroup:hover .fixedDataTableCellLayout_wrap1 {
  background-color: #fff;
}

.powerstation-nameplate {
  display: flex;
  border-bottom: 1px solid #DBE2E9;
  padding: 1em 0;
}

.powerstation-nameplate > div {
  margin-right: 2em;
}

.powerstation-nameplate label {
  margin-bottom: 0.4em;
  display: inline-block;
  font-weight: 500;
}

.powerstation-nameplate div.value {
  font-size: 1.3em;
  font-weight: 300;
}

.powerstation-nameplate.no-border {
  border-bottom: none;
}

.powerstation-nameplate .value.size {
  min-width: 5.5em;
}

.powerstation-nameplate .value.capacity,
.powerstation-nameplate .value.power {
  min-width: 6em;
}

.powerstation-nameplate .ps-np-loading {
  color: #8a8d91;
  margin-left: 0.5em;
}

.powerstation-nameplate .ps-np-tooltip-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: -2em;
}

.powerstation-incomplete {
  display: flex;
  flex-flow: column;
  padding-top: 1em;
}

.powerstation-incomplete > div {
  margin-right: 2em;
}

.powerstation-incomplete label {
  margin-bottom: 0.4em;
  display: inline-block;
  font-weight: 500;
}

.powerstation-incomplete div.value {
  font-size: 1.3em;
  font-weight: 300;
}

.powerstation-incomplete .select-dd {
  padding: 0;
}

.powerstation-incomplete .select-dd .current-value {
  border-bottom: none;
}

.powerstation-incomplete .select-dd .current-value .fa {
  margin-left: 0;
  margin-right: 0.3em;
}

.powerstation-incomplete .select-dd .current-value:after {
  content: '';
}

.event-table-container, .event-table {
  width: 100%;
}

.powerstation-deployment-search {
  display: flex;
  align-items: center;
}

.powerstation-deployment-search .text-search {
  flex: 4 1;
}

.powerstation-deployment-search .included-only {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.billing-gc-container {
  padding-top: 1em;
  box-sizing: border-box;
}

.billing-transaction-item {
  background-color: #fff;
  margin-bottom: 8px;
}

.billing-transaction-heading {
  line-height: 3.5em;
  box-sizing: border-box;
  padding: 0 8px;
  border-right: 3px solid transparent;
}

.billing-transaction-heading .total {
  float: right;
}

.billing-transaction-heading .expand-icon {
  float: right;
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5em;
  color: #dee4f0;
}

.billing-transaction-heading:hover {
  cursor: pointer;
}

.billing-transaction-item:hover {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.react-sanfona-item-body-wrapper {
  padding: 1em 2.5em;
}

.billing-transaction-item .label {
  width: 12rem;
}

.billing-transaction-item.billing-transaction-expanded {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.billing-transaction-item .link-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.billing-transaction-expanded .billing-transaction-heading {
  background-color: #f7f9fd;
  border-right: 3px solid #45a9d3;
}

.billing-transaction-body {
  display: flex;
}

.billing-transaction-body .summary,
.billing-transaction-body .controls {
  width: 50%;
}

.billing-transaction-body .controls {
  padding: 1.5em 2em;
}

.something .fixedDataTableCellGroupLayout_cellGroup:hover .fixedDataTableCellLayout_wrap1 {
    cursor: default;
}
.search-container {
  display: flex;
}

.system-components .row {
    padding: 20px 10px 40px 10px;
}

.system-components .full-width-data-summary > span:not(:first-child) .full-width-row {
    padding-top: 15px;
}

.system-components .full-width-data-summary > span:not(:last-child) .full-width-row {
    padding-bottom: 15px;
}

.system-components .row:not(:last-child),
.full-width-data-summary .full-width-row:not(:last-child) {
    border-bottom: 1px solid #D9E1E8;
}

.system-components .row.thick-top-border {
    border-top: 2px solid #D9E1E8;
}

.system-components .full-width-data-summary > span:not(:first-child) .thin-top-border {
    border-top: 1px solid #D9E1E8;
}

.system-components .row.energy-system-header {
    padding: 0 10px 25px 10px;
}

.system-components .row.energy-system-header h2 {
    margin-top: 20px;
    padding-left: 4.5em;
}

.system-components .row .cell {
    flex: 1 1;
    max-width: 30em;
}

.system-components .row .cell.no-width {
    max-width: none;
    max-width: initial;
}

.system-components .row .cell.center {
    align-items: center;
    /*justify-content: center;*/
    display: flex;
}

.system-components .cell.cell-header {
    max-width: 15em;
    font-size: 1.25em;
    margin-top: -0.25em
}

.system-components .cell h2 {
    font-size: 1em;
    line-height: normal;
    line-height: initial;
    display: inline-block;
    font-weight: 400;
}

.system-components .cell h3 {
    font-size: 1.1em;
    margin-bottom: 15px;
    line-height: normal;
    line-height: initial;
    display: inline-block;
    font-weight: 300;
}

.data-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
}

.data-list div {
    flex: 55% 1;
    margin-bottom: 3px;
}

.data-list .data-title {
    font-weight: bold;
    flex: 40% 1;
}

.system-components {
    display: flex;
    flex-direction: column;
}

.system-components .row {
    display: flex;
    flex-direction: row;
}

.systme-components .reposit-summary {
    display: flex;
    flex-direction: column;
}

.data-summary .data-item {
    display: flex;
}

.data-summary .data-item .heading {
    flex: 1 1;
    max-width: 10em;
    font-weight: bold;
}

.data-summary .data-item .content {
    flex: 1 1;
}

.fill-width-data-summary {
    display: flex;
    flex-direction: column;
}

.full-width-data-summary .full-width-row {
    display: flex;
}

.full-width-data-summary .full-width-row .full-width-heading {
    flex: 1 1;
    max-width: 15em;
}

.full-width-data-summary .full-width-row .full-width-content {
    flex: 1 1;
}

.system-components .web-portal:after {
    content: "\f08e";
    font-family: "FontAwesome", monospace;
    margin-left: 0.5em;
    color: #4698CB;
    top: 1px;
    position: relative;
    transition: all 0.3s ease-out;
}

.system-components .web-portal:hover:after {
    text-decoration: none;
    color: #1F2A44;
    cursor: pointer;
}

.system-components .energy-system-image-container {
    display: flex;
    align-items: center;
}

.system-components .energy-system-image {
    max-height: 100px;
    max-width: 100px;
    padding: 0 8px;
}

.system-components .solar-panel-image {
    max-height: 62px;
}
.fleet-deployment-location {
  display: flex;
  height: 100%;
}

.fleet-deployment-location .location-info {
  padding: 1em 2em;
  flex: 2 1;
}

.fleet-deployment-location .map-container {
  flex: 5 1;
  height: 100%;
}

.fleet-deployment-location .location-info .labelled-content:not(:last-child) {
  margin-bottom: 2em;
}

.fleet-deployment-location .location-info .labelled-content .label {
  margin-bottom: 0.4em;
}

.fleet-deployment-location .location-info .labelled-content .value {
  font-size: 1.3em;
  font-weight: 300;
}

.stats-panel {
  color: #fff;
  font-weight: 300;
  border-radius: 5px;
  width: 100%;
  padding: 0 1.8em 1.6em 1.8em;
  box-sizing: border-box;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

.stats-panel:not(:first-child) {
  margin-top: 1.2em;
}

.stats-panel.blue {
  background: linear-gradient(to top left, #347299, #3191CC);
}

.stats-panel.blue header {
  border-bottom: 1px solid rgb(37, 118, 181);
}

.stats-panel.red {
  background: linear-gradient(to top left, #AD003D, #E0004D);
}

.stats-panel.red header {
  border-bottom: 1px solid #bc003f;
}

.stats-panel.yellow {
  background: linear-gradient(to top left, #DB7E21, #F58D25);
}

.stats-panel.yellow header {
  border-bottom: 1px solid #e18421;
}

.stats-panel.green {
  background: linear-gradient(to top left, #1C9E5D, #2AD17D);
}

.stats-panel.green header {
  border-bottom: 1px solid #1FAD67;
}

.stats-panel.white {
  background: linear-gradient(to top left, #ececec, #fcfcfc);
  color: #53565A;
}

.stats-panel.white header {
  border-bottom: 1px solid #cccccc;
}

.stats-panel header {
  height: 2.8em;
  margin-bottom: 0.6em;
  display: flex;
  align-items: center;
}

.stats-panel header h3 {
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.stats-panel ul li {
  display: flex;
  margin-bottom: 0.4em;
}

.stats-panel label,
.stats-panel span.value {
  flex: 1 1;
}

.stats-panel label {
  font-weight: 600;
}

.stats-panel .stats-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}

.stats-panel .stats-grid > div {
  flex: 1 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.stats-panel .stats-grid div.value {
  font-weight: 500;
  font-size: 1.2em;
}

.stats-panel .stats-grid label {
  font-weight: 300;
}

.stats-panel .loading {
  position: relative;
  margin-bottom: 0.6em;
}
.monitoring-graph-controls {
  display: flex;
  flex-wrap: wrap;
}

.monitoring-graph-controls .control {
  width: 50%;
  padding-bottom: 1em;
  display: flex;
}

.monitoring-graph-controls .control > label {
  font-size: 1.05em;
  font-weight: 300;
  min-width: 8.5em;
}

.monitoring-graph-controls .control .checkbox-label {
  display: inline-flex;
  padding: 0;
}

.monitoring-graph-controls .control .rp-widget.checkbox {
  margin-left: 0.4em;
}

.monitoring-graph-controls .control .checkbox-label .label-text {
  margin-left: 0.6em;
  font-weight: 300;
}

.monitoring-graph-controls .toggles-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-right: 3em;
}

.monitoring-graph-controls .toggles-container .rp-widget.checkbox {
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  position: static;
  position: initial;
}

.monitoring-graph-controls .label-button-checkbox {
  display: block;
  border: 1px solid #DBE2E9;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 0.85em;
  color: #4698CB;
  padding: 0.15em 2em;
  margin-bottom: 0.6em;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 20em;
}

.monitoring-graph-controls input:checked + .label-button-checkbox {
  color: #fff;
}

.monitoring-graph-controls input:checked + .label-button-checkbox.grid {
  background-color: rgb(70, 152, 203);
  border-color: rgb(70, 152, 203);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.grid:hover {
  background-color: rgba(70, 152, 203, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.grid:hover {
  background-color: rgba(70, 152, 203, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.solar {
  background-color: rgb(246, 141, 38);
  border-color: rgb(246, 141, 38);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.solar:hover {
  background-color: rgba(246, 141, 38, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.solar:hover {
  background-color: rgba(246, 141, 38, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.sga {
  background-color: rgb(31, 42, 68);
  border-color: rgb(31, 42, 68);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.sga:hover {
  background-color: rgba(31, 42, 68, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.sga:hover {
  background-color: rgba(31, 42, 68, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.battery,
.monitoring-graph-controls input:checked + .label-button-checkbox.soc {
  background-color: rgb(224, 0, 77);
  border-color: rgb(224, 0, 77);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.battery:hover {
  background-color: rgba(224, 0, 77, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.battery:hover {
  background-color: rgba(224, 0, 77, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.house {
  background-color: rgb(81, 199, 109);
  border-color: rgb(81, 199, 109);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.house:hover {
  background-color: rgba(81, 199, 109, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.house:hover {
  background-color: rgba(81, 199, 109, 0.3);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.submeter {
  background-color: rgb(87, 196, 199);
  border-color: rgb(87, 196, 199);
}

.monitoring-graph-controls input:checked + .label-button-checkbox.submeter:hover {
  background-color: rgba(87, 196, 199, 0.7);
}

.monitoring-graph-controls input:not(:checked) + .label-button-checkbox.submeter:hover {
  background-color: rgba(87, 196, 199, 0.3);
}
.monitoring-time-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  margin-bottom: 1em;
  align-items: center;
}

.monitoring-time-controls .mp-button.link-inverted {
  margin-right: 2em;
}

.monitoring-time-controls .mp-button.link-inverted.active {
  color: #4698CB;
}

.monitoring-time-controls .data-download-container {

}

.monitoring-time-controls .date-selector-container {
  display: flex;
  align-items: center;
  height: 10px;
  justify-content: space-between;
}

.monitoring-time-controls .date-selector-container h3 {
  text-align: center;
}

.monitoring-time-controls .date-selector-container h3.day {
  min-width: 12.5em;
}

.monitoring-time-controls .date-selector-container h3.week {
  min-width: 17em;
}

.monitoring-time-controls .date-selector-container h3.month {
  min-width: 8.5em;
}

.monitoring-time-controls .date-selector-container h3.year {
  min-width: 2.5em;
}

.monitoring-time-controls .goto-today .fa {
  font-size: 1.3em;
  margin-top: 0.3em;
}
.monitoring {
  display: flex;
  flex: 1 1;
}

.monitoring .graph-container {
  flex: 2 1;
  display: flex;
  flex-direction: column;
}

.monitoring .stats-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1.2em;
  box-sizing: border-box;
  max-width: 26em;
}

.monitoring .graph-container .graph {
  flex: 1 1;
  min-height: 25em;
  position: relative;
}

.monitoring .monitoring-graph-no-data {
  background-color: #c7e6ff;
  border-radius: 5px;
  text-align: center;
  z-index: 100;
}

.monitoring .monitoring-graph-notification-container {
  border-radius: 5px;
  margin-left: 30px;
  position: absolute;
  width: calc(100% - 76px);
  top: -12px;
}

.monitoring-tooltip {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  border-radius: 5px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.monitoring-tooltip.wide {
  width: 500px;
}

.monitoring-tooltip header {
  background-color: rgba(230, 236, 247, 0.4);
  padding: 0.2em 0.4em;
  flex: 1 1;
}

.monitoring-tooltip .primary-container, .monitoring-tooltip .secondary-container {
  display: flex;
  flex-direction: row;
  padding: 0.4em 1em;
}

.monitoring-tooltip .column {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.monitoring-tooltip .metric {
  display: flex;
  flex-direction: row;
}


.monitoring-tooltip .metric .color-container {
  display: flex;
  align-items: center;
}

.monitoring-tooltip .metric .color {
  width: 15px;
  margin-right: 5px;
  border-width: 1px;
  border-style: solid;
}

.monitoring-tooltip .metric .value {
  margin-left: 5px;
  font-family: monospace;
}

.monitoring-tooltip .metric .direction {
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  margin-left: 5px;
}

.monitoring-tooltip .secondary-container .metric .color {
  border-style: dashed;
}
.monitoring-graph-line {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.fleet-deployment-tabbar {
  margin-bottom: 0;
}

.connectivity-container .status::before {
  content: '\f111';
  font-family: "FontAwesome", monospace;
  margin-right: 0.3em;
}

.connectivity-container .status.online::before {
  color: #26d07c;
}

.connectivity-container .status.offline::before {
  color: #e0004d;
}

.connectivity-container .timestamp {
  font-size: 0.8em;
}
.editable-input-container {
  display: flex;
  font-size: medium;
  margin-bottom: 12px; 
  position: relative;
}

.editable-input-placeholder {
  font-style: italic;
  font-weight: 200;
}

.editable-input-icon {
  padding-left: 5px;
}

.editable-input-cancel {
  color: #9ea1a5;
}

.editable-input-read-only {
  line-height: 38px;
  box-sizing: border-box;
  margin: 0;
}

.editable-input input{
  width: 300px;
}

.editable-input-container a {
  -webkit-transform: translate(0, 5px);
          transform: translate(0, 5px);
}
.HubspotContactFormCotainer{
    margin: auto;
    width: 100%;
    max-width: 900px;
    height: 100%;
    padding-top: 50px;
}

.dispatch-response-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.dispatch-response-graph .graph-container {
  width: 100%;
  height: 100%;
  max-height: 25rem;
}

.dispatch-response-graph .legend-container {
  display: flex;
  background-color: #fff;
  font-size: 0.85em;
  border-top: 1px solid #DBE2E9;
  border-left: 1px solid #E8E8E9;
  border-right: 1px solid #E8E8E9;
  padding: 0.46em 2em;
}

.dispatch-response-graph .legend-container .legend {
  margin: 0 0.6em;
  display: flex;
  align-items: center;
}

.dispatch-response-graph .legend-container .key-style {
  margin-right: 0.4em;
  display: inline-block;
  width: 15px;
}

.dispatch-response-graph .legend-container .key-style.accepted {
  border-top: 3px solid #4698cb;
}

.dispatch-response-graph .legend-container .key-style.battery-response {
  border-top: 3px solid rgb(38, 208, 124);
}

.dispatch-response-graph .legend-container .key-style.meter-response {
  border-top: 3px solid rgb(170, 170, 170);
}

.dispatch-response-graph .legend-container .key-style.battery-prediction {
  border-top: 3px dashed rgba(38, 208, 124, 0.6);
}

.dispatch-response-graph .legend-container .key-style.meter-prediction {
  border-top: 3px dashed rgba(170, 170, 170, 0.6);
}

.dispatch-response-graph .graph-label {
  position: absolute;
  right: 4.5rem;
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.8;
}

.dispatch-response-graph .graph-label.import {
  top: 2.2rem;
}

.dispatch-response-graph .graph-label.export {
  bottom: 2rem;
}

.dispatch-overview {
  position: relative;
  display: flex;
}

.dispatch-overview .response-container {
  position: relative;
  flex: 3 1;
  padding: 2rem 3rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dispatch-overview .graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 25rem;
}

.dispatch-overview .node-count-container {
  width: 100%;
  margin-top: 1.6em;
}

.dispatch-overview .details-container {
  flex: 2 1;
  display: flex;
  flex-direction: column;
}

.dispatch-overview .capacity {
  margin-bottom: 1rem;
}

.dispatch-overview .capacity .value {
  font-size: 34px;
  font-weight: 300;
}

.dispatch-overview .capacity .label {
  font-size: 16px;
  margin-top: -0.55rem;
}

.dispatch-overview .capacity.accepted {
  padding: 2rem 0;
  border-bottom: 1px solid #DBE2E9;
}

.dispatch-overview .capacity.cost.inprogress {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #DBE2E9;
}

.dispatch-overview .capacity.accepted .label {
  color: #4698cb;
}

.dispatch-overview .capacity.battery-power .label {
  color: rgb(38, 208, 124);
}

.dispatch-overview .capacity.meter-power .label {
  color: rgb(170, 170, 170);
}

.dispatch-overview strong.capacity {
  font-size: 34px;
  font-weight: 300;
  display: block;
  margin: -0.5rem 0;
}

.dispatch-overview .reasons.rejected {
  margin-top: 0.5em;
}

.dispatch-overview .reasons.rejected strong {
  font-weight: 500;
}

.dispatch-overview-footer {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 1.6rem;
}

.dispatch-overview-footer span {
  display: block;
  text-align: center;
}

.dispatch-overview-footer .refresh-interval {
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.6;
}

.dispatch-overview .prices-loading {
  position: relative;
  margin: 2em 0 4em 0;
}

.dispatch-overview .graph-loading-container {
  position: relative;
  margin: 2em;
}

.mp-user {
  margin-left: 0.5em;
}

.mp-user.unknown {
  font-style: italic;
}

.rert-dispatch-overview {
  position: relative;
  display: flex;
}

.rert-dispatch-overview .response-container {
  position: relative;
  flex: 3 1;
  padding: 2rem 3rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rert-dispatch-overview .graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 25rem;
}

.rert-dispatch-overview .node-count-container {
  width: 100%;
  margin-top: 1.6em;
}

.rert-dispatch-overview .details-container {
  flex: 2 1;
  display: flex;
  flex-direction: column;
}

.rert-dispatch-overview .details-container .reschedule-rert-dispatch-button {
  margin-top: 1.6rem;
}

.rert-dispatch-overview .details-container .cancel-rert-dispatch-button {
  margin-top: 1.6rem;
}

.rert-dispatch-overview .capacity {
  margin-bottom: 1rem;
}

.rert-dispatch-overview .capacity .value {
  font-size: 34px;
  font-weight: 300;
}

.rert-dispatch-overview .capacity .label {
  font-size: 16px;
  margin-top: -0.55rem;
}

.rert-dispatch-overview .capacity.accepted {
  padding: 2rem 0;
  border-bottom: 1px solid #DBE2E9;
}

.rert-dispatch-overview .capacity.accepted .label {
  color: #4698cb;
}

.rert-dispatch-overview .capacity.battery-power .label {
  color: rgb(38, 208, 124);
}

.rert-dispatch-overview .capacity.meter-power .label {
  color: rgb(170, 170, 170);
}

.rert-dispatch-overview strong.capacity {
  font-size: 34px;
  font-weight: 300;
  display: block;
  margin: -0.5rem 0;
}

.rert-dispatch-overview .reasons.rejected {
  margin-top: 0.5em;
}

.rert-dispatch-overview .reasons.rejected strong {
  font-weight: 500;
}

.rert-dispatch-overview-footer {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 1.6rem;
}

.rert-dispatch-overview-footer span {
  display: block;
  text-align: center;
}

.rert-dispatch-overview-footer .refresh-interval {
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.6;
}

.rert-dispatch-overview .graph-loading-container {
  position: relative;
  margin: 2em;
}

.reschedule-dispatch-info {
  padding: 0 2.45rem 1rem 2.45rem;
}

.reschedule-dispatch-info > div {
  margin: 0.6em 0;
}

.reschedule-dispatch-info .customer-msg {
  display: flex;
}

.reschedule-dispatch-info .customer-msg-summary {
  max-width: 95%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
  border-bottom: 1px dotted #4698cb;
}

.reschedule-dispatch-info .customer-msg .edit-container {
  overflow: hidden;
  position: relative;
  color: #4698cb;
  cursor: pointer;
  line-height: 0.8em;
  transition: color 0.3s ease;
  display: flex;
  width: 85%
}

.reschedule-dispatch-info .customer-msg .edit-container:hover {
  color: #1F2A44;
}

.reschedule-dispatch-info .customer-msg .title {
  white-space: nowrap;
  margin-right: 0.3em;
}

.reschedule-dispatch-info .customer-msg .edit-icon {
  font-size: 0.7em;
  margin-top: 7px;
  margin-left: 5px;
}

.reschedule-dispatch-info .lockout-message {
  font-style: italic;
  color: #85888c;
  font-size: 0.9em;
  line-height: 1.8em;
}

.reschedule-rert-dispatch-confirmation .reschedule-rert-dispatch-info > div {
  margin: 0;
}

.reschedule-rert-dispatch-confirmation .reschedule-rert-dispatch-info .ro {
  font-weight: 500;
}

.reschedule-rert-dispatch-confirmation .labelled-content .label {
  font-weight: normal;
}

.reschedule-rert-dispatch-confirmation .confirm-message {
  padding: 3rem 2.45rem 3rem 2.45rem;
  font-size: 1.2em;
  text-align: center;
}

.new-dispatch-info {
  padding: 0 2.45rem 1rem 2.45rem;
}

.new-dispatch-info > div {
  margin: 0.6em 0;
}

.new-dispatch-info.grid-outage {
  margin-top: 2em;
}

.new-dispatch-info .customer-msg {
  display: flex;
}

.new-dispatch-info .customer-msg-summary {
  max-width: 95%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5em;
  border-bottom: 1px dotted #4698cb;
}

.new-dispatch-info .customer-msg .edit-container {
  overflow: hidden;
  position: relative;
  color: #4698cb;
  cursor: pointer;
  line-height: 0.8em;
  transition: color 0.3s ease;
  display: flex;
  width: 85%
}

.new-dispatch-info .customer-msg .edit-container:hover {
  color: #1F2A44;
}

.new-dispatch-info .customer-msg .title {
  white-space: nowrap;
  margin-right: 0.3em;
}

.new-dispatch-info .customer-msg .edit-icon {
  font-size: 0.7em;
  margin-top: 7px;
  margin-left: 5px;
}

.new-dispatch-info .lockout-message {
  font-style: italic;
  color: #85888c;
  font-size: 0.9em;
  line-height: 1.8em;
}

.grid-outage-container {
  display: flex;
}

.grid-outage-overview {
  position: relative;
  display: flex;
  flex: 3 1;
  flex-direction: column;
}

.grid-outage-overview .graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
}

.grid-outage-overview .node-count-container {
  width: 100%;
  margin-top: 1.6em;
  flex: 3 1;
}

.grid-outage-overview .details-container {
  flex: 2 1;
}

.grid-outage-overview .capacity {
  margin-bottom: 2rem;
}

.grid-outage-overview .capacity .value {
  font-size: 34px;
  font-weight: 300;
}

.grid-outage-overview .capacity .label {
  font-size: 16px;
  margin-top: -0.55rem;
}

.grid-outage-overview .capacity.accepted {
  padding: 2rem 0;
  border-bottom: 1px solid #DBE2E9;
}

.grid-outage-overview .capacity.accepted .label {
  color: #4698cb;
}

.grid-outage-overview .capacity.battery-power .label {
  color: rgb(38, 208, 124);
}

.grid-outage-overview .capacity.meter-power .label {
  color: rgb(170, 170, 170);
}

.grid-outage-overview strong.capacity {
  font-size: 34px;
  font-weight: 300;
  display: block;
  margin: -0.5rem 0;
}

.grid-outage-overview .reasons.rejected {
  margin-top: 0.5em;
}

.grid-outage-overview .reasons.rejected strong {
  font-weight: 500;
}

.grid-outage-overview .nodes-responded-container {
  padding: 5px;
  align-items: center;
  display: flex;
}

.grid-outage-overview-text {
  padding-top: 2em;
}

.opted-out-container {
  flex: 2 1;
  padding: 1rem;
  border-bottom: 1px solid #dbe2e9;
}

.opted-out-container .cancelled-node {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.curtailment-response-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.curtailment-response-graph .graph-container {
  width: 100%;
  height: 100%;
  max-height: 25rem;
  margin-bottom: 2em;
}

.curtailment-response-graph .legend-container {
  display: flex;
  background-color: #fff;
  font-size: 0.85em;
  border-top: 1px solid #DBE2E9;
  border-left: 1px solid #E8E8E9;
  border-right: 1px solid #E8E8E9;
  padding: 0.46em 2em;
}

.curtailment-response-graph .legend-container .legend {
  margin: 0 0.6em;
  display: flex;
  align-items: center;
}

.curtailment-response-graph .legend-container .key-style {
  margin-right: 0.4em;
  display: inline-block;
  width: 15px;
}

.curtailment-response-graph .legend-container .key-style.accepted {
  border-top: 3px solid #4698cb;
}

.curtailment-response-graph .legend-container .key-style.battery-response {
  border-top: 3px solid rgb(38, 208, 124);
}

.curtailment-response-graph .legend-container .key-style.meter-response {
  border-top: 3px solid rgb(170, 170, 170);
}

.curtailment-response-graph .legend-container .key-style.solar-prediction {
  border-top: 3px dashed #F68D26;
}

.curtailment-response-graph .legend-container .key-style.meter-prediction {
  border-top: 3px dashed rgba(170, 170, 170, 0.6);
}

.curtailment-response-graph .legend-container .key-style.grid-response {
  border-top: 3px dashed #4698cb;
}

.curtailment-response-graph .legend-container .key-style.solar-response {
  border-top: 3px dotted #26D07C;
}

.curtailment-response-graph .graph-label {
  position: absolute;
  right: 4.5rem;
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.8;
}

.curtailment-response-graph .graph-label.import {
  top: 2.2rem;
}

.curtailment-response-graph .graph-label.export {
  bottom: 2rem;
}

.curtailment-overview {
  position: relative;
  display: flex;
  height: 100%;
}

.curtailment-overview .response-container {
  position: relative;
  flex: 3 1;
  padding: 2rem 3rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.curtailment-overview .graph-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 25rem;
}


.curtailment-overview .node-count-container{
  width: 100%;
  margin-top: 5em;
}

.curtailment-overview .details-container {
  flex: 2 1;
  display: flex;
  flex-direction: column;
}

.curtailment-overview .deployment-list {
  position: relative;
  
}

.curtailment-overview .request {
  margin-bottom: 1rem;
  border-bottom: 1px solid #DBE2E9;
}

.curtailment-overview .request .value {
  font-size: 34px;
  font-weight: 300;
  padding-top: 1em;
}

.curtailment-overview .request .label {
  font-size: 16px;
  margin-top: -0.55rem;
  margin-bottom: 1.7em;
}


.curtailment-overview .request.power .label {
  color: #4698cb;
}

.curtailment-overview strong.request {
  font-size: 34px;
  font-weight: 300;
  display: block;
  margin: -0.5rem 0;
}

.curtailment-overview .reasons.rejected {
  margin-top: 0.5em;
}

.curtailment-overview .reasons.rejected strong {
  font-weight: 500;
}

.curtailment-overview-footer {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 1.6rem;
}

.curtailment-overview-footer span {
  display: block;
  text-align: center;
}

.curtailment-overview-footer .refresh-interval {
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.6;
}

.curtailment-overview .graph-loading-container {
  position: relative;
  margin: 2em;
}

.password-requirements {
    display: inline-block;
    line-height: 1.75;
    text-align: left;
}

.password-requirements ul {
    margin-left: 3ex;
}

.password-requirements li>span {
    color: #4698cb;
}
.message.validation {
    color: #E0004D;
}
.user-setting {
  display: flex;
  padding: 2em 0;
  line-height: 2.5em;
  border-bottom: 1px solid #DBE2E9;
}

.user-setting:last-child {
  border-bottom: none;
}

.user-setting h4 {
  width: 250px;
  color: #4698cb;
}

.user-setting.password a {
  margin-right: 2em;
}

.application-version {
  text-align: center;
  font-size: 0.8em;
  opacity: 0.6;
  margin-top: 2rem;
}

.alert-email-warning {
  color: #E0004D;
  margin-left: 1em;
}
.inline-password-change-box {
    display: block;
}

.user-setting.password .inline-password-description {
    line-height: 1.5;
    margin-top: 1ex;
}

.user-setting.password .error-message {
    color: #E0004D;
}

.user-setting.password .password-requirements {
    vertical-align: top;
}

.user-setting.password .inline-password-change-box {
    display: block;
    margin-top: 1em;
}

.user-setting.password .inline-password-change-fields {
    display: inline-block;
    margin-right: 3em;
}

.user-setting.password .inline-password-change-form .field-box {
    position: relative;
    margin-top: 1ex;
    margin-bottom: 1ex;
    display: block;
}

.user-setting.password .inline-password-change-form .field-box h6 {
    font-size: 12px;
    color: #4698cb;
    line-height: 1;
    margin-bottom: 3px;
}

.user-setting.password .inline-password-change-form .field-box input {
    /*
     * Reserves space for error messages.
     *
     * (Stops the text boxes jumping jumping around when errors are shown/hidden.)
     */
    margin-bottom: 2.5ex;
    width: 16em;
}

.user-setting.password .inline-password-change-form button.mp-button {
    margin-left: 0;
}

.user-setting.password .input .error-message {
    line-height: 1;
    margin-top: 3px;
    position: absolute;
    bottom: 0;
}

.user-setting.password .inline-password-change-form button {
    display: block;
    margin-top: 0.5ex;
}

.user-setting.password .inline-password-change-form button[disabled] {
    background-color: rgba(50, 118, 155, 0.50);
    cursor: default;
}

.user-setting.password .inline-password-change-form .success-message {
    padding-left: 0.9em; /* matches padding of text within button */
}

.user-setting.password .inline-password-change-form .success-message .fa {
    margin-right: 0.6em; /* matches spacing in buttons */
}

.mfa-settings-form {
  margin-top: 1em;
  line-height: 1.5em;
}

.mfa-settings-form-container .field-box h6 {
  font-size: 12px;
  color: #4698cb;
  line-height: 1;
  margin-bottom: 3px;
}

.mfa-settings-form-container .field-box {
  position: relative;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  display: block;
}

.mfa-settings-form-container .field-box input {
  width: 16em;
}

.mfa-settings-form-container button.mp-button {
  margin-left: 0;
}

.mfa-settings-form-container .google-auth-help {
  padding: 1em 0;
}

.mfa-modal-confirm-phone-container {
  padding: 1.5rem 2.45rem 0 2.45rem;
}

.mfa-modal-confirm-phone-container .code-wrapper {
  padding: 0.6em;
}

.mfa-modal-confirm-phone-container .code-wrapper input {
  width: 250px;
}

.api-keys-table {
  border-bottom: 1px solid #DBE2E9;
  width: 100%;
}

.api-keys-table .no-api-keys-message {
  margin-right: 1em;
}

.add-api-key-button {
  margin: 1em;
}

.revoked-api-key {
  color: #9ea1a5;
}

.revoked-api-key :last-child {
  text-align: center;
}

.api-keys-table td {
  line-height: 1.5em;
}

.add-api-key-button.with-table {
  margin: 0;
  margin-bottom: 2rem;
  position: relative;
}

.add-api-key-button.with-table button {
  position: absolute;
  right: 1rem;
}

.password-confirmation-details {
    margin-bottom: 1em;
}
.add-api-key-modal-wrapper {
  padding-right: 2.45rem;
  padding-left: 2.45rem;
}

.add-api-key-field {
  margin-top: 1em;
  margin-bottom: 1em;
}

.add-api-key-field h6 {
  font-size: 12px;
  color: #4698cb;
  line-height: 1;
  margin-bottom: 3px;
}

.add-api-key-field input {
  width: 20rem;
}

.add-api-key-ip-whitelist {
  display: flex;
  flex-direction: row;
}

.add-api-key-ip-whitelist .error-message {
  color: #E0004D;
  display: block;
}

.add-api-key-ip-whitelist textarea {
  width: 20rem !important;
  height: 8rem !important;
}

.add-api-key-ip-whitelist .ip-whitelist-instructions {
  margin-left: 1em;
}

.ip-whitelist-instructions ul {
  margin-left: 3ex;
}

.ip-whitelist-instructions li {
  text-indent: -1em;
}

.ip-whitelist-instructions li>span {
  color: #4698cb;
}

.new-api-key-block {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  align-items: flex-end;
}

.new-api-key-block .rp-widget {
  display: flex;
  width: 100%;
}

.new-api-key-block textarea {
  width: 100%;
  height: 8rem;
  border-color: rgba(0, 224, 149, 0.3) !important;
  background-color: rgba(0, 224, 92, 0.1) !important;
}

.new-api-key-block button .fa {
  margin-right: 0;
}

#new-api-key-value {
  text-overflow: ellipsis;
  font-family: monospace;
}

.copied-notification {
  color: #4698CB;
  margin-left: 1ex;
}

.api-keys-content {
  width: 100%;
}

.user-notification-container {
  display: flex;
  align-items: center;
}

.user-notification-container .read-spacer,
.user-notification-container .unread-marker {
  height: 14px;
  width: 14px;
  margin: 0 1em 0 0.6em;
}

.user-notification-container .unread-marker {
  border-radius: 7px;
  background-color: #4698cb;
}

.user-notification-container .datetime {
  color: #4698cb;
}

.user-notification-mark-read-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
}
.notification-markdown-container {
  margin: 0 2em 1em 2em;
}

.notification-markdown-container h1,
.notification-markdown-container h2,
.notification-markdown-container h3 {
  font-weight: 300;
  line-height: 2.5;
}

.notification-markdown-container h1 {
  font-size: 1.6em !important;
}

.notification-markdown-container h2 {
  font-size: 1.4em !important;
}

.notification-markdown-container h3 {
  font-size: 1.2em !important;
}

.notification-markdown-container em {
  font-style: italic;
}

.notification-markdown-container strong {
  font-weight: 600;
}

.notification-markdown-container code {
  font-family: monospace;
}

.notification-markdown-container ul,
.notification-markdown-container ol {
  padding-left: 2em;
  box-sizing: border-box;
}

.notification-markdown-container ul {
  list-style: disc;
}

.notification-markdown-container ol {
  list-style: decimal;
}

.notification-markdown-container blockquote,
.notification-markdown-container pre {
  white-space: pre-wrap;
  padding: 1em 3em;
  box-sizing: border-box;
}

.notification-markdown-container p {
  margin-top: 1em;
}

.notification-markdown-container a.external-link::after {
  content: ' \f08e';
  font-family: FontAwesome, monospace;
  font-size: 0.8em;
}

.notification-summary {
  font-weight: 500;
  font-size: 1.2em;
  margin: 1em 1.6em 0 1.6em;
}
.public_fixedDataTable_header .fixedDataTableCellLayout_wrap3 {
  padding-top: 15px;
  padding-left: 5px;
}

h6.no-customers-message {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.discount-container {
  font-style: italic;
}

.discount-container label {
  font-weight: 500;
  margin-right: 0.5em;
}

.customer-info-info {
  color: #E0004D;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.customer-info-info .icon {
  font-size: 1.1rem;
  line-height: 1.7rem;
  width: 25px;
}

.close-search-control {
  margin-bottom: 11px;
  margin-right: 5px;
}

.customer-list-searchbar {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.customer-list-searchbar .Select-menu-outer {
  z-index: 10;
}

.customer-location {
  display: flex;
  width: 100%;
  background-color: #fff;
  margin: 1em 0;
}

.customer-location .location-info {
  padding: 1em 2em;
  flex: 2 1;
}

.customer-location .map-container {
  flex: 5 1;
  min-height: 315px;
  height: 100%;
}

.customer-location .location-info .labelled-content:not(:last-child) {
  margin-bottom: 2em;
}

.customer-location .location-info .labelled-content .label {
  margin-bottom: 0.4em;
}

.customer-location .location-info .labelled-content .value {
  font-size: 1.3em;
  font-weight: 300;
}

.customer-location .location-info .labelled-content .value .labelled-content {
  font-size: 0.7em;
  margin: 0;
}

.customer-location .location-info .labelled-content .value .labelled-content .value {
  font-size: 1.1em;
}

.tariff .rates-table {
  width: 100%;
}

.tariff .rates-table input {
  display: inline-block;
}

.tariff .rates-table.ro td {
  padding: 0.2em;
}

.tariff .rates-table td.label {
  vertical-align: top;
}

.tariff .rates-table td.label input {
  max-width: 150px;
}

.tariff .rates-table td.block-onset input {
  max-width: 60px;
  margin: 0 0.4em;
  padding-right: 0.2rem;
}

.tariff .rates-table td.price input {
  max-width: 90px;
}

.tariff .rates-table .block-add {
  /*!*position: absolute;*!*/
  /*right: 0.5em;*/
  /*top: 35%;*/
  float: right;
}

.tariff .rates-table .block-add button,
.tariff .rates-table td.rate-add button {
  font-size: 0.95em;
}

.tariff .rates-table td.rate-add {
  text-align: right;
}

.tariff .rates-table tr.base-rate {
  background-color: #EEF2FA;
}

.tariff .rates-table .base-rate-text {
  display: block;
  font-style: italic;
  font-size: 0.85em;
  padding-left: 0.6rem;
  color: #808387;
}

.tariff .rates-table.ro .base-rate-text {
  margin-top: -1em;
}

.tariff .periods-table {
  width: 100%;
}

.tariff .periods-table td {
  padding: 0.3em;
}

.tariff .periods-table td.period-add button{
  font-size: 0.95em;
}

.tariff .periods-table td.period-add button {
  float: right;
}

.tariff .periods-table td.period-add .error-message,
.tariff .periods-table td.time-range .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.tariff .periods-table td.time-range input {
  display: inline-block;
}

.tariff .periods-table td.time-range input,
.tariff .periods-table td.time-range .error {
  max-width: 70px;
}

.tariff .periods-table td.time-range .rp-widget {
  display: inline-block;
  vertical-align: top;
}

.tariff .periods-table td.time-range .rp-widget:not(:first-child) {
  margin-left: 0.5em;
}

.tariff .periods-table td.days label {
  display: inline-block;
  text-align: center;
  padding: 0 0.2em;
}

.tariff .periods-table td.days label .text {
  font-size: 0.9em;
}

.tariff .periods-table td.days .checkbox {
  display: block;
  width: 100%;
  text-align: center;
}

.tariff .periods-table td.days .rp-widget.checkbox {
  height: auto;
  height: initial;
}

.tariff .periods-table td.days .checkbox .control {
  margin: 0;
}

.tariff .periods-table td.rate input {
  min-width: 60px;
}

.tariff .periods-table .days .error-message {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.page .page-content .tariff {
  margin-top: 1rem;
  height: auto;
  height: initial;
}

.tariff .tariff-details-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1em;
  flex-wrap: wrap;
}

.tariff .tariff-periods,
.tariff .tariff-rates {
  margin-top: 2em;
}

/*.tariff .tariff-periods .rp-widget.input.ro,*/
/*.tariff .tariff-rates .rp-widget.input.ro {*/
  /*line-height: initial;*/
/*}*/


.tariff.ro .tariff-periods,
.tariff.ro .tariff-rates {
  margin-top: 1em;
}

@media screen and (max-width: 800px) {
  .tariff .tariff-details label {
    display: block;
    width: 100%;
    margin-right: 0 !important;
  }
}

.tariff .tariff-details label {
  display: inline-block;
  font-weight: 400;
  color: #4698cb;
}

.tariff .tariff-details label {
  margin-right: 1em;
}

.tariff .tariff-details label .label-text {
  padding-left: 0.5em;
}

.tariff .tariff-details label .widget {
  margin-top: 0.2em;
}

.tariff .tariff-details label.plan-name .widget {
  min-width: 400px;
}

.tariff .tariff-details label.plan-name .widget input {
  width: 100%;
}

.tariff .tariff-details label.plan-code .widget {
  min-width: 14.3em;
}

.tariff .tariff-details label.plan-code .widget input {
  width: 100%;

}

.tariff .tariff-details label.tariff-type .widget {
  min-width: 200px;
}

.tariff .tariff-details label.dollar-input .widget {
  min-width: 105px;
  position: relative;
}

.tariff .tariff-details label.dollar-input .widget::before {
  content: '$';
  color: #A8AFBD;
  position: absolute;
  height: 100%;
  line-height: 2.4em;
  left: 0.7em;
  font-size: 1.1em;
}

.tariff .tariff-details label.dollar-input.ro .widget::before {
  line-height: 3.1em;
}

.tariff .tariff-details label.dollar-input .widget input {
  padding: 0 0.2em 0 1.6em;
}

.tariff .tariff-details label.dollar-input .rp-widget.input.ro {
  padding-left: 1.6em;
}

.tariff .tariff-details label.reset-period .widget {
  min-width: 140px;
}

.tariff .tariff-details label.operational-network .widget {
  min-width: 240px;
}

.tariff .tariff-details label.description .widget {
  min-width: 400px;
}

.tariff .tariff-details label.description .widget input {
  width: 100%;
}

.tariff input.tariff-name {
  max-width: 400px;
}

.tariff .help-text {
  padding: 0 0 0.5em 0.5em;
  box-sizing: border-box;
}

.tariff .non-field-errors {
  color: #E0004D;
  font-size: 0.9em;
  display: block;
}

.tariff .operational-networks-loading {
  color: #363636;
  padding: 1em;
}

.tariff-summary {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tariff-summary .tariff-info-container {
  flex: 1 1;
  min-width: 160px;
  width: 100%;
  padding-left: 1rem;
}

.tariff-summary .tariff-info-container h4 {
  font-weight: 400;
  padding: 0;
  margin: 0 0 0.8rem 0 !important;
  font-size: 1rem !important;
  color: #4698cb;
}

.tariff-summary-table,
.tariff-summary-rate-table {
  margin-top: 1.42rem;
  width: 100%;
}

.tariff-summary .discount-help-text {
  color: #808387;
  font-style: italic;
}

.tariff-summary .no-tariff {
  font-size: 1.3em;
  font-weight: 300;
  margin-top: 1em;
}

h6.no-tariffs-message {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.user-form {
  margin-top: 1rem;
}

.user-form .user-form-row {
  margin-bottom: 1em;
}

.user-form .user-form-row > label {
  display: inline-block;
  margin-right: 1em;
}

.user-form .user-form-row > label.user-name {
  width: 15em;
}

.user-form .user-form-row > label.email {
  width: 25em;
}

.user-form .user-form-row .label-text {
  padding-left: 0.5em;
}

.user-form .user-form-row > label input {
  width: 100%;
  box-sizing: border-box;
}

.user-form .permissions-table {
  width: 100%;
}

.user-form .permissions-table .display-name {
  font-size: 1.2em;
  display: block;
}

.user-form h2 {
  font-size: 1.71em;
  font-weight: 300;
  line-height: 2.5;
}

.user-form .no-revoke {
  font-size: 0.9em;
}

.data-export-summary-item .no-data,
.data-export-summary-item .progress-help {
  font-style: italic;
  opacity: 0.6;
}

.data-export-summary-item .rp-widget.infobox {
  margin-top: 1em;
}

.explanation-modal-link {
  padding-top: 1em;
}

.markdown-modal-container {
    padding: 0 2em;
}
.markdown-modal-container table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
}
.markdown-modal-container tr, .markdown-modal-container td {
    cursor: default;

    border: 1px solid rgba(70, 152, 203, 0.3) !important;
}

.markdown-modal-container > tr:hover, td:hover {
    box-shadow: none !important;
}
.analysis-exports-container {
  padding-top: 1em;
  box-sizing: border-box;
}

.analysis-exports-header {
  display: flex;
  padding-bottom: 1em;
  color: #4698CB
}


.analysis-exports-header .date {
  flex: 1 1;
  margin-right: 3em;
}

.analysis-exports-header .status {
  width: 6em;
}

.analysis-exports-header .type {
  flex: 3 1;
  margin-right: 2em;
}

.analysis-exports-header .details {
  flex: 3 1;
  margin-right: 6em;
}

.export-item {
  background-color: #fff;
  margin-bottom: 8px;
}


.export-item:hover {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.react-sanfona-item-body-wrapper {
  padding: 2em;
}

.export-item .label {
  width: 12rem;
}

.export-item.export-item-expanded {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.export-item .link-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.analysis-exports-heading {
  line-height: 3.5em;
  box-sizing: border-box;
  padding: 0 8px;
  border-right: 3px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.analysis-exports-heading .expand-icon {
  float: right;
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5em;
  color: #DEE4F0;
}

.analysis-exports-heading:hover {
  cursor: pointer;
}

.analysis-exports-heading .date {
  flex: 1 1
}

.analysis-exports-heading .export-type-customer {
  flex: 1 1
}

.analysis-exports-heading .export-type-operational {
  flex: 1 1
}

.analysis-exports-heading .summary-blurb {
  flex: 5 1;
}

.analysis-exports-heading .status {
  width: 6em;
}

.analysis-exports-heading .powerstation-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 2 1;
  margin-right: 0.35em;
}

.export-item-expanded .analysis-exports-heading .powerstation-name {
  white-space: normal;
  line-height: 2em;
}

.export-item-expanded .analysis-exports-heading .metrics {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 2em;
  flex: 3 1;
}

.analysis-exports-heading .metrics {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 3 1;
}

.export-item-expanded .analysis-exports-heading {
  background-color: #f7f9fd;
  border-right: 3px solid #45a9d3;
}

/*
Expanded headings should show a double-up icon, instead of the double-down
 */
.export-item-expanded .expand-icon i.fa:before {
  content: "\f102";
}

.new-operational-export-modal-contents {
  min-height: 35em;
  height: 40em;
  margin: 0 2.45rem;
  padding-left: 0.5rem;
  overflow-y: scroll;
}

.checkbox-label {
  display: flex;
  align-items: center;
  padding-top: 1em;
  margin-left: -8px;
}

.new-export-dropdown {
  padding-top: 0.7em;
}

.select-all {
  padding-left: 10px;
  padding-top: 5px;
  display: block;
}

.deployment-suggestion-row {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.deployment-suggestion-row.highlighted {
  background-color: rgba(70, 152, 203, 0.3)
}

.deployment-suggestion-row .highlight {
  background-color: yellow;
}

.deployment-suggestion-column {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  min-width: 280px;
  width: 99%; /* Leave a little bit of room so a horizontal scroll bar doesn't always appear */
  background-color: #fff;
  box-shadow: 0 5px 25px 0 rgba(21, 23, 37, 0.2);
  border-radius: 3px;
  border: 1px solid rgb(194, 196, 206);
  z-index: 2;
}



.new-export-modal-contents {
    min-height: 20em;
    height: 20em;
    margin: 0 2.45rem;
}

.new-export-modal-contents .export-modal-dropdown {
    padding-top: 1em;
}

.checkbox-label {
    display: flex;
    align-items: center;
    padding-top: 1em;
    margin-left: -8px;
}

.new-export-dropdown {
    padding-top: 0.7em;
}

.select-all {
    padding-left: 10px;
    padding-top: 5px;
    display: block;
}

.choose-export-modal {
    display: flex;
    flex-flow: column;
    padding: 2em;
}

.choose-export-modal .choose-export-tile {
    padding: 1em;
    text-align: center;
    width: 100%;
    border: 1px solid #dee4f0;
    font-weight: 400;
    box-sizing: border-box;
    height: 8em;
    margin-bottom: 1em;
}

.choose-export-modal .choose-export-tile:hover {
    cursor: pointer;
    background-color: #4698CB;
    color: #fff;
}

.choose-export-modal .choose-export-tile h3 {
    font-size: 1.4em;
    margin-top: 0.4em;
}

.choose-export-modal .choose-export-tile .description {
    font-weight: 400;
}
.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found h1 {
  font-size: 12em;
  color: #D9E2EA;
  font-weight: 200;
}

.not-found h2 {
  color: #4698cb;
  font-size: 2em;
  font-weight: 300;
}

.not-found p {
  margin: 2em 0;
  width: 30em;
  text-align: center;
}

.fleet-modal header.kit-design-header {
  padding-bottom: 0;
}

.fleet-modal header.kit-design-header.no-border {
  border-bottom: 1px solid #DBE2E9;
}

.fleet-modal header.kit-design-header.no-border > div {
  border-bottom: none;
}

.kit-design-modal .fleet-modal {
  width: 55em;
  height: 55em;
}

.kit-design-modal .fleet-modal > .content {
  overflow-y: auto;
}

.kit-design-modal .fleet-modal footer {
  margin-top: 0;
}

.kit-design-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 2.5;
}

.kit-design-form .fa-exclamation-circle {
  color: #F68D26;
}

.kit-design-form .kit-design-form-section {
  box-sizing: border-box;
  margin: 1.5rem 2.45rem 0;
}

.kit-design-form .kit-design-form-section h4 {
  font-weight: bold;
}

.kit-design-form .illustration-bar {
  box-sizing: border-box;
  width: 100%;
  background-color: #F7F9FD;
  border-bottom: 1px solid #DBE2E9;
  padding: 0 2ex;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.kit-design-form .illustration-bar .illustrations {
  min-height: 12em;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
}

.kit-design-form .no-inverter-indicator {
  font-size: x-large;
  font-weight: lighter;
  color: #9fb9c7;
  align-self: center;
}

.kit-design-form .illustration-bar button.link span {
  margin: 0;
}

.kit-design-form .illustration-bar .inverter-system-add span {
  font-size: xx-large;
}

.kit-design-form.kit-summary {
  line-height: 2;
}

.kit-design-form.kit-summary b {
  font-weight: bold;
}

.kit-design-form.kit-summary .illustration-bar img {
  height: 10em;
  margin: auto 0;
}

.kit-design-form.kit-summary .order-table {
  margin-top: 1em;
  line-height: normal;
  table-layout: fixed;
  width: 100%;
}

.kit-design-form.kit-summary .order-table thead th:nth-child(1) {
  width: 26%;
}

.kit-design-form.kit-summary .order-table thead th:nth-child(3) {
  width: 2em;
  text-align: right;
}

.kit-design-form.kit-summary .order-table tbody tr td:nth-child(3) {
  text-align: right;
}

.kit-summary-email-button {
  flex-grow: 1;
}

.kit-summary-email-button .email-success {
  float: right;
  padding-right: 0.9rem;
  color: #ADB4C1;
}

.kit-summary-email-button .email-success i.fa {
  margin-right: 0.6em;
}

.fleet-modal .kit-summary-email-button button.secondary {
  float: right;
}

.kit-design-form.site-details {
  line-height: 3;
}

.illustration-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.illustration-container .illustration {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.illustration-container .illustration img {
  height: 5em;
  margin: 1ex;
}

.illustration-container .inverter-system-remove {
  font-size: x-large;
  align-self: flex-end;
  line-height: 0;
}

.error-indicator {
  font-size: small;
  color: #F68D26;
  line-height: normal;
  position: absolute;
  bottom: 3ex;
  cursor: default;
}

.illustration-container .selected-indicator {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  height: 2ex;
  width: 2ex;
  position: relative;
  top: 1ex;
}

.illustration-container .selected-indicator.selected {
  background: white;
  border-top: 1px solid #DBE2E9;
  border-right: 1px solid #DBE2E9;
}

.configure-inverters-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.configure-inverters-loading .loading-spinner {
  -webkit-animation: none 0s ease 0s 1 normal none running;
          animation: none 0s ease 0s 1 normal none running;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  border: medium none currentColor;
  border-collapse: separate;
  border-image: none;
  border-radius: 0;
  border-spacing: 0;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: #000;
  -webkit-columns: auto;
          columns: auto;
  -webkit-column-count: auto;
          column-count: auto;
  -webkit-column-fill: balance;
          column-fill: balance;
  grid-column-gap: normal;
  -webkit-column-gap: normal;
          column-gap: normal;
  -webkit-column-rule: medium none currentColor;
          column-rule: medium none currentColor;
  -webkit-column-span: 1;
          column-span: 1;
  -webkit-column-width: auto;
          column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  height: auto;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  left: auto;
  letter-spacing: normal;
  list-style: disc outside none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 2;
  outline: medium none invert;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  -webkit-perspective: none;
          perspective: none;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%;
  position: static;
  right: auto;
  -moz-tab-size: 8;
       tab-size: 8;
  table-layout: auto;
  text-align: left;
  text-align-last: auto;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  -webkit-transform: none;
          transform: none;
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-transform-style: flat;
          transform-style: flat;
  transition: none 0s ease 0s;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 2;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  all: initial;
}

.kit-design-checklist {
  margin-top: 1em;
}

.kit-design-checklist sup {
  vertical-align: super;
  font-size: smaller;
}

.kit-design-checklist p {
  margin: 1em;
}

.kit-design-checklist ul {
  margin-left: 3em;
  margin-bottom: 1.5em;
  list-style: outside;
}

.kit-design-checklist li {
  margin-top: 1ex;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 13px;
}

html,
body {
  height: 100%;
}

body {
  font-size: 14px;
  color: #53565A;
  margin: 0;
  padding: 0;
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
}

a,
a:visited,
a:active,
a:focus,
.link,
.mp-button.link {
  text-decoration: none;
  color: #4698CB;
  transition: all 0.3s ease-out;
}

a:hover,
.link:hover,
.mp-button.link:hover {
  text-decoration: none;
  color: #1F2A44;
  cursor: pointer;
}

.public_fixedDataTableCell_cellContent a:hover,
.public_fixedDataTableCell_cellContent .link:hover {
  color: #1F2A44 !important;
}

.public_fixedDataTableCell_cellContent a,
.public_fixedDataTableCell_cellContent .link {
  color: #4698CB !important;
}

.rc-tooltip {
  z-index: 999999;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.6em;
}


table tbody tr:hover {
  box-shadow: 0px 0px 10px 0px #ddd;
}

table tbody tr[role="button"]:hover {
  cursor: pointer;
}

table tbody tr:hover a,
table tbody tr:hover link {
  text-decoration: none;
  color: #1F2A44;
}

table td {
  padding: 0.25em 0.5em;
  vertical-align: middle;
}

table tbody td {
  background: #fff;

}

table thead tr th {
  text-align: left;
  padding: 0.2em 0.5em;
  color: #4698CB;
}

table tbody tr.no-click {
  cursor: default;
}

table.table-light {
  width: 100%;
  border-collapse: collapse;
}

table.table-light tr {
  border-top: 1px solid #DBE2E9;
}

table.table-light tbody tr:hover {
  cursor: default;
}

table.table-light td {
  padding: 1em;
  vertical-align: middle;
  background: none;
  height: 28px;
  border-top: 1px solid #DBE2E9;
}

table.table-light thead tr th {
  padding: 0.5em 0em 0em 1em;
  text-align: left;
  color: #4698CB;
  height: 0;
  border-top: 1px solid #DBE2E9;
}

table.table-light tbody tr:hover {
  box-shadow: none;
}

table.table-light tbody tr a:hover,
table.table-light tbody tr link:hover {
  text-decoration: none;
  color: #1F2A44;
  cursor: default;
}

.fleet-table-no-results {
  width: 100%;
  text-align: center;
  padding: 2em 0;
  font-size: 1.3em;
  font-weight: 300;
}

.label-text {
  color: #4698CB;
}

.notification-dismiss:before {
  content: '\f00d';
  font-family: "FontAwesome", monospace;
  color: #fff;
  height: 14px;
  top: calc(50% - 7px);
  position: absolute;
  font-size: 1rem;
}

td.actions .mp-button.cancel {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 1em;
  color: #ADB4C1;
}

td.actions .mp-button.cancel:hover {
  color: #737a87;
}

