.illustration-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.illustration-container .illustration {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.illustration-container .illustration img {
  height: 5em;
  margin: 1ex;
}

.illustration-container .inverter-system-remove {
  font-size: x-large;
  align-self: flex-end;
  line-height: 0;
}

.error-indicator {
  font-size: small;
  color: #F68D26;
  line-height: normal;
  position: absolute;
  bottom: 3ex;
  cursor: default;
}

.illustration-container .selected-indicator {
  transform: rotate(-45deg);
  height: 2ex;
  width: 2ex;
  position: relative;
  top: 1ex;
}

.illustration-container .selected-indicator.selected {
  background: white;
  border-top: 1px solid #DBE2E9;
  border-right: 1px solid #DBE2E9;
}
