.fleet-modal header.kit-design-header {
  padding-bottom: 0;
}

.fleet-modal header.kit-design-header.no-border {
  border-bottom: 1px solid #DBE2E9;
}

.fleet-modal header.kit-design-header.no-border > div {
  border-bottom: none;
}

.kit-design-modal .fleet-modal {
  width: 55em;
  height: 55em;
}

.kit-design-modal .fleet-modal > .content {
  overflow-y: auto;
}

.kit-design-modal .fleet-modal footer {
  margin-top: 0;
}

.kit-design-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 2.5;
}

.kit-design-form .fa-exclamation-circle {
  color: #F68D26;
}

.kit-design-form .kit-design-form-section {
  box-sizing: border-box;
  margin: 1.5rem 2.45rem 0;
}

.kit-design-form .kit-design-form-section h4 {
  font-weight: bold;
}

.kit-design-form .illustration-bar {
  box-sizing: border-box;
  width: 100%;
  background-color: #F7F9FD;
  border-bottom: 1px solid #DBE2E9;
  padding: 0 2ex;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.kit-design-form .illustration-bar .illustrations {
  min-height: 12em;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
}

.kit-design-form .no-inverter-indicator {
  font-size: x-large;
  font-weight: lighter;
  color: #9fb9c7;
  align-self: center;
}

.kit-design-form .illustration-bar button.link span {
  margin: 0;
}

.kit-design-form .illustration-bar .inverter-system-add span {
  font-size: xx-large;
}
