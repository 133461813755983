.react-grid-item {
  height: 100%;
}

.fullscreen-dashboard .react-grid-item {
  height: calc(100% - 50px);
}

.react-grid-item.dashboard-widget > .react-resizable-handle {
  background: none;
  transform: rotate(45deg);
}

.react-grid-item.dashboard-widget > .react-resizable-handle:before {
  content: '\f105';
  font-family: "FontAwesome", monospace;
  margin-left: 10px;
}

.react-grid-item.react-grid-placeholder {
  background: #4698cb !important;
}
