.new-api-key-block {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  align-items: flex-end;
}

.new-api-key-block .rp-widget {
  display: flex;
  width: 100%;
}

.new-api-key-block textarea {
  width: 100%;
  height: 8rem;
  border-color: rgba(0, 224, 149, 0.3) !important;
  background-color: rgba(0, 224, 92, 0.1) !important;
}

.new-api-key-block button .fa {
  margin-right: 0;
}

#new-api-key-value {
  text-overflow: ellipsis;
  font-family: monospace;
}

.copied-notification {
  color: #4698CB;
  margin-left: 1ex;
}

.api-keys-content {
  width: 100%;
}
