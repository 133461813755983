.mp-button {
  display: inline-block;
  line-height: 1em;
  height: 2.71em;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5em 0.9em;
  font-size: 1em;
  font-weight: 300;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
  position: relative;
  background: none;
}

a.mp-button {
  height: initial;
  line-height: 1.6em;
}

.mp-button:hover {
  text-decoration: none;
}

.mp-button:not(:first-child) {
  margin-left: 0.8em;
}

.mp-button.primary {
  background-color: #4698CB;
  color: #FFF;
}

.mp-button.primary:hover {
  background-color: #1F2A44;
}

.mp-button.secondary {
  border: 1px solid #DBE2E9;
  color: #4698CB;
  background-color: transparent;
  font-weight: 400;
}

.mp-button.secondary:hover {
  border: 1px solid #4698CB;
  color: #1F2A44;
}

.mp-button.danger {
  background-color: #CB4646;
  color: #FFF;
}

.mp-button.danger:hover {
  background-color: #441F1F;
}

.mp-button .fa {
  margin-right: 0.6em;
}

.mp-button:focus {
  outline: 0;
}

.mp-button.link,
.mp-button.link-inverted {
  font-weight: 400;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  height: initial;
  line-height: inherit;
  border-radius: unset;
}

.mp-button.link-inverted {
  color: #53565A;
}

.mp-button.link-inverted:hover {
  color: #4698CB;
}

.mp-button:disabled {
  cursor: not-allowed !important;
  opacity: 0.8 !important;
}
