.powerstation-deployment-search {
  display: flex;
  align-items: center;
}

.powerstation-deployment-search .text-search {
  flex: 4;
}

.powerstation-deployment-search .included-only {
  flex: 1;
  display: flex;
  align-items: center;
}
