.fleet-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 99999;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1000ms linear;
  animation: fleet-modal-overlay-fade-in 600ms 1;
}

.fleet-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  min-height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

@keyframes fleet-modal-overlay-fade-in {
  0%   { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.6); }
}

.fleet-modal {
  background-color: #fff;
  width: 50em;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
}

.fleet-modal header {
  font-size: 1.75em;
  font-weight: 300;
  width: 100%;
  padding: 1.5rem 2.45rem 0;
  box-sizing: border-box;
}

.fleet-modal header > div {
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #DBE2E9;
}

.fleet-modal footer {
  width: 100%;
  padding: 1.5rem 2.45rem;
  box-sizing: border-box;
  margin-top: 1.5rem;
  border-top: 1px solid #DBE2E9;
  background-color: #F7F9FD;
  border-radius: 0 0 10px 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.fleet-modal footer button.secondary {
  float: left;
}

.fleet-modal footer button.primary {
  float: right;
}

.fleet-modal.confirm .content {
  font-size: 1.3em;
  font-weight: 300;
  padding: 1.5rem 2.45rem 0 2.45rem;
}

.fleet-modal .rp-widget.infobox {
  margin-left: 2.45rem;
  margin-right: 2.45rem;
}

.fleet-modal .content {
  max-height: 80%;
}
