.customer-app-message-preview {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3.4rem;
  max-height: 18rem;
  overflow: hidden;
}

.customer-app-message-preview .title {
  color: #FF9A3C;
  width: 100%;
  text-align: center;
  font-size: 1em;
}

.customer-app-message-preview .message {
  color: #9b9b9b;
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  padding: 0 1.7em;
  box-sizing: border-box;
  font-size: 0.75em;
  line-height: 1.1em;
}
