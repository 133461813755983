.powerstation-type {
  align-items: center;
  display: flex;
  height: 1.8rem;
  padding: 1em 0;
  vertical-align: middle;
}

.powerstation-type.simple {
  border-bottom: 0;
  height: initial;
  padding: 0;
}

.powerstation-type {
  border-bottom: 1px solid #DBE2E9;
}

.powerstation-type.simple .rp-widget.select-dd.ro,
.powerstation-type.simple .rp-widget.input.ro {
  padding: 0;
  line-height: initial;
  cursor: pointer;
}

.powerstation-type.simple .rp-widget.select-dd.ro .current-value,
.powerstation-type.simple .rp-widget.input.ro .current-value {
  cursor: pointer;
}

.powerstation-type .filter-item {
  display: inline-block;
}

.powerstation-type .filter-item input {
  height: 1.8rem;
  width: 300px;
  font-size: 0.9em;
}

.powerstation-type .spacer {
  margin: 0 0.5em;
}

.powerstation-type.simple .spacer {
  margin: 0 0.2em;
}

.powerstation-type .filter-type-dd-label {
  display: flex;
  justify-content: space-between;
}

.powerstation-type .filter-type-dd-label .enrolment-type {
  margin-left: 1.6em;
  font-size: 0.9em;
  color: #8a8d91;
  text-decoration: none !important;
}

.rp-widget.select-dd .options-container .option:hover .enrolment-type {
  text-decoration: none !important;
  display: inline-block;
}
