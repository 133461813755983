.new-operational-export-modal-contents {
  min-height: 35em;
  height: 40em;
  margin: 0 2.45rem;
  padding-left: 0.5rem;
  overflow-y: scroll;
}

.checkbox-label {
  display: flex;
  align-items: center;
  padding-top: 1em;
  margin-left: -8px;
}

.new-export-dropdown {
  padding-top: 0.7em;
}

.select-all {
  padding-left: 10px;
  padding-top: 5px;
  display: block;
}
