.new-export-modal-contents {
    min-height: 20em;
    height: 20em;
    margin: 0 2.45rem;
}

.new-export-modal-contents .export-modal-dropdown {
    padding-top: 1em;
}

.checkbox-label {
    display: flex;
    align-items: center;
    padding-top: 1em;
    margin-left: -8px;
}

.new-export-dropdown {
    padding-top: 0.7em;
}

.select-all {
    padding-left: 10px;
    padding-top: 5px;
    display: block;
}
