.powerstation-incomplete {
  display: flex;
  flex-flow: column;
  padding-top: 1em;
}

.powerstation-incomplete > div {
  margin-right: 2em;
}

.powerstation-incomplete label {
  margin-bottom: 0.4em;
  display: inline-block;
  font-weight: 500;
}

.powerstation-incomplete div.value {
  font-size: 1.3em;
  font-weight: 300;
}

.powerstation-incomplete .select-dd {
  padding: 0;
}

.powerstation-incomplete .select-dd .current-value {
  border-bottom: none;
}

.powerstation-incomplete .select-dd .current-value .fa {
  margin-left: 0;
  margin-right: 0.3em;
}

.powerstation-incomplete .select-dd .current-value:after {
  content: '';
}

.event-table-container, .event-table {
  width: 100%;
}
