.login-view {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #1F2A44;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
}

.login-panel {
  width: 580px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  padding: 5em;
  box-sizing: border-box;
}

.login-panel h1 {
  font-size: 1.7em;
  font-weight: 400;
  font-family: "Raleway", "Work Sans", sans-serif;
  margin: 1em 0;
}

.login-panel .message-container {
  margin: 1em 0;
}

.login-panel p,
.login-panel form {
  padding: 0 4em;
}

.login-panel form input {
  display: block;
  width: 100%;
}

.login-panel form input[type="text"]:first-child {
  margin-top: 2em;
}

.login-panel form input[type="text"],
.login-panel form input[type="password"] {
  background-color: #fff;
  color: #4698CB;
  border: none;
  font-size: 1em;
  text-align: center;
  line-height: 1.3em;
  border-bottom: 1px solid #DAE0EB;
  margin-top: 2em;
}

.login-panel form input[type="text"]::placeholder,
.login-panel form input[type="password"]::placeholder {
  color: #4698CB;
}

.login-panel input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #4698CB !important;
}

.login-panel .mp-button {
  margin: 2em 0;
}

.login-panel .link {
  font-size: 0.9em;
  text-decoration: none;
  color: #53565A;
  padding-bottom: 2px;
  border-bottom: 1px solid #53565A;
}
