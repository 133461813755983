.user-form {
  margin-top: 1rem;
}

.user-form .user-form-row {
  margin-bottom: 1em;
}

.user-form .user-form-row > label {
  display: inline-block;
  margin-right: 1em;
}

.user-form .user-form-row > label.user-name {
  width: 15em;
}

.user-form .user-form-row > label.email {
  width: 25em;
}

.user-form .user-form-row .label-text {
  padding-left: 0.5em;
}

.user-form .user-form-row > label input {
  width: 100%;
  box-sizing: border-box;
}

.user-form .permissions-table {
  width: 100%;
}

.user-form .permissions-table .display-name {
  font-size: 1.2em;
  display: block;
}

.user-form h2 {
  font-size: 1.71em;
  font-weight: 300;
  line-height: 2.5;
}

.user-form .no-revoke {
  font-size: 0.9em;
}
