.deployment-suggestion-row {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.deployment-suggestion-row.highlighted {
  background-color: rgba(70, 152, 203, 0.3)
}

.deployment-suggestion-row .highlight {
  background-color: yellow;
}

.deployment-suggestion-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  min-width: 280px;
  width: 99%; /* Leave a little bit of room so a horizontal scroll bar doesn't always appear */
  background-color: #fff;
  box-shadow: 0 5px 25px 0 rgba(21, 23, 37, 0.2);
  border-radius: 3px;
  border: 1px solid rgb(194, 196, 206);
  z-index: 2;
}

