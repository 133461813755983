.user-setting {
  display: flex;
  padding: 2em 0;
  line-height: 2.5em;
  border-bottom: 1px solid #DBE2E9;
}

.user-setting:last-child {
  border-bottom: none;
}

.user-setting h4 {
  width: 250px;
  color: #4698cb;
}

.user-setting.password a {
  margin-right: 2em;
}

.application-version {
  text-align: center;
  font-size: 0.8em;
  opacity: 0.6;
  margin-top: 2rem;
}

.alert-email-warning {
  color: #E0004D;
  margin-left: 1em;
}